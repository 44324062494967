/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useState, useMemo, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import customersService from 'service/customer';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';

interface BuyersChoiceProps {
  setOpenContact: (show: boolean) => void;
  setSelectedContactId?: any;
}

export type CustomerData = {
  first_name: string;
  last_name: string;
  phone: string;
  guid: string;
};

const BuyersChoice = ({
  setOpenContact,
  setSelectedContactId
}: BuyersChoiceProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  console.log(searchValue);

  //Get Product Search
  const { data, refetch } = useQuery(['GET_CUSTOMERS'], () => {
    const res = customersService
      .getList({
        offset: 0,
        limit: 100,
        search: searchValue
      })
      .then((res: any) => res?.data?.users);
    return res;
  });

  const customerData: CustomerData[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          first_name: items?.first_name,
          last_name: items?.last_name,
          phone: items?.phone_number,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const customerDataTableColumns: ColumnDef<CustomerData>[] = [
    {
      id: 'action',
      cell: rowData => {
        return (
          <div>
            <Button
              variant="hover"
              className="p-2"
              onClick={() => {
                setSelectedContactId({
                  id: rowData?.row?.original?.guid,
                  first_name: rowData?.row?.original?.first_name,
                  last_name: rowData?.row?.original?.last_name
                });
                setOpenContact(false);
              }}
            >
              <FeatherIcon icon="plus" className="cursor-pointer" size={20} />
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'last_name',
      header: t('second_name'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'first_name',
      header: t('name'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'phone',
      header: t('phone_number'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: customerData,
    columns: customerDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    if (searchValue.length > 2 || searchValue === '') {
      refetch();
    }
  }, [searchValue]);

  return (
    <div>
      <SearchBox
        placeholder={t('search')}
        onChange={e => setSearchValue(e?.target?.value)}
        className="mb-3"
      />
      <AdvanceTableProvider {...table}>
        <div className="border-top border-bottom border-200">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default BuyersChoice;
