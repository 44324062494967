/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { useEffect, useState, useMemo, ChangeEvent } from 'react';
import { useQuery } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Offcanvas } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import customerEntityService from 'service/charterer';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AddPurchaser from './AddPurchaser';

import './styles.scss';

export const customersBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'dt_kt',
    url: '/dtkt/provider'
  },
  {
    label: 'offsetting_buyyer',
    active: true
  }
];

export type SettelementData = {
  product_provider: string;
  currency: string;
  debt: string;
  credit: string;
  remainder: string;
  debitor_id: string;
  currency_id: string;
  guid: string;
};

const PurchaserWithCustomer = () => {
  const { t, i18n } = useTranslation();

  const [dateFrom, setDateFrom] = useState<any>(null);
  const [dateTo, setDateTo] = useState<any>(null);
  const [openMain, setOpenMain] = useState(false);
  const [selectedTableData, setSelectedTableData] = useState({});
  const [totalAmount, setTotalAmount] = useState<any>([]);

  const navigate = useNavigate();

  const navigateTo = (row: any) => {
    navigate(
      `/dtkt/purchaser-with-customer/purchaser-with-customer-result/${row?.original?.debitor_id}?currency_id=${row?.original?.currency_id}`
    );
  };

  const handleStartDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const startDate = new Date(date[0]);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    } else {
      const startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    }
  };

  const handleEndDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const endDateInMillis = date[0]?.getTime();
      setDateTo(endDateInMillis);
    } else {
      const endDateInMillis = date.getTime();
      setDateTo(endDateInMillis);
    }
  };

  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? undefined
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000 
          : undefined;

  const { data, refetch } = useQuery(
    ['GET_CUSTOMERS_CHECK'],
    () => {
      const res = customerEntityService
        .getListCharterer({
          offset: 0,
          limit: 1000,
          law_subject_type: 'INDIVIDUAL',
          date_from: dateFrom,
          date_to: adjustedDateTo
        })
        .then((res: any) => {
          setTotalAmount(res?.total_amount);
          return res?.debit_credit_list;
        });
      return res;
    },
    {
      enabled: !!dateFrom || !!adjustedDateTo
    }
  );

  const settelementData: SettelementData[] = useMemo(() => {
    const resultData =
      data?.map((items: any) => {
        const remainder =
          (parseFloat(items?.amount_debit) || 0) -
          (parseFloat(items?.amount_credit) || 0);
        return {
          product_provider: items?.user?.first_name,
          debitor_id: items?.debitor_id,
          currency_id: items?.currency_id,
          currency: items?.currency?.name?.[i18n?.language],
          debt: items?.amount_debit
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.amount_debit)
                .replace(/,/g, ' ')
            : 0,
          credit: items?.amount_credit
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.amount_credit)
                .replace(/,/g, ' ')
            : 0,
          remainder: remainder
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(remainder)
                .replace(/,/g, ' ')
            : 0,
          guid: items?.id
        };
      }) ?? [];

    return resultData;
  }, [data, t, i18n?.language]);

  const settelementDataTableColumns: ColumnDef<SettelementData>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            className="p-2"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setOpenMain(true);
              setSelectedTableData(rowData?.row?.original);
            }}
          >
            <FontAwesomeIcon
              className="cursor-pointer"
              icon={faMoneyBillWave}
              transform={{ size: 20 }}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'product_provider',
      header: t('name_buyer'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'debt',
      header: t('debit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'credit',
      header: t('credit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'remainder',
      header: t('remainder'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: settelementData,
    columns: settelementDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    if (dateFrom || adjustedDateTo) {
      refetch();
    }
  }, [dateFrom, adjustedDateTo]);

  useEffect(() => {
    // Set initial dateFrom to the beginning of the current month
    const today = new Date();
    const beginningOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    setDateFrom(beginningOfMonth.getTime());

    // Set initial dateTo to today's date
    setDateTo(today.getTime());
  }, []);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={customersBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{t('offsetting_buyyer')}</h2>
      </div>

      <div className="d-flex gap-2 mt-3">
        <div className="form-icon-container">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder={t('search')}
              onChange={handleSearchInputChange}
              className="form-icon-input"
              autoComplete="off"
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              {t('search')}
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faSearch}
            className="text-900 fs-9 form-icon"
          />
        </div>
        <Form.Group className="md-4">
          <Form.Floating>
            <Flatpickr
              className={classNames('form-control')}
              options={{
                nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                locale: {
                  firstDayOfWeek: 1
                },
                monthSelectorType: 'static',
                onDayCreate: (...[, , , dayElem]) => {
                  if (
                    dayElem.dateObj.getDay() === 5 ||
                    dayElem.dateObj.getDay() === 6
                  ) {
                    dayElem.className += ' weekend-days';
                  }
                },
                dateFormat: 'M j, Y',
                disableMobile: true
              }}
              value={dateFrom}
              onChange={handleStartDateChange}
              placeholder={t('date_from')}
            />
            <label htmlFor="floatingInputCustom">{t('date_from')}</label>
          </Form.Floating>
        </Form.Group>

        <Form.Group className="md-4">
          <Form.Floating>
            <Flatpickr
              className={classNames('form-control')}
              options={{
                nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                locale: {
                  firstDayOfWeek: 1
                },
                monthSelectorType: 'static',
                onDayCreate: (...[, , , dayElem]) => {
                  if (
                    dayElem.dateObj.getDay() === 5 ||
                    dayElem.dateObj.getDay() === 6
                  ) {
                    dayElem.className += ' weekend-days';
                  }
                },
                dateFormat: 'M j, Y',
                disableMobile: true
              }}
              value={dateTo}
              onChange={handleEndDateChange}
              placeholder={t('date_to')}
            />
            <label htmlFor="floatingInputCustom">{t('date_to')}</label>
          </Form.Floating>
        </Form.Group>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <AdvanceTable
            tableProps={{ className: 'phoenix-table fs-9' }}
            navigateTo={navigateTo}
          />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>

      <Offcanvas
        show={openMain}
        onHide={() => {
          setOpenMain(false);
        }}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            {t('offsetting_buyyer')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddPurchaser
            setOpenMain={setOpenMain}
            selectedTableData={selectedTableData}
            refetch={refetch}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <div className="purchaser-container">
        <div className="box">
          <p className="mb-0" style={{ fontWeight: '800' }}>
            {t('total_amount_remains')}
          </p>
          {totalAmount?.map((amount: any) => {
            return (
              <div
                key={amount?.currency_id}
                className="d-flex justify-content-between gap-2"
              >
                <span>{amount?.currency.name?.[i18n?.language]}:</span>
                <span style={{ fontWeight: '800' }}>
                  {amount?.difference_amount
                    ? new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 4,
                        useGrouping: true
                      })
                        .format(amount?.difference_amount)
                        .replace(/,/g, ' ')
                    : '0'}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PurchaserWithCustomer;
