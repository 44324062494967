/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import productSearchService from 'service/productSearch';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import { pageToOffset } from 'utils/pageToOffset';

export type SearchProduct = {
  name: string;
  variation: string;
  barcode: string;
  original_barcode: string;
  unit: string;
};

interface NotInListsProps {
  inventoryItemData?: any;
  inventoryId?: any;
  warehouuseId?: any;
}

const NotInLists = ({
  inventoryItemData,
  inventoryId,
  warehouuseId
}: NotInListsProps) => {
  const { t } = useTranslation();
  // const dispatch: Dispatch<any> = useDispatch();
  // const [loading, setLoading] = useState(false);
  // const [loadingZero, setLoadingZero] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');

  const { data, isFetching, isLoading } = useQuery(
    ['GET_PRODUCT', currentPage, searchValue],
    () => {
      if (warehouuseId && inventoryId) {
        const res = productSearchService
          .getListProduct({
            offset: pageToOffset(currentPage, 10),
            limit: 10,
            warehouse_id: warehouuseId,
            name: searchValue,
            not_inventory_id: inventoryId
          })
          .then((res: any) => {
            setPageCount(Math.ceil(res?.count / 10));
            return res?.products;
          });
        return res;
      }
    },
    { enabled: !!currentPage || !searchValue || searchValue?.length > 2 }
  );

  const loadingData = isLoading || isFetching;

  const generateData: SearchProduct[] = useMemo(() => {
    return (
      data
        ?.filter(
          (item: any) =>
            !inventoryItemData?.some(
              (inventoryItem: any) => inventoryItem.product_id === item.id
            )
        )
        ?.map((items: any) => {
          return {
            name: items?.name,
            variation: items?.nomenclature?.is_variation ? 'ДА' : 'НЕТ',
            barcode: items?.barcode,
            original_barcode: items?.nomenclature?.barcode_original,
            unit: items?.nomenclature?.measure_unit?.name?.ru,
            quantity: items?.total_quantity,
            nomenclature_id: items?.nomenclature_id,
            currency_id: items?.currency_id,
            total_quantity: items?.total_quantity,
            price_in: items?.price_in,
            price: items?.price ?? 0,
            guid: items?.id
          };
        }) ?? []
    );
  }, [data, inventoryItemData]);

  const searchProductTableColumns: ColumnDef<SearchProduct>[] = [
    {
      accessorKey: 'name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'variation',
      header: t('variation'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'original_barcode',
      header: t('barcode_original'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'unit',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'price',
      header: t('selling_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: generateData,
    columns: searchProductTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  // const handleAdd = () => {
  //   const selectedItems = table
  //     .getSelectedRowModel()
  //     .rows.map(row => row.original);

  //   const createdData = selectedItems?.map((el: any) => ({
  //     quantity_actual: el?.total_quantity,
  //     quantity: el?.total_quantity,
  //     nomenclature_id: el.nomenclature_id,
  //     currency_id: el.currency_id,
  //     price_in: el.price_in,
  //     product_id: el.guid,
  //     status_id: 'ba94f8a8-3a42-4d1e-a351-0e5349e3f512'
  //   }));
  //   /* prettier-ignore */
  //   if (selectedItems?.length > 0) {
  //     setLoading(true);
  //     inventoryItemService
  //       .createInventoryItem({
  //         inventory_id: inventoryId,

  //         inventory_items: createdData
  //       })
  //       .then((res: any) => {
  //         if (res) {
  //           dispatch(
  //             showAlert({
  //               title: 'Товар успешно добавлен!',
  //               type: 'success'
  //             })
  //           );
  //           setOpenNotInList(false);
  //           refetchInventoryItem();
  //           setLoading(false);

  //         }
  //       });
  //   } else {
  //     dispatch(
  //       showAlert({
  //         title: 'Товар еще не выбран!'
  //       })
  //     );
  //   }
  // };

  // const handleLikeThero = () => {
  //   const selectedItems = table
  //     .getSelectedRowModel()
  //     .rows.map(row => row.original);

  //   const createdData = selectedItems?.map((el: any) => ({
  //     quantity_actual: 0,
  //     quantity: 0,
  //     nomenclature_id: el.nomenclature_id,
  //     currency_id: el.currency_id,
  //     price_in: el.price_in,
  //     product_id: el.guid,
  //     status_id: 'ba94f8a8-3a42-4d1e-a351-0e5349e3f512'
  //   }));

  //   /* prettier-ignore */
  //   if (selectedItems?.length > 0) {
  //     setLoadingZero(true);
  //     inventoryItemService
  //       .createInventoryItem({
  //         inventory_id:
  //       inventoryId,

  //         inventory_items: createdData
  //       })
  //       .then((res: any) => {
  //         if (res) {
  //           dispatch(
  //             showAlert({
  //               title: 'Товар успешно добавлен!',
  //               type: 'success'
  //             })
  //           );
  //           setOpenNotInList(false);
  //           refetchInventoryItem();
  //     setLoadingZero(false);

  //         }
  //       });
  //   }else{
  //     dispatch(
  //       showAlert({
  //         title: 'Товар еще не выбран!'
  //       })
  //     );
  //   }
  // };

  return (
    <div>
      <div className="form-icon-container mb-3 w-30">
        <Form.Floating>
          <Form.Control
            type="text"
            placeholder={t('enter_product')}
            onFocus={e => e.target.select()}
            onChange={e => setSearchValue(e.target.value)}
            className="form-icon-input"
          />
          <label
            htmlFor="floatingInputCustom"
            className="form-icon-label text-700"
          >
            {t('enter_product')}
          </label>
        </Form.Floating>
        <FontAwesomeIcon icon={faSearch} className="text-900 fs-9 form-icon" />
      </div>

      <AdvanceTableProvider {...table}>
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          isLoading={loadingData}
        />
        <AdvanceTableCPagination
          count={pageCount}
          page={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </AdvanceTableProvider>

      {/* <div className="d-flex justify-content-end gap-3">
        <Button
          className="mt-4"
          variant="primary"
          type="button"
          loading={loading}
          onClick={() => handleAdd()}
          loadingPosition="start"
        >
          Добавить в список как есть
        </Button>
        <Button
          className="mt-4"
          type="button"
          variant="primary"
          loading={loadingZero}
          onClick={() => handleLikeThero()}
          loadingPosition="start"
        >
          Добавить в список путем аннулирования
        </Button>
      </div> */}
    </div>
  );
};

export default NotInLists;
