/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col, Modal, Offcanvas } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UilPlusCircle } from '@iconscout/react-unicons';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

import CreateBuyers from './CreateBuyers';
import BuyersChoice from './BuyersChoice';
import Button from 'components/base/Button';
import Unicon from 'components/base/Unicon';
import legalEntityCurrencyService from 'service/legalEntityCurrency';
import expenseSourceService from 'service/expenseSource';
import expensesService from 'service/expenses';
import walletService from 'service/wallet';
import legalEntityArticleService from 'service/legalEntityArticle';
import { showAlert } from 'store/alert/alert.thunk';
import cashboxService from 'service/cashbox';

interface CreateExpensesProps {
  setOpenExpenses: (show: boolean) => void;
  refetch?: any;
}

const SwitchControl = ({
  field,
  label,
  setCheckValue,
  value,
  setValue
}: {
  field: any;
  label: any;
  setValue?: any;
  setCheckValue: (show: boolean) => void;
  value?: any;
}) => {
  return (
    <Form.Check
      type="switch"
      label={label}
      className="cursor-pointer"
      checked={field.value || value}
      onChange={e => {
        field.onChange(e.target.checked);
        setCheckValue(e.target.checked);
        setValue('date_debt', '');
      }}
    />
  );
};

const CreateExpenses = ({ setOpenExpenses, refetch }: CreateExpensesProps) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm();
  const cashboxId = useSelector((state: any) => state?.shiftId?.shiftId);
  const currencyId = useSelector(
    (state: any) => state?.auth?.legal_entity?.currency_id
  );

  const { t, i18n } = useTranslation();
  console.log('currencyId', currencyId);
  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();

  const [openContact, setOpenContact] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [legalEntityCurrencyOption, setLegalEntityCurrencyOption] =
    useState<any>([]);
  const [expenseSourceOption, setExpenseSourceOption] = useState<any>([]);
  const [walletOption, setWalletOption] = useState<any>([]);
  const [articleOption, setArticleOption] = useState([]);
  const [checkedValue, setCheckValue] = useState(false);
  const [expensesId, setExpensesId] = useState('');
  const [selectedContactId, setSelectedContactId] = useState<any>({
    id: '',
    first_name: '',
    last_name: ''
  });
  const [selectedCurrencyId, setSelectedCurrencyId] = useState<any>('');

  //GET Currency to options
  useQuery(['GET_CURRENCY'], async () => {
    await legalEntityCurrencyService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.legal_entity_currencies?.map((option: any) => ({
          value: option?.currency?.id,
          label: option?.currency?.name?.[i18n?.language]
        }));
        setLegalEntityCurrencyOption(options);
      });
  });

  //GET Expense Source to options
  useQuery(['GET_EXPENSE_SOURCE'], async () => {
    await expenseSourceService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.expense_sources?.map((option: any) => ({
          value: option?.id,
          label: option?.name?.[i18n?.language]
        }));
        setExpenseSourceOption(options);
      });
  });

  //GET Wallet to options
  useQuery(
    ['GET_WALLET', selectedCurrencyId],
    async () => {
      if (selectedCurrencyId !== '') {
        await walletService
          .getList({
            offset: 0,
            limit: 100,
            status: true,
            currency_id: selectedCurrencyId
          })
          .then((res: any) => {
            const options = res?.wallets?.map((option: any) => ({
              value: option?.id,
              label: option?.name,
              balance: option?.total_balance
            }));
            setWalletOption(options);
          });
      }
    },
    { enabled: !!selectedCurrencyId }
  );

  //GET Cash to options
  useQuery(
    ['GET_CASH'],
    async () => {
      if (
        cashboxId &&
        currencyId &&
        expensesId === '75e67979-c9d5-4315-b1c4-88e7f3af5632'
      ) {
        await cashboxService
          .getListBalance(cashboxId, { currency_id: currencyId })
          .then((res: any) => {
            setValue('balance', res?.balance ?? 0);
          });
      }
    },
    {
      enabled:
        !!cashboxId &&
        !!currencyId &&
        expensesId === '75e67979-c9d5-4315-b1c4-88e7f3af5632'
    }
  );

  //GET Article to options
  useQuery(['GET_ARTICLE'], async () => {
    await legalEntityArticleService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entity_articles?.map((option: any) => ({
          value: option?.id,
          label: option?.name?.[i18n?.language]
        }));
        setArticleOption(options);
      });
  });

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (cashboxId === '') {
      dispatch(showAlert({ title: t('shift_not_open') }));
    }

    if (+data?.balance - +data?.cost_amount < 0 || +data?.balance === 0) {
      dispatch(showAlert({ title: t('not_enough_product') }));
    }

    const createdData = {
      employee_id: selectedContactId?.id,
      maturity_date: data?.date_debt ? Date.parse(data?.date_debt) : 0,
      is_back: data?.gratuitous,
      currency_id: data?.currency,
      expense_source_id: data?.source,
      article_id: data?.cost_type,
      wallet_id: data?.wallet ? data?.wallet : undefined,
      cashbox_id: cashboxId,
      amount: +data?.cost_amount,
      note: data?.note,
      show_cashflow: data?.show_cashflow,
      show_pl: data?.show_pl
    };

    if (
      cashboxId !== '' &&
      +data?.balance - +data?.cost_amount >= 0 &&
      +data?.balance !== 0
    ) {
      expensesService.createExpense(createdData).then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: `${t('cost')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
          setOpenExpenses(false);
          refetch();
        }
      });
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-2">
          <Col className="d-flex justify-content-between align-items-center">
            <p className="mb-0">
              <span className="text-bold">{t('contact')}: </span>
              {`${selectedContactId?.first_name} ${selectedContactId?.last_name}`}
            </p>
            <div className="d-flex gap-2">
              <button
                type="button"
                className="shadow p-2 bg-white rounded border border-light"
                onClick={() => setOpenContact(true)}
              >
                <FontAwesomeIcon
                  icon={faUserPlus}
                  style={{ color: '#8200BF' }}
                  transform={{ size: 16 }}
                />
              </button>
              <button
                type="button"
                className="shadow p-2 bg-white rounded border border-light"
                onClick={() => setOpenCreate(true)}
              >
                <Unicon
                  style={{ color: '#8200BF' }}
                  className="size-18"
                  icon={UilPlusCircle}
                />
              </button>
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="currency"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = legalEntityCurrencyOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setSelectedCurrencyId(selectedOption?.value);
                        }
                        field.onChange(e);
                      }}
                      ref={inputRef}
                      isInvalid={!!errors.currency}
                    >
                      <option className="d-none" value=""></option>
                      {legalEntityCurrencyOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('currency')}</label>
                    {errors?.currency && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {t('required_field')}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="source"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      isInvalid={!!errors.source}
                      onChange={(e: any) => {
                        const selectedOption = expenseSourceOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setExpensesId(selectedOption?.value);
                          setValue('balance', '');
                        }
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {expenseSourceOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('source_consumption')}
                    </label>
                    {errors?.source && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {t('required_field')}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        {expensesId === 'a4aa5ed5-83f0-4239-8aed-e9db669b7b96' && ( //wallet id
          <Row className="mb-2">
            <Col>
              <div className="react-select-container">
                <Controller
                  name="wallet"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Form.Floating>
                      <Form.Select
                        {...field}
                        isInvalid={!!errors.wallet}
                        onChange={(e: any) => {
                          const selectedOption = walletOption.find(
                            (option: any) => option.value === e.target.value
                          );
                          if (selectedOption) {
                            setValue(
                              'balance',
                              selectedOption?.balance
                                ? selectedOption?.balance
                                : 0
                            );
                          }
                          field.onChange(e);
                        }}
                      >
                        <option className="d-none" value=""></option>
                        {walletOption?.map((option: any) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>

                      <label htmlFor="floatingInputCustom">{t('wallet')}</label>
                      {errors?.wallet && (
                        <span
                          style={{
                            marginTop: '2px',
                            color: 'red',
                            fontSize: '12px'
                          }}
                        >
                          {t('required_field')}
                        </span>
                      )}
                    </Form.Floating>
                  )}
                />
              </div>
            </Col>
          </Row>
        )}
        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                disabled
                {...register('balance')}
                type="text"
                placeholder={t('balance')}
                onFocus={e => e.target.select()}
                autoComplete="off"
              />
              <label htmlFor="floatingInputCustom">{t('balance')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="cost_type"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.cost_type}>
                      <option className="d-none" value=""></option>
                      {articleOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('type_expenses')}
                    </label>
                    {errors?.cost_type && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {t('required_field')}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Controller
              name="cost_amount"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder={t('total_costs')}
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.cost_amount}
                    autoComplete="off"
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('total_costs')}
                  </label>

                  {errors.cost_amount && (
                    <Form.Control.Feedback type="invalid">
                      {t('required_field')}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                disabled
                defaultValue={t('Cash')}
                {...register('cash')}
                type="numbere"
                placeholder={t('payment_type')}
                onFocus={e => e.target.select()}
                autoComplete="off"
              />
              <label htmlFor="floatingInputCustom">{t('payment_type')}</label>
            </Form.Floating>
          </Col>
        </Row>

        {selectedContactId?.id !== '' && (
          <Row className="mb-2">
            <Col className="d-flex gap-3">
              <Form.Group className="md-4 flex-grow-1 bd-highlight">
                <Controller
                  name="date_debt"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Floating>
                      <Flatpickr
                        disabled={checkedValue}
                        className={classNames('form-control')}
                        options={{
                          nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                          prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                          locale: {
                            firstDayOfWeek: 1
                          },
                          monthSelectorType: 'static',
                          onDayCreate: (...[, , , dayElem]) => {
                            if (
                              dayElem.dateObj.getDay() === 5 ||
                              dayElem.dateObj.getDay() === 6
                            ) {
                              dayElem.className += ' weekend-days';
                            }
                          },
                          dateFormat: 'M j, Y',
                          disableMobile: true
                        }}
                        placeholder={t('maturity_date')}
                        {...field}
                      />
                      <label htmlFor="floatingInputCustom">
                        {t('maturity_date')}
                      </label>
                    </Form.Floating>
                  )}
                />
              </Form.Group>

              <Form.Group className="d-flex align-items-center bd-highlight">
                <Controller
                  name="gratuitous"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <SwitchControl
                      value={checkedValue}
                      setCheckValue={setCheckValue}
                      field={field}
                      label={t('free_charge')}
                      setValue={setValue}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row className="mb-2">
          <Col>
            <Form.Floating className="md-4">
              <Form.Control
                {...register('note')}
                as="textarea"
                style={{ height: '100px' }}
                placeholder={t('note')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('note')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Group className="d-flex gap-3 mt-2">
              <Controller
                name="show_cashflow"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Form.Check
                    {...field}
                    label={t('show_cash_flow')}
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                  />
                )}
              />

              <Controller
                name="show_pl"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Form.Check
                    {...field}
                    label={t('show_profits_losses')}
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          className="w-100"
          variant="primary"
          type="submit"
        >
          {t('save')}
        </Button>
      </Form>

      <Modal
        show={openContact}
        onHide={() => {
          setOpenContact(false);
          setSelectedContactId({ id: '', first_name: '', last_name: '' });
        }}
        size="lg"
        className="modal-classs"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('buyer_choice')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BuyersChoice
            setOpenContact={setOpenContact}
            setSelectedContactId={setSelectedContactId}
          />
        </Modal.Body>
      </Modal>

      <Offcanvas
        show={openCreate}
        onHide={() => {
          setOpenCreate(false);
          setSelectedContactId({ id: '', first_name: '', last_name: '' });
        }}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            {t('users')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CreateBuyers
            setOpenCreate={setOpenCreate}
            setSelectedContactId={setSelectedContactId}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CreateExpenses;
