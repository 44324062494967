import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const addamountBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'directories',
    url: '/directories/stock'
  },
  {
    label: 'wallet',
    url: '/directories/wallet'
  },
  {
    label: 'add_amount',
    active: true
  }
];

export type AddAmountType = {
  amount: string;
  note: string;
  date: string;
};

export const addAmount: AddAmountType[] = [
  {
    amount: '5 000 000',
    note: 'Test',
    date: '07.02.2024'
  }
];
