/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClockRotateLeft,
  faMoneyBillWave
} from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Offcanvas } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import usePageSearchParams from 'utils/usePageSearchParams';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import customerEntityService from 'service/charterer';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import PurchaseWithCustomerHistory from './PurchaseWithCustomerHistory';
import AddPurchaseToChild from './AddPurchaseToChild';

export type SettelementData = {
  product_provider: string;
  currency: string;
  debt: string;
  credit: string;
  remainder: string;
  status: string;
  bg_color: string;
  text_color: string;
  guid: string;
};

const PurchaserWithCustomerResult = () => {
  const { t, i18n } = useTranslation();

  const [openSettelementHistory, setOpenSettelementHistory] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState('');
  const [openChild, setOpenChild] = useState(false);
  const [selectedTableData, setSelectedTableData] = useState({});

  const { pathname } = useLocation();
  const [searchParams] = usePageSearchParams();

  const debitor_id = pathname.split('/').pop();
  const currency_id = searchParams.get('currency_id');

  const { data, refetch } = useQuery(
    ['GET_PROVIDER_RESULT'],
    () => {
      if (debitor_id && currency_id) {
        const res = customerEntityService
          .getListCharterer({
            offset: 0,
            limit: 100,
            debitor_id: debitor_id,
            currency_id: currency_id
          })
          .then((res: any) => res?.debit_credit_list);
        return res;
      }
    },
    { enabled: !!debitor_id && !!currency_id }
  );

  const resultData: SettelementData[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        const remainder =
          (parseFloat(items?.amount_debit) || 0) -
          (parseFloat(items?.amount_credit) || 0);
        return {
          product_provider: items?.creditor?.name,
          debt: items?.amount_debit
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.amount_debit)
                .replace(/,/g, ' ')
            : 0,
          credit: items?.amount_credit
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.amount_credit)
                .replace(/,/g, ' ')
            : 0,
          currency_id: items?.currency_id,
          remainder: remainder
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(remainder)
                .replace(/,/g, ' ')
            : 0,
          status: items?.status?.name?.ru,
          currency: items?.currency?.name?.[i18n?.language],
          bg_color: items?.status?.background_color,
          text_color: items?.status?.text_color,
          creditor_id: items?.creditor_id,
          debitor_id: items?.debitor_id,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const buyyersBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'dt_kt',
      url: '/dtkt/provider'
    },
    {
      label: 'offsetting_buyyer',
      url: '/dtkt/purchaser-with-customer'
    },
    {
      label: data && data[0]?.debitor?.name,
      active: true
    }
  ];

  const settelementDataTableColumns: ColumnDef<SettelementData>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            className="p-2"
            onClick={() => {
              setOpenChild(true);
              setSelectedTableData(rowData?.row?.original);
            }}
          >
            <FontAwesomeIcon
              className="cursor-pointer"
              icon={faMoneyBillWave}
              transform={{ size: 20 }}
            />
          </Button>
          <Button
            variant="hover"
            onClick={() => {
              setOpenSettelementHistory(true);
              setSelectedCustomerId(rowData?.row?.original?.guid);
            }}
          >
            <FontAwesomeIcon
              className="cursor-pointer"
              icon={faClockRotateLeft}
              transform={{ size: 20 }}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'product_provider',
      header: t('name_buyer'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'debt',
      header: t('debit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'credit',
      header: t('credit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'remainder',
      header: t('remainder'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        return (
          /* prettier-ignore */
          <div
            style={{
              background: `${rowData?.row?.original?.bg_color}`,
              padding: '8px 10px',
              textAlign: 'center',
              borderRadius: '8px',
              color: `${rowData?.row?.original?.text_color}`,
              fontWeight: "600"
            }}
          >
            {rowData?.row?.original?.status}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: resultData,
    columns: settelementDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <PageBreadcrumb items={buyyersBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{data && data[0]?.debitor?.name}</h2>
        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>

          <Modal
            show={openSettelementHistory}
            onHide={() => {
              setOpenSettelementHistory(false);
              setSelectedCustomerId('');
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {t('history_mutual_settlements')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PurchaseWithCustomerHistory
                selectedCustomerId={selectedCustomerId}
              />
            </Modal.Body>
          </Modal>
        </AdvanceTableProvider>

        <Offcanvas
          show={openChild}
          onHide={() => {
            setOpenChild(false);
          }}
          placement="end"
          backdrop="static"
          keyboard={false}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
              {t('mutual_settlement')}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <AddPurchaseToChild
              setOpenChild={setOpenChild}
              selectedTableData={selectedTableData}
              refetch={refetch}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
};
export default PurchaserWithCustomerResult;
