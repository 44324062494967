/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useMemo, useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Offcanvas } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Button from 'components/base/Button';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import inventoryService from 'service/inventory';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import OpenFolder from './filter/modals/OpenFolder';
import SupperModal from 'components/modals/SupperModal';
import usePreventBackOnRoute from 'utils/usePreventBackOnRoute';
import legalEntitySettingsService from 'service/legalEntitySettings';

export type OpenFolderData = {
  employee: string;
  invoce_date: string;
  overhead: string;
  warehouse: string;
  create_date: string;
  guid: string;
  warehouse_id: string;
};

export const createInventoryBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'inventory',
    active: true
  }
];

const InventoryMainCreate = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [openFolder, setOpenFolder] = useState(false);
  const [openSupperModal, setOpenSupperModal] = useState(false);
  const [confirmToDelete, setConfirmToDelete] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(
    null
  );

  // prevent back
  usePreventBackOnRoute('/inventory/create-inventory');

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  //Get Product Search
  const { data, refetch } = useQuery(['GET_INVENTORY'], () => {
    const res = inventoryService
      .getList({
        offset: 0,
        limit: 100,
        status_id: 'ba94f8a8-3a42-4d1e-a351-0e5349e3f512'
      })
      .then((res: any) => res?.inventories);
    return res;
  });

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const openForlderData: OpenFolderData[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    return (
      data?.map((items: any) => {
        return {
          employee: `${items?.user?.first_name} ${items?.user?.last_name}`,
          overhead: items?.inventory_number ?? 0,
          /* prettier-ignore */
          invoce_date:
            items?.inventory_date === 0 || !items?.inventory_date
              ? ''
              : formatDateToTimeZone(items?.inventory_date, timeZone),
          /* prettier-ignore */
          create_date:  formatDateToTimeZone(items?.date_create, timeZone),
          warehouse: items?.ware_house?.name,
          warehouse_id: items?.warehouse_id,
          smart_mode: items?.smart_mode === true ? t('yes') : t('no'),
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, dataSettings, t, i18n?.language]);

  const handleDelete = (id: string) => {
    setSelectedInvoiceId(id);
    setOpenSupperModal(true);
  };

  const openFolderDataTableColumns: ColumnDef<OpenFolderData>[] = [
    {
      id: 'action',
      cell: rowData => {
        return (
          <div className="d-flex gap-2">
            <Button
              variant="hover"
              className="p-2"
              onClick={() => {
                navigate(
                  `/inventory/main/${rowData?.row?.original?.guid}?warehouse_id=${rowData?.row?.original?.warehouse_id}`
                );
              }}
            >
              <FeatherIcon icon="plus" className="cursor-pointer" size={20} />
            </Button>
            <Button
              variant="hover"
              className="p-2"
              onClick={() => handleDelete(rowData?.row?.original?.guid)}
            >
              <FeatherIcon
                icon="trash-2"
                className="cursor-pointer text-danger"
                size={20}
              />
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'employee',
      header: t('employees'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'invoce_date',
      header: t('date_invoice'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'overhead',
      header: t('invoice_number'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'warehouse',
      header: t('warehouse'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'smart_mode',
      header: t('smart_mode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'create_date',
      header: t('date_create'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: openForlderData,
    columns: openFolderDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    if (confirmToDelete && selectedInvoiceId) {
      inventoryService.deleteInventory(selectedInvoiceId).finally(() => {
        refetch();
        setOpenSupperModal(false);
        setConfirmToDelete(false);
        setSelectedInvoiceId(null);
      });
    }
  }, [confirmToDelete, selectedInvoiceId, refetch]);

  return (
    <div>
      <PageBreadcrumb items={createInventoryBreadcrumbItems} />

      <div>
        <div className="d-flex justify-content-between align-items-center mb-5">
          <h2>{t('inventory')}</h2>
          <Button
            onClick={() => setOpenFolder(true)}
            style={{ height: '48px' }}
            variant="primary"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            {t('new')}
          </Button>
        </div>

        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>
      </div>

      <SupperModal
        show={openSupperModal}
        setShow={setOpenSupperModal}
        setConfirm={setConfirmToDelete}
        title={t('inventory')}
        bodyText={t('you_want_delete')}
      />

      <Offcanvas
        show={openFolder}
        onHide={() => setOpenFolder(false)}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            {t('new')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <OpenFolder />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default InventoryMainCreate;
