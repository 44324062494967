/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import returnItemService from 'service/returnItemService';

import { OutgoingReturningResult } from 'data/returning';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';

const OutgoingReturningResultTable = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const returningId = pathname.split('/').pop();

  const { data } = useQuery(
    ['GET_ORDER_ITEM', returningId],
    async () => {
      if (returningId !== '') {
        const res = await returnItemService
          .getReturnItemOutById(returningId, {
            offset: 0,
            limit: 10
          })
          .then((res: any) => {
            return res?.return_items;
          });
        return res;
      }
    },
    { enabled: !!returningId }
  );

  const outgoingResultData: OutgoingReturningResult[] = useMemo(() => {
    const resultData =
      data?.map((items: any) => {
        /* prettier-ignore */
        const final_price =
          items?.price_in && items?.quantity
            ? +items.price_in * +items.quantity
            : 0;

        const total_price =
          items?.price && items?.quantity ? +items.price * +items.quantity : 0;
        return {
          guid: items?.id,
          can_update_order_item: items?.can_update,
          product_id: items?.product_id,
          parent_product_id: items?.parent_product_id,
          nomenclature_id: items?.nomenclature_id,
          recipient_id: items?.recipient_id,
          product_name: items?.product_short_name,
          barcode: items?.barcode,
          currency: items?.currency?.name?.[i18n?.language],
          units: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          measure_unit_id: items?.nomenclature?.measure_unit?.id,
          amount: items?.quantity ?? 0,
          status: items?.status?.name?.ru,
          cost: items?.price
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.price)
                .replace(/,/g, ' ')
            : 0,
          cost_change: items?.price_in
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.price_in)
                .replace(/,/g, ' ')
            : 0,
          quantity: items?.quantity_send ?? 0,
          final_price: final_price
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(final_price)
                .replace(/,/g, ' ')
            : 0,
          total_price: total_price
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(total_price)
                .replace(/,/g, ' ')
            : 0
        };
      }) ?? [];

    return resultData;
  }, [data, t, i18n?.language]);

  const buyyersBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'returns',
      url: '/returning/outgoing'
    },
    {
      label: 'outgoing',
      url: '/returning/outgoing'
    },
    {
      label: 'application_number',
      active: true
    }
  ];

  const outgoingResultTableColumns: ColumnDef<OutgoingReturningResult>[] = [
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'cost',
      header: t('purchase_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'cost_change',
      header: t('price_difference'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'amount',
      header: t('short_quantity_return'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'total_price',
      header: t('final_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'final_price',
      header: t('final_purchase_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: outgoingResultData,
    columns: outgoingResultTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const sum = outgoingResultData.reduce((acc: any, item: any) => {
    const allCost =
      item?.total_price === undefined
        ? 0
        : +item?.total_price?.split(' ')?.join('');
    return acc + allCost;
  }, 0);
  return (
    <div>
      <PageBreadcrumb items={buyyersBreadcrumbItems} />

      <div>
        <h2 className="mb-5">{t('application_number')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>
        <div className="footer-returning">
          <div className="box">
            <p className="mb-0 text-bold">{t('total_amount')}</p>
            <span>
              {sum
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(sum)
                    .replace(/,/g, ' ')
                : '0'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutgoingReturningResultTable;
