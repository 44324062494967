/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import FeatherIcon from 'feather-icons-react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import movingItemService from 'service/movingItem';

export type NewReturningInfo = {
  product_name: string;
  barcode: string;
  unit: string;
  currency: string;
  current_balance: string;
  amount: string;
  total_price: string;
};

interface NewMovementsTableProps {
  movingItemData?: any;
  newMovingId?: any;
  setMovingItemData?: any;
  setRef?: any;
}

const NewMovementsTable = ({
  movingItemData,
  newMovingId,
  setMovingItemData,
  setRef
}: NewMovementsTableProps) => {
  const { t, i18n } = useTranslation();

  const { data } = useQuery(
    ['GET_MOVING_ITEMS', newMovingId],
    async () => {
      if (newMovingId) {
        const res = await movingItemService
          .getList({
            offset: 0,
            limit: 100,
            moving_id: newMovingId
          })
          .then((res: any) => {
            return res?.moving_items;
          });
        return res;
      }
    },
    { enabled: !!newMovingId }
  );

  const generateData: NewReturningInfo[] = useMemo(() => {
    const newData =
      data?.map((items: any) => {
        return {
          current_balance: items?.quantity_send ?? 1,
          product_name: items?.product?.name,
          barcode: items?.product?.barcode,
          unit: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          measure_unit_id: items?.nomenclature?.measure_unit.id,
          amount: items?.quantity_send ?? 1,
          total_price: items?.price_wholesale ?? 0,
          currency: items?.currency?.name?.[i18n?.language],
          nomenclature_id: items?.nomenclature_id,
          product_id: items?.product_id,
          product_parent_id: items?.product_parent_id,
          price: items?.price,
          price_in: items?.price_in,
          is_vat: items?.is_vat ? items?.is_vat : false,
          currency_id: items?.currency_id,
          package_quantity: items?.package_quantity ?? 0
        };
      }) ?? [];

    setMovingItemData([...movingItemData, ...newData]);

    return newData;
  }, [data, t, i18n?.language]);

  console.log(generateData);

  const handleRemoveProductItem = (indexToRemove: number) => {
    setMovingItemData(
      movingItemData.filter((_: any, index: any) => index !== indexToRemove)
    );
  };

  const newReturningDataTableColumns: ColumnDef<NewReturningInfo>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() => handleRemoveProductItem(+rowData?.row?.id)}
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } }
      }
    },
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'unit',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    // {
    //   accessorKey: 'currency',
    //   header: 'Валюта',
    //   meta: {
    //     cellProps: { className: 'text-900' }
    //   }
    // },

    {
      accessorKey: 'current_balance',
      header: t('current_balance'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'amount',
      header: t('quantity'),
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '1';

        const currentBalance = rowData?.row?.original?.current_balance;

        const [value, setValue] = useState(amount);

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name, value } = e.target;
          let newValue = parseFloat(value); // Convert value to a float
          const currentBalanceNumber = parseFloat(currentBalance); // Convert currentBalance to a number

          if (newValue > currentBalanceNumber) {
            newValue = currentBalanceNumber;
          }

          const updatedItems = movingItemData.map((item: any, i: any) => {
            if (i === +index) {
              return { ...item, [name]: newValue };
            }
            return item;
          });

          setMovingItemData(updatedItems);
          setValue(newValue); // Ensure the displayed value is updated correctly
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;
          if (/^\d*\.?\d*$/.test(newValue) && +newValue <= +currentBalance) {
            setValue(newValue);
          } else if (+newValue > +currentBalance) {
            setValue(currentBalance.toString());
          }
        };

        return (
          <div>
            <Form.Group>
              <Form.Control
                type="text"
                name="amount"
                value={value}
                onChange={handleChange}
                onBlur={(e: any) => onBlur(e, rowData.row?.id)}
                onFocus={e => e.target.select()}
                ref={setRef(`amount_${rowData.row.id}`)}
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'total_price',
      header: t('wholesale_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: movingItemData,
    columns: newReturningDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default NewMovementsTable;
