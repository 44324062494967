/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo, useRef } from 'react';
import { Offcanvas, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import FeatherIcon from 'feather-icons-react';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Button from 'components/base/Button';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import purchaserService from 'service/purchaser';
import CreatePurchaser from './CreatePurchaser';
import ImportedPurchasserTable from './ImportedPurchasserTable';

export const purchaserBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Дт Кт',
    url: '/dtkt/purchaser'
  },
  {
    label: 'Покупатель',
    active: true
  }
];

export type PurchaserType = {
  phone: string;
  second_name: string;
  first_name: string;
  patronymic: string;
  country: string;
  region: string;
  area: string;
  gender: string;
};

const Purchaser = () => {
  const { t, i18n } = useTranslation();
  const [openPurchaser, setOpenPurchaser] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [importData, setImportData] = useState<any>([]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  //Get Purchaser
  const { data, refetch } = useQuery(['GET_PURCHASER'], () => {
    const res = purchaserService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.data?.users);
    return res;
  });

  const purchaserData: PurchaserType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          phone: items?.phone_number,
          second_name: items?.first_name,
          first_name: items?.last_name,
          patronymic: items?.surname,
          gender: items?.gender === 'MALE' ? t('male') : t('female'),
          country_id: items?.country?.name?.[i18n?.language],
          region: items?.region?.name?.[i18n?.language],
          area: items?.district?.name?.[i18n?.language]
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const purchaserTableColumns: ColumnDef<PurchaserType>[] = [
    {
      accessorKey: 'phone',
      header: t('phone_number'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'second_name',
      header: t('second_name'),
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },
    {
      accessorKey: 'first_name',
      header: t('name'),
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },
    {
      accessorKey: 'patronymic',
      header: t('surname'),
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },

    {
      accessorKey: 'country_id',
      header: t('country'),
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },

    {
      accessorKey: 'region',
      header: t('region'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'area',
      header: t('area'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'gender',
      header: t('gender'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: purchaserData,
    columns: purchaserTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleExportClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = e => {
        if (e?.target?.result) {
          const data = new Uint8Array(e.target.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          const formattedData = (jsonData as any[][])
            .slice(1)
            .filter((row: any) =>
              row.some(
                (cell: any) =>
                  cell !== null && cell !== undefined && cell !== ''
              )
            )
            .map(
              ([
                first_name = '',
                last_name = '',
                phone_number,
                credit = 0,
                maturity_date,
                currency = '',
                note = ''
              ]) => ({
                first_name: String(first_name),
                last_name: String(last_name),
                phone_number: String(phone_number),
                credit: String(credit),
                /* prettier-ignore */
                maturity_date:
                  typeof maturity_date === 'string'
                    ? validateAndFormatDate(maturity_date) // Validate date
                    : maturity_date
                      ? excelDateToJSDate(maturity_date)
                      : '',
                currency: String(currency),
                note: String(note)
              })
            );

          if (formattedData?.length > 0) {
            setOpenImportModal(true);
            setImportData(formattedData);
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const excelDateToJSDate = (serial: number): string => {
    const utcDays = Math.floor(serial - 25569); // Excel's serial number is days since 1900-01-01, we subtract 25569 to get the number of days since 1970-01-01 (Unix epoch)
    const date = new Date(utcDays * 86400 * 1000); // Convert days to milliseconds and create a date
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    return `${day}.${month}.${year}`;
  };

  const validateAndFormatDate = (dateString: string): string => {
    // Check if the date is in the format DD.MM.YYYY
    const datePattern = /^\d{2}\.\d{2}\.\d{4}$/;

    if (datePattern.test(dateString)) {
      return dateString; // Return the date if it is valid
    }

    // If the format is invalid (e.g., "01,01,2024"), return an empty string
    return '';
  };

  const handleGetExcample = () => {
    const url = 'https://dev.test.cdn.api.rizo.uz/docs/customer_example.xlsx';

    const link = document.createElement('a');
    link.href = url;
    link.download = 'Шаблон.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <PageBreadcrumb items={purchaserBreadcrumbItems} />
      <div className="d-flex justify-content-between align-items-center">
        <h2>{t('buyer')}</h2>

        <div className="d-flex gap-2">
          <Button variant="outline-info" onClick={handleGetExcample}>
            <FeatherIcon icon="download-cloud" className="me-2" size={20} />
            {t('sample')}
          </Button>
          <Button variant="outline-primary" onClick={handleExportClick}>
            <FeatherIcon icon="download-cloud" className="me-2" size={20} />
            {t('import')}
          </Button>
          <Button
            variant="primary"
            style={{ height: '48px' }}
            onClick={() => setOpenPurchaser(true)}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            {t('new')}
          </Button>

          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept=".xlsx, .xls" // Specify the file types you want to allow
          />
        </div>
      </div>
      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>

      <Offcanvas
        show={openPurchaser}
        onHide={() => {
          setOpenPurchaser(false);
        }}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            {t('new')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CreatePurchaser
            setOpenPurchaser={setOpenPurchaser}
            refetch={refetch}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={openImportModal}
        onHide={() => {
          setOpenImportModal(false);
          setImportData([]);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('imported_data')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImportedPurchasserTable
            setOpenImportModal={setOpenImportModal}
            setImportData={setImportData}
            importData={importData}
            refetch={refetch}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Purchaser;
