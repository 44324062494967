/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import PurchaseByGoodsTable from './PurchaseByGoodsTable';
import GoodsFilter from './filter/GoodsFilter';

import './styles.scss';

export const goodsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'reports',
    url: '/reports/purchase/invoice'
  },
  {
    label: 'purchase',
    url: '/reports/purchase/invoice'
  },

  {
    label: 'purchase_products',
    active: true
  }
];

const PurchaseByGoods = () => {
  const { t } = useTranslation();
  const [generateTableData, setGeneratedTableData] = useState([]);
  const [savedValues, setSavedValues] = useState([]);
  const [getName, setGetName] = useState('');
  const [getBarcode, setGetBarcode] = useState('');
  const [getFilterData, setFilterData] = useState({});
  const [totalAmount, setTotalAmount] = useState<any>([]);

  return (
    <div>
      <PageBreadcrumb items={goodsBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{t('purchase_products')}</h2>

        <GoodsFilter
          setGeneratedTableData={setGeneratedTableData}
          savedValues={savedValues}
          setSavedValues={setSavedValues}
          setGetName={setGetName}
          setGetBarcode={setGetBarcode}
          getName={getName}
          getBarcode={getBarcode}
          setFilterData={setFilterData}
          setTotalAmount={setTotalAmount}
        />
        <PurchaseByGoodsTable
          generateTableData={generateTableData}
          setGeneratedTableData={setGeneratedTableData}
          getName={getName}
          getBarcode={getBarcode}
          getFilterData={getFilterData}
          totalAmount={totalAmount}
          setTotalAmount={setTotalAmount}
        />
      </div>
    </div>
  );
};

export default PurchaseByGoods;
