/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';

import Button from 'components/base/Button';
import AddAmountTable from './AddAmountTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { addamountBreadcrumbItems } from 'data/addamount';
import useAdvanceTable from 'hooks/useAdvanceTable';
import walletService from 'service/wallet';
import CreateAddAmount from './CreateAddAmount';
import legalEntitySettingsService from 'service/legalEntitySettings';

export type AddAmountType = {
  amount: string;
  note: string;
  date: string;
  currency_id: string;
  wallet_id: string;
};

const AddAmount = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const addId = pathname.split('/').pop();

  const [modalShow, setModalShow] = useState(false);

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  //Get NDS
  const { data, refetch } = useQuery(
    ['GET_WALLET_SETTLEMENT'],
    () => {
      if (addId) {
        const res = walletService
          .getWalletSettlementById(addId)
          .then((res: any) => res?.wallet_settlements);
        return res;
      }
    },
    { enabled: !!addId }
  );

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const walletSettlementData: AddAmountType[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    return (
      data?.map((items: any) => {
        return {
          amount: items?.balance
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.balance)
                .replace(/,/g, ' ')
            : '0',
          note: items?.note,
          currency_id: items?.currency?.id,
          wallet_id: items?.wallet_id,
          /* prettier-ignore */
          date: formatDateToTimeZone(items?.date_create, timeZone)
        };
      }) ?? []
    );
  }, [data, addId, dataSettings]);

  const addAmountTableColumns: ColumnDef<AddAmountType>[] = [
    {
      accessorKey: 'amount',
      header: t('amounts'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'note',
      header: t('note'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'date',
      header: t('date_replenishment'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: walletSettlementData,
    columns: addAmountTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={addamountBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">{t('add_amount')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex justify-content-between">
              <SearchBox
                placeholder={t('search')}
                onChange={handleSearchInputChange}
              />

              <div className="d-flex justify-content-between gap-2">
                <Button variant="primary" onClick={() => setModalShow(true)}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  {t('add')}
                </Button>
              </div>
            </div>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <AddAmountTable />
          </div>
        </AdvanceTableProvider>
      </div>

      <Offcanvas
        show={modalShow}
        onHide={() => setModalShow(false)}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            {t('add_amount')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CreateAddAmount
            refetch={refetch}
            setModalShow={setModalShow}
            addId={addId}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AddAmount;
