/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Offcanvas } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import CreateExpenses from './CreateExpenses';
import Button from 'components/base/Button';
import usersService from 'service/users';
import legalEntityArticleService from 'service/legalEntityArticle';
import legalEntityCurrencyService from 'service/legalEntityCurrency';
import './styles.scss';

interface ExpensesFilterProps {
  refetch?: any;
  setDateFrom?: any;
  setDateTo?: any;
  dateFrom?: any;
  dateTo?: any;
  setOptionCurrencyId?: any;
  setOptionArticleId?: any;
  setOptionUserId?: any;
}
const ExpensesFilter = ({
  refetch,
  setDateFrom,
  setDateTo,
  dateFrom,
  dateTo,
  setOptionCurrencyId,
  setOptionArticleId,
  setOptionUserId
}: ExpensesFilterProps) => {
  const { t, i18n } = useTranslation();
  const { control } = useForm();

  const [openExpenses, setOpenExpenses] = useState(false);
  const [usersOption, setUsersOption] = useState<any>([]);
  const [articleOption, setArticleOption] = useState<any>([]);
  const [legalEntityCurrency, setLegalEntityCurrency] = useState<any>([]);

  const handleStartDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const startDate = new Date(date[0]);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    } else {
      const startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    }
  };

  const handleEndDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const endDateInMillis = date[0]?.getTime();
      setDateTo(endDateInMillis);
    } else {
      const endDateInMillis = date.getTime();
      setDateTo(endDateInMillis);
    }
  };

  //GET Users to options
  useQuery(['GET_USERS'], async () => {
    await usersService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.data?.users?.map((option: any) => ({
        value: option?.id,
        label: option?.first_name
      }));
      setUsersOption(options);
    });
  });

  //GET Article to options
  useQuery(['GET_ARTICLE'], async () => {
    await legalEntityArticleService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entity_articles?.map((option: any) => ({
          value: option?.id,
          label: option?.name?.[i18n?.language]
        }));
        setArticleOption(options);
      });
  });

  //GET Currency to options
  useQuery(['GET_CURRENCY'], async () => {
    await legalEntityCurrencyService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.legal_entity_currencies?.map((option: any) => ({
          value: option?.currency?.id,
          label: option?.currency?.name?.[i18n?.language]
        }));
        setLegalEntityCurrency(options);
      });
  });

  return (
    <div>
      <Form className="exp-rows">
        <Form.Group>
          <div className="react-select-container">
            <Controller
              name="employees"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    onChange={(e: any) => {
                      const selectedOption = usersOption.find(
                        (option: any) => option.value === e.target.value
                      );
                      if (selectedOption) {
                        setOptionUserId(selectedOption?.value);
                      }
                      field.onChange(e);
                    }}
                  >
                    <option className="d-none" value=""></option>
                    {usersOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">{t('employees')}</label>
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group>
          <div className="react-select-container">
            <Controller
              name="cost_type"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    onChange={(e: any) => {
                      const selectedOption = articleOption.find(
                        (option: any) => option.value === e.target.value
                      );
                      if (selectedOption) {
                        setOptionArticleId(selectedOption?.value);
                      }
                      field.onChange(e);
                    }}
                  >
                    <option className="d-none" value=""></option>
                    {articleOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">
                    {t('type_expenses')}
                  </label>
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group>
          <div className="react-select-container">
            <Controller
              name="currency"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    onChange={(e: any) => {
                      const selectedOption = legalEntityCurrency.find(
                        (option: any) => option.value === e.target.value
                      );
                      if (selectedOption) {
                        setOptionCurrencyId(selectedOption?.value);
                      }
                      field.onChange(e);
                    }}
                  >
                    <option className="d-none" value=""></option>
                    {legalEntityCurrency?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">{t('currency')}</label>
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group>
          <Form.Floating>
            <Flatpickr
              className={classNames('form-control')}
              options={{
                nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                locale: {
                  firstDayOfWeek: 1
                },
                monthSelectorType: 'static',
                onDayCreate: (...[, , , dayElem]) => {
                  if (
                    dayElem.dateObj.getDay() === 5 ||
                    dayElem.dateObj.getDay() === 6
                  ) {
                    dayElem.className += ' weekend-days';
                  }
                },
                dateFormat: 'M j, Y',
                disableMobile: true
              }}
              value={dateFrom}
              onChange={handleStartDateChange}
              placeholder={t('date_from')}
            />
            <label htmlFor="floatingInputCustom">{t('date_from')}</label>
          </Form.Floating>
        </Form.Group>

        <Form.Group>
          <Form.Floating>
            <Flatpickr
              className={classNames('form-control')}
              options={{
                nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                locale: {
                  firstDayOfWeek: 1
                },
                monthSelectorType: 'static',
                onDayCreate: (...[, , , dayElem]) => {
                  if (
                    dayElem.dateObj.getDay() === 5 ||
                    dayElem.dateObj.getDay() === 6
                  ) {
                    dayElem.className += ' weekend-days';
                  }
                },
                dateFormat: 'M j, Y',
                disableMobile: true
              }}
              value={dateTo}
              onChange={handleEndDateChange}
              placeholder={t('date_to')}
            />
            <label htmlFor="floatingInputCustom">{t('date_to')}</label>
          </Form.Floating>
        </Form.Group>

        <Form.Group className="exp-btn ms-auto">
          <Button variant="primary" onClick={() => setOpenExpenses(true)}>
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            {t('new')}
          </Button>
          {/* <Button variant="primary">Применить</Button> */}
        </Form.Group>
      </Form>

      <Offcanvas
        show={openExpenses}
        onHide={() => setOpenExpenses(false)}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            {t('new')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CreateExpenses setOpenExpenses={setOpenExpenses} refetch={refetch} />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default ExpensesFilter;
