/* eslint-disable @typescript-eslint/no-explicit-any */
import { Row, Col } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { NewMovementsData } from 'data/newmovements';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import movingOutService from 'service/movingOut';
import movingService from 'service/movement';
import useAdvanceTable from 'hooks/useAdvanceTable';
import Button from 'components/base/Button';
import SupperModal from 'components/modals/SupperModal';

interface NewMovementsDataTableProps {
  setOpenFolder: (show: boolean) => void;
}

const NewMovementsDataTable = ({
  setOpenFolder
}: NewMovementsDataTableProps) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const legalEntityName = useSelector(
    (state: any) => state?.auth?.legal_entity?.name
  );

  const [openSupperModal, setOpenSupperModal] = useState(false);
  const [confirmToDelete, setConfirmToDelete] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(
    null
  );

  const { data, refetch } = useQuery(['GET_MOVING_OUT'], async () => {
    const res = await movingOutService
      .getList({
        offset: 0,
        limit: 100,
        status_id: 'd9f0bc1f-5245-428a-800b-d51aadde2557'
      })
      .then((res: any) => {
        return res?.movings;
      });
    return res;
  });

  const tableData: NewMovementsData[] = useMemo(() => {
    const resultData =
      data?.map((items: any) => {
        return {
          orders: items?.moving_number,
          sender: items?.legal_entity?.name,
          recipient: legalEntityName,
          currency: items?.currency?.name?.[i18n?.language],
          date_create: new Date(items?.date_create)
            .toLocaleDateString('en-GB')
            .replace(/\//g, '-'),
          recipient_id: items?.recipient_id,
          guid: items?.id
        };
      }) ?? [];

    return resultData;
  }, [data, t, i18n?.language]);

  const handleDelete = (id: string) => {
    setSelectedInvoiceId(id);
    setOpenSupperModal(true);
  };

  const newApplicationsTableColumns: ColumnDef<NewMovementsData>[] = [
    {
      id: 'action',
      cell: rowData => {
        return (
          <Row>
            <Col className="d-flex gap-2">
              <Button
                variant="hover"
                className="p-2"
                onClick={() => {
                  navigate(
                    `/movements/outgoing/filter/newmovements/${rowData?.row?.original?.guid}?sender_id=${rowData?.row?.original?.recipient_id}`
                  );
                  setOpenFolder(false);
                }}
              >
                <FeatherIcon icon="plus" className="cursor-pointer" size={20} />
              </Button>
              <Button
                variant="hover"
                className="p-2"
                onClick={() => handleDelete(rowData?.row?.original?.guid)}
              >
                <FeatherIcon
                  icon="trash-2"
                  className="cursor-pointer text-danger"
                  size={20}
                />
              </Button>
            </Col>
          </Row>
        );
      },
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-end' }
      }
    },

    {
      accessorKey: 'orders',
      header: t('order_number'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'sender',
      header: t('sender'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'recipient',
      header: t('recipient'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'date_create',
      header: t('date_create'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: tableData,
    columns: newApplicationsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    if (selectedInvoiceId && confirmToDelete) {
      movingService.deleteMoving(selectedInvoiceId).finally(() => {
        refetch();
        setOpenSupperModal(false);
        setConfirmToDelete(false);
        setSelectedInvoiceId(null);
      });
    }
  }, [confirmToDelete, selectedInvoiceId, refetch]);

  return (
    <AdvanceTableProvider {...table}>
      <div>
        <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
        <AdvanceTableFooter pagination />
      </div>

      <SupperModal
        show={openSupperModal}
        setShow={setOpenSupperModal}
        setConfirm={setConfirmToDelete}
        title={t('products')}
        bodyText={t('you_want_delete')}
      />
    </AdvanceTableProvider>
  );
};

export default NewMovementsDataTable;
