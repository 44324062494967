/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import analyticRevaluationService from 'service/analyticRevaluation';
import legalEntitySettingsService from 'service/legalEntitySettings';

export type Invoice = {
  supplier: string;
  warehouse: string;
  invoice: string;
  invoice_date: string;
  currency: string;
  revaluation_amount: string;
  create_date: string;
};

interface AccordingToInvoiceTableProps {
  dateFrom?: any;
  dateTo?: any;
  optionSenderId?: any;
  optionWarehouseId?: any;
}

const AccordingToInvoiceTable = ({
  dateFrom,
  dateTo,
  optionSenderId,
  optionWarehouseId
}: AccordingToInvoiceTableProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const navigateTo = (row: any) => {
    navigate(
      `/analytics/revaluation/revaluation-invoice/invoice-result/${row?.original?.guid}`
    );
  };

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  console.log('dateTO', typeof dateTo);
  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? undefined
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000
          : undefined;

  const { data, refetch } = useQuery(
    ['GET_REVALUATION'],
    async () => {
      const res = await analyticRevaluationService
        .getList({
          offset: 0,
          limit: 100,
          warehouse_id: optionWarehouseId ? optionWarehouseId : undefined,
          supplier_id: optionSenderId ? optionSenderId : undefined,
          date_from: dateFrom,
          date_to: adjustedDateTo
        })
        .then((res: any) => res?.items);
      return res;
    },
    {
      enabled:
        !!dateFrom ||
        !!adjustedDateTo ||
        !!optionSenderId ||
        !!optionWarehouseId
    }
  );

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const invoiceData: Invoice[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    const resData =
      data?.map((items: any) => {
        return {
          supplier: items?.legal_entity?.name,
          warehouse: items?.wareHouse?.name,
          invoice: items?.invoice_number,
          /* prettier-ignore */
          invoice_date:  formatDateToTimeZone(items?.invoice_date, timeZone),
          currency: items?.currency?.name?.[i18n?.language],
          revaluation_amount: items?.amount ?? 0,
          /* prettier-ignore */
          create_date:  formatDateToTimeZone(items?.date_created, timeZone),
          guid: items?.id
        };
      }) ?? [];

    return resData;
  }, [data, dataSettings, t, i18n?.language]);

  const invoiceTableColumns: ColumnDef<Invoice>[] = [
    {
      accessorKey: 'supplier',
      header: t('supplier'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'warehouse',
      header: t('warehouse'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'invoice',
      header: t('invoice_number'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'invoice_date',
      header: t('date_invoice'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'revaluation_amount',
      header: t('revaluation_amount'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'create_date',
      header: t('date_create'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: invoiceData,
    columns: invoiceTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    refetch();
  }, [dateFrom, dateTo, optionSenderId, optionWarehouseId]);

  return (
    <AdvanceTableProvider {...table}>
      <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          navigateTo={navigateTo}
        />
        <AdvanceTableFooter pagination />
      </div>
    </AdvanceTableProvider>
  );
};

export default AccordingToInvoiceTable;
