/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import analyticRevaluationService from 'service/analyticRevaluation';

export type InvoiceResult = {
  product_name: string;
  barcode: string;
  currency: string;
  units: string;
  old_purchase_price: string;
  quantity: string;
  new_purchase_price: string;
  revaluation_amount: string;
};

const AccordingToInvoiceResultTable = () => {
  const { t, i18n } = useTranslation();

  const { pathname } = useLocation();
  const invoiceId = pathname.split('/').pop();

  const { data } = useQuery(
    ['GET_MOVING_ITEM_IN', invoiceId],
    async () => {
      if (invoiceId) {
        const res = await analyticRevaluationService
          .getInvoiceItemInById({
            invoice_id: invoiceId,
            offset: 0,
            limit: 10
          })
          .then((res: any) => {
            return res?.items;
          });
        return res;
      }
    },
    { enabled: !!invoiceId }
  );

  console.log('test update');

  const invoiceResultData: InvoiceResult[] = useMemo(() => {
    const resultData =
      data?.map((items: any) => {
        return {
          product_name: items?.name,
          barcode: items?.barcode,
          currency: items?.currency?.name?.[i18n?.language],
          units: items?.measure_unit?.name?.[i18n?.language],
          old_purchase_price: items?.old_price_in ?? 0,
          quantity: items?.quantity ?? 0,
          new_purchase_price: items?.new_price_in ?? 0,
          revaluation_amount: items?.amount ?? 0
        };
      }) ?? [];

    return resultData;
  }, [data, t, i18n?.language]);

  const invoiceResultTableColumns: ColumnDef<InvoiceResult>[] = [
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'old_purchase_price',
      header: t('old_purchase_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'quantity',
      header: t('quantity'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'new_purchase_price',
      header: t('new_purchase_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'revaluation_amount',
      header: t('revaluation_amount'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: invoiceResultData,
    columns: invoiceResultTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const invoiceBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'analytics',
      url: '/analytics/revaluation/revaluation-invoice'
    },

    {
      label: 'revaluation_products',
      url: '/analytics/revaluation/revaluation-invoice'
    },
    {
      label: 'application_number',
      active: true
    }
  ];
  return (
    <div>
      <PageBreadcrumb items={invoiceBreadcrumbItems} />

      <div>
        <h2 className="mb-5">{t('application_number')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default AccordingToInvoiceResultTable;
