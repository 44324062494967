/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { UilCalendarAlt } from '@iconscout/react-unicons';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import legalEntityCurrencyService from 'service/legalEntityCurrency';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import ProfitAndCostInfo from './ProfitAndCostInfo';
import PaymentAndTransaction from './PaymentAndTransaction';
import SalesAndGoodsInfo from './SalesAndGoodsInfo';
import SaleGraph from './SaleGraph';
import styles from './styles.module.scss';
import 'flatpickr/dist/flatpickr.css';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { UzbekLatin } from 'flatpickr/dist/l10n/uz_latn.js';

export const branchOption = [
  {
    label: 'Epa',
    value: 'Epa'
  }
];

const HomePage = () => {
  const { t, i18n } = useTranslation();

  const isAuth = useSelector(
    (state: { auth: { isAuth: boolean } }) => state?.auth?.isAuth
  );
  const currencyId = useSelector(
    (state: any) => state?.auth?.legal_entity?.currency_id
  );
  const selectRef = useRef<HTMLSelectElement>(null);

  const [activeButton, setActiveButton] = useState<string | null>('Вчера');
  const [currencyOption, setCurrencyOption] = useState<any>();
  const [selectedCurrencyId, setSelectedCurrencyId] = useState<string>('');
  const [dateFrom, setDateFrom] = useState<number | null>(null);
  const [dateTo, setDateTo] = useState<number | null>(null);

  const dashboardBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'home_page',
      active: true
    }
  ];

  useEffect(() => {
    initializeDates();
  }, []);

  useEffect(() => {
    setDefaultCurrency();
  }, [currencyId, currencyOption]);

  const initializeDates = () => {
    // Set default date to "Вчера"
    const today = new Date();
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - 1);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(today);
    // endDate.setDate(today.getDate() + 1);
    endDate.setHours(0, 0, 0, 0);
    setDateFrom(startDate.getTime());
    setDateTo(endDate.getTime());
  };

  const setDefaultCurrency = () => {
    if (currencyId && currencyOption?.length > 0) {
      const defaultCurrency = currencyOption.find(
        (option: any) => option.value === currencyId
      );
      if (defaultCurrency) {
        setSelectedCurrencyId(defaultCurrency.value);
      }
    }
  };

  const handleDateChange = (selectedDates: any) => {
    if (selectedDates.length === 2) {
      const startDateInMillis = new Date(selectedDates[0]).setHours(0, 0, 0, 0);
      let endDateInMillis = new Date(selectedDates[1]).setHours(0, 0, 0, 0);

      if (startDateInMillis === endDateInMillis) {
        endDateInMillis += 24 * 60 * 60 * 1000; // Add one day in milliseconds
      }
      setDateFrom(startDateInMillis);
      setDateTo(endDateInMillis);
    } else {
      setDateFrom(null);
      setDateTo(null);
    }
  };
  const handleButtonClick = (buttonLabel: string) => {
    setActiveButton(buttonLabel);
    const today = new Date();
    const startDate = new Date(today);
    const endDate = new Date(today);

    if (buttonLabel === 'Вчера') {
      startDate.setDate(today.getDate() - 1);
      // endDate.setDate(today.getDate() + 1);
    } else if (buttonLabel === 'Сегодня') {
      endDate.setDate(today.getDate() + 1);
    } else if (buttonLabel === 'Неделя') {
      startDate.setDate(today.getDate() - 7);
      endDate.setDate(today.getDate() + 1);
    } else if (buttonLabel === 'Месяц') {
      startDate.setMonth(today.getMonth() - 1);
      endDate.setDate(today.getDate() + 1);
    } else if (buttonLabel === 'Год') {
      startDate.setFullYear(today.getFullYear() - 1);
      endDate.setDate(today.getDate() + 1);
    }

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    setDateFrom(startDate.getTime());
    setDateTo(endDate.getTime());
  };

  //GET Currency Entity to options
  useQuery(['GET_CURRENCY', i18n.language], async () => {
    await legalEntityCurrencyService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.legal_entity_currencies?.map((option: any) => ({
          value: option?.currency?.id,
          label: option?.currency?.name?.[i18n?.language]
        }));
        setCurrencyOption(options);
      });
  });

  const getFlatpickrLocale = () => {
    switch (i18n.language) {
      case 'ru':
        return Russian;
      case 'uz':
        return UzbekLatin;
      default:
        return undefined;
    }
  };

  if (!isAuth) {
    return <Navigate to="/sign-in" />;
  }

  return (
    <div>
      <PageBreadcrumb items={dashboardBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{t('home_page')}</h2>
        <div className="d-flex justify-content-between gap-3 border-bottom pb-4">
          <div className={styles.list}>
            <button
              className={activeButton === 'Вчера' ? styles.active : ''}
              onClick={() => handleButtonClick('Вчера')}
            >
              {t('yesterday')}
            </button>
            <button
              className={activeButton === 'Сегодня' ? styles.active : ''}
              onClick={() => handleButtonClick('Сегодня')}
            >
              {t('today')}
            </button>
            <button
              className={activeButton === 'Неделя' ? styles.active : ''}
              onClick={() => handleButtonClick('Неделя')}
            >
              {t('week')}
            </button>
            <button
              className={activeButton === 'Месяц' ? styles.active : ''}
              onClick={() => handleButtonClick('Месяц')}
            >
              {t('month')}
            </button>
            <button
              className={activeButton === 'Год' ? styles.active : ''}
              onClick={() => handleButtonClick('Год')}
            >
              {t('year')}
            </button>
          </div>

          <Form.Floating className="w-100 position-relative">
            <Form.Select
              ref={selectRef}
              value={selectedCurrencyId}
              onChange={(e: any) => {
                const selectedOption = currencyOption?.find(
                  (option: any) => option.value === e?.target?.value
                );

                if (selectedOption) {
                  setSelectedCurrencyId(selectedOption?.value);
                }
              }}
            >
              {currencyOption?.map((option: any) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>

            <label htmlFor="floatingInputCustom">{t('currency')}</label>
          </Form.Floating>

          <div className="flatpickr-input-container w-100">
            <Form.Floating>
              <Flatpickr
                className={classNames('form-control')}
                options={{
                  mode: 'range',
                  nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                  prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                  locale: getFlatpickrLocale(),
                  monthSelectorType: 'static',
                  onDayCreate: (...[, , , dayElem]) => {
                    if (
                      dayElem.dateObj.getDay() === 5 ||
                      dayElem.dateObj.getDay() === 6
                    ) {
                      dayElem.className += ' weekend-days';
                    }
                  },
                  dateFormat: 'd-m-Y',
                  enableTime: false,
                  disableMobile: true
                }}
                onChange={handleDateChange}
                placeholder={t('select_period')}
              />
              <label htmlFor="startDatepicker" className="ps-6">
                {t('select_period')}
              </label>
            </Form.Floating>
            <UilCalendarAlt className="flatpickr-icon text-700" size={16} />
          </div>
        </div>

        <ProfitAndCostInfo
          selectedCurrencyId={selectedCurrencyId}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
        <SaleGraph
          selectedCurrencyId={selectedCurrencyId}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
        <PaymentAndTransaction
          selectedCurrencyId={selectedCurrencyId}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
        <SalesAndGoodsInfo
          selectedCurrencyId={selectedCurrencyId}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      </div>
    </div>
  );
};

export default HomePage;
