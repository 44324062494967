/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import FeatherIcon from 'feather-icons-react';
import { useQuery } from 'react-query';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { UzbekLatin } from 'flatpickr/dist/l10n/uz_latn.js';

import Button from 'components/base/Button';
import legalEntityService from 'service/legalEntity';
import warehouseService from 'service/warehouse';
import legalEntityCurrencyService from 'service/legalEntityCurrency';
import paymentOrderService from 'service/paymentOrder';
import invoiceTypeService from 'service/invoiceType';
import '../styles.scss';

interface FilterProps {
  setDateFrom?: any;
  setDateTo?: any;
  dateFrom?: any;
  dateTo?: any;
  setOptionSenderId?: any;
  setWarehouseId?: any;
  setCurrencyId?: any;
  setTypeId?: any;
  setPaymentId?: any;
}

const Filter = ({
  setDateFrom,
  setDateTo,
  dateFrom,
  dateTo,
  setOptionSenderId,
  setWarehouseId,
  setCurrencyId,
  setTypeId,
  setPaymentId
}: FilterProps) => {
  const { t, i18n } = useTranslation();
  const { control, setValue, watch } = useForm();

  const [legalEntityOption, setLegalEntityOption] = useState<any>([]);
  const [warehouseOption, setWarehouseOption] = useState<any>([]);
  const [currencyOption, setCurrencyOption] = useState<any>([]);
  const [paymentOrderOption, setPaymentOrderOption] = useState<any>([]);
  const [invoiceTypeOption, setInvoiceTypeOption] = useState<any>([]);

  // Initialize today's date in milliseconds
  const todayStart = new Date().setHours(0, 0, 0, 0);
  const todayEnd = new Date().setHours(0, 0, 0, 0);

  useEffect(() => {
    if (!dateFrom) {
      setDateFrom(todayStart);
    }
    if (!dateTo) {
      setDateTo(todayEnd);
    }
  }, [dateFrom, dateTo, setDateFrom, setDateTo]);

  //GET LEgal Entity to options
  useQuery(['GET_LEGAL_ENTITY'], async () => {
    await legalEntityService
      .getLegalEntityOption({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entities?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));
        setLegalEntityOption(options);
      });
  });

  //GET Warehouse Entity to options
  useQuery(['GET_WAREHOUSE'], async () => {
    await warehouseService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.ware_houses?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));
        setWarehouseOption(options);
      });
  });

  //GET Currency Entity to options
  useQuery(['GET_CURRENCY'], async () => {
    await legalEntityCurrencyService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.legal_entity_currencies?.map((option: any) => ({
          value: option?.currency?.id,
          label: option?.currency?.name?.[i18n?.language]
        }));
        setCurrencyOption(options);
      });
  });

  //GET Payment Order Entity to options
  useQuery(['GET_PAYMENT_ORDER'], async () => {
    await paymentOrderService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.payment_orders?.map((option: any) => ({
          value: option?.id,
          label: option?.name?.[i18n?.language]
        }));
        setPaymentOrderOption(options);
      });
  });

  //GET Invoice Type to options
  useQuery(['GET_INVOICE_TYPE'], async () => {
    await invoiceTypeService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.invoice_types?.map((option: any) => ({
          value: option?.id,
          label: option?.name?.[i18n?.language]
        }));
        setInvoiceTypeOption(options);
      });
  });

  const handleStartDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const startDate = new Date(date[0]);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    } else {
      const startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    }
  };

  const handleEndDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const endDateInMillis = date[0]?.getTime();
      setDateTo(endDateInMillis);
    } else {
      const endDateInMillis = date.getTime();
      setDateTo(endDateInMillis);
    }
  };

  const getFlatpickrLocale = () => {
    switch (i18n.language) {
      case 'ru':
        return Russian;
      case 'uz':
        return UzbekLatin;
      default:
        return undefined;
    }
  };

  return (
    <>
      <Form>
        <div className="new-rows">
          <Form.Group>
            <div className="react-select-container">
              <Controller
                name="provider"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = legalEntityOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setOptionSenderId(selectedOption?.value);
                        }
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {legalEntityOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('users')}</label>
                    {watch('provider') !== '' && (
                      <Button
                        variant="link"
                        className="close-btn p-0"
                        onClick={() => {
                          setOptionSenderId('');
                          setValue('provider', '');
                        }}
                      >
                        <FeatherIcon icon="delete" size={16} />
                      </Button>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Form.Group>

          <Form.Group>
            <div className="react-select-container">
              <Controller
                name="stock"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = warehouseOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setWarehouseId(selectedOption?.value);
                        }
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {warehouseOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('warehouse')}
                    </label>
                    {watch('stock') !== '' && (
                      <Button
                        variant="link"
                        className="close-btn p-0"
                        onClick={() => {
                          setWarehouseId('');
                          setValue('stock', '');
                        }}
                      >
                        <FeatherIcon icon="delete" size={16} />
                      </Button>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Form.Group>
          <Form.Group>
            <div className="react-select-container">
              <Controller
                name="currency"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = currencyOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setCurrencyId(selectedOption?.value);
                        }
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {currencyOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('currency')}</label>
                    {watch('currency') !== '' && (
                      <Button
                        variant="link"
                        className="close-btn p-0"
                        onClick={() => {
                          setCurrencyId('');
                          setValue('currency', '');
                        }}
                      >
                        <FeatherIcon icon="delete" size={16} />
                      </Button>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Form.Group>

          <Form.Group>
            <div className="react-select-container">
              <Controller
                name="type"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = invoiceTypeOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setTypeId(selectedOption?.value);
                        }
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {invoiceTypeOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('type')}</label>
                    {watch('type') !== '' && (
                      <Button
                        variant="link"
                        className="close-btn p-0"
                        onClick={() => {
                          setTypeId('');
                          setValue('type', '');
                        }}
                      >
                        <FeatherIcon icon="delete" size={16} />
                      </Button>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Form.Group>
          <Form.Group>
            <div className="react-select-container">
              <Controller
                name="payment_order"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = paymentOrderOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setPaymentId(selectedOption?.value);
                        }
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {paymentOrderOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('payment_procedure')}
                    </label>
                    {watch('payment_order') !== '' && (
                      <Button
                        variant="link"
                        className="close-btn p-0"
                        onClick={() => {
                          setPaymentId('');
                          setValue('payment_order', '');
                        }}
                      >
                        <FeatherIcon icon="delete" size={16} />
                      </Button>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Form.Group>

          <Form.Group className="md-4">
            <Form.Floating>
              <Flatpickr
                className={classNames('form-control')}
                options={{
                  nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                  prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                  locale: getFlatpickrLocale(),
                  monthSelectorType: 'static',
                  onDayCreate: (...[, , , dayElem]) => {
                    if (
                      dayElem.dateObj.getDay() === 5 ||
                      dayElem.dateObj.getDay() === 6
                    ) {
                      dayElem.className += ' weekend-days';
                    }
                  },
                  dateFormat: 'M j, Y',
                  disableMobile: true
                }}
                value={dateFrom}
                onChange={handleStartDateChange}
                placeholder={t('date_from')}
              />
              <label htmlFor="floatingInputCustom">{t('date_from')}</label>
            </Form.Floating>
          </Form.Group>

          <Form.Group className="md-4">
            <Form.Floating>
              <Flatpickr
                className={classNames('form-control')}
                options={{
                  nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                  prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                  locale: getFlatpickrLocale(),
                  monthSelectorType: 'static',
                  onDayCreate: (...[, , , dayElem]) => {
                    if (
                      dayElem.dateObj.getDay() === 5 ||
                      dayElem.dateObj.getDay() === 6
                    ) {
                      dayElem.className += ' weekend-days';
                    }
                  },
                  dateFormat: 'M j, Y',
                  disableMobile: true
                }}
                value={dateTo}
                onChange={handleEndDateChange}
                placeholder={t('date_to')}
              />
              <label htmlFor="floatingInputCustom">{t('date_to')}</label>
            </Form.Floating>
          </Form.Group>

          <Form.Group className="purin-btn-group">
            <Button style={{ height: '48px' }} variant="outline-primary">
              <FeatherIcon icon="upload-cloud" className="me-2" size={20} />
              {t('export')}
            </Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
};

export default Filter;
