/* eslint-disable @typescript-eslint/no-explicit-any */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Offcanvas } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AddBonusProduct from './add/AddBonusProduct';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Button from 'components/base/Button';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import bonusItemService from 'service/bonusItem';

export type BonusResultData = {
  product_name: string;
  variation: string;
  barcode: string;
  area: string;
  bonus_activation: string;
  bonus_payment: string;
  quantity: string;
  quantity_good: string;
  sum: string;
  currency: string;
  used: string;
  remainder: string;
  status: string;
  guid: string;
};

export const bonusResultBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'bonuses',
    url: '/bonuses'
  },
  {
    label: 'bonus_products',
    active: true
  }
];

const BonusResultTable = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const bonusId = pathname.split('/').pop();

  const [openResult, setOpenResult] = useState(false);
  const [selectedResultId, setSelectedResultId] = useState('');

  const { data, refetch } = useQuery(
    ['GET_BONUS_ITEM', bonusId],
    () => {
      if (bonusId !== '') {
        const res = bonusItemService
          .getList({
            offset: 0,
            limit: 10,
            bonus_id: bonusId
          })
          .then((res: any) => {
            return res?.items;
          });
        return res;
      }
    },
    { enabled: !!bonusId }
  );

  const bonusresultData: BonusResultData[] = useMemo(() => {
    const resultData =
      data?.map((items: any) => {
        return {
          guid: items?.id,
          product_name: items?.product?.name,
          barcode: items?.barcode,
          area: items?.bonus_application?.name?.[i18n?.language],
          bonus_activation: items?.bonus_activation?.name?.[i18n?.language],
          bonus_payment: items?.bonus_payment?.name?.[i18n?.language],
          currency: items?.currency?.name?.[i18n?.language],
          quantity: items?.quantity ?? 0,
          quantity_good: items?.buy_quantity ?? 0,
          sum: items?.amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.amount)
                .replace(/,/g, ' ')
            : 0,
          used: items?.quantity_used ?? 0,
          remainder:
            items?.quantity && items?.quantity_used
              ? items?.quantity - items?.quantity_used
              : 0,
          status: items?.status === true ? t('active') : t('blocked')
        };
      }) ?? [];

    return resultData;
  }, [data, bonusId, t, i18n?.language]);

  const bonusDataTableColumns: ColumnDef<BonusResultData>[] = [
    {
      id: 'action',
      cell: rowData => {
        return (
          <div>
            <Button
              variant="hover"
              onClick={() => {
                setOpenResult(true);
                setSelectedResultId(rowData?.row?.original?.guid);
              }}
            >
              <FeatherIcon icon="edit-2" className="cursor-pointer" size={20} />
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'product_name',
      header: t('bonus_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'area',
      header: t('scope_application'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'bonus_activation',
      header: t('bonus_activation'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'bonus_payment',
      header: t('bonus_payment'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'quantity',
      header: t('quantity'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'quantity_good',
      header: t('quantity_goods_pruchased'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'sum',
      header: t('amounts'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'used',
      header: t('used'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'remainder',
      header: t('remainder'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: bonusresultData,
    columns: bonusDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });
  return (
    <div>
      <PageBreadcrumb items={bonusResultBreadcrumbItems} />

      <div></div>

      <div className="d-flex justify-content-between">
        <h2>{t('bonus_products')}</h2>

        <Button
          style={{ height: '48px' }}
          variant="primary"
          onClick={() => setOpenResult(true)}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          {t('new')}
        </Button>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>

        <Offcanvas
          show={openResult}
          onHide={() => {
            setOpenResult(false);
            setSelectedResultId('');
          }}
          placement="end"
          backdrop="static"
          keyboard={false}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
              {t('add')}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <AddBonusProduct
              setOpenAddBonus={setOpenResult}
              selectedResultId={selectedResultId}
              setSelectedResultId={setSelectedResultId}
              refetch={refetch}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </AdvanceTableProvider>
    </div>
  );
};

export default BonusResultTable;
