/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const customerEntityService = {
  getList: (params: any) => request.get('/dc/charterer-entity', { params }),
  getById: (id: string) => request.get(`/legal-entity/${id}`),
  getListCheck: (params: any) => request.get('/dc/charterer/check', { params }),
  getListHistorySettlement: (params: any) =>
    request.get('/dc/settlement', { params }),

  createRoleBackSettlement: (data: any) =>
    request.post('/dc/settlement/rollback', data),

  createLegalEntityRelation: (data: any) =>
    request.post('/legal-entity/relation', data),

  createCustomerSettelment: (data: any) =>
    request.post('/dc/customer/settlement', data),
  createCharterer: (data: any) => request.post('/dc/charterer', data),

  getListCharterer: (params: any) => request.get('/dc/charterer', { params }),
  getCustomerSettlementById: (id: string, params: any) =>
    request.get(`/dc/settlement/${id}`, { params }),
  updateCustomerSettlement: (id: any, data: any) =>
    request.put(`/dc/settlement/${id}`, data)
};

export default customerEntityService;
