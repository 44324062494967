/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import IncomeFilter from './IncomeFilter';
import IncomeTable from './IncomeTable';

import styles from '../remainder/styles.module.scss';

export const incomeBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'reports',
    url: '/reports/remainder'
  },

  {
    label: 'gross_income',
    active: true
  }
];

const GrossIncome = () => {
  const { t, i18n } = useTranslation();
  const currencyName = useSelector(
    (state: any) => state?.auth?.legal_entity?.currency?.name?.[i18n?.language]
  );

  const [generateTableData, setGeneratedTableData] = useState([]);
  const [savedValues, setSavedValues] = useState([]);
  const [getName, setGetName] = useState('');
  const [getBarcode, setGetBarcode] = useState('');
  const [getFilterData, setGetFilterData] = useState({});
  const [selectedCurrencyName, setCurrencyName] = useState('');

  const sumOfAllCost = generateTableData.reduce((acc: any, item: any) => {
    const allCost =
      item?.gross_income === undefined
        ? 0
        : +String(item.gross_income || '')
            ?.split(' ')
            ?.join('');
    return acc + allCost;
  }, 0);

  const formatPrice = (price: any) => {
    const priceNumber = Number(price);
    if (isNaN(priceNumber)) {
      return { main: '0', decimals: '00' };
    }
    const priceParts = Number.isInteger(priceNumber)
      ? [priceNumber?.toString(), '00']
      : priceNumber?.toFixed(4).split('.');
    return {
      main: priceParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
      decimals: priceParts[1]
    };
  };

  const { main, decimals } = formatPrice(sumOfAllCost);

  return (
    <div>
      <PageBreadcrumb items={incomeBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{t('gross_income')}</h2>
        <IncomeFilter
          setGeneratedTableData={setGeneratedTableData}
          savedValues={savedValues}
          setSavedValues={setSavedValues}
          setGetName={setGetName}
          setGetBarcode={setGetBarcode}
          setGetFilterData={setGetFilterData}
          getName={getName}
          getBarcode={getBarcode}
          setCurrencyName={setCurrencyName}
        />

        <IncomeTable
          generateTableData={generateTableData}
          setGeneratedTableData={setGeneratedTableData}
          getName={getName}
          getBarcode={getBarcode}
          getFilterData={getFilterData}
        />
        <div className={styles.footerRemain}>
          <div className={styles.box}>
            <p className="mb-0" style={{ fontWeight: '800' }}>
              {t('total_gross_income_amount')}: (
              {selectedCurrencyName !== ''
                ? selectedCurrencyName
                : currencyName}
              )
            </p>
            <span style={{ fontWeight: '800' }}>
              {main}.{decimals}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrossIncome;
