/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateBack } from '@fortawesome/free-solid-svg-icons';

import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import customerEntityService from 'service/charterer';
import legalEntitySettingsService from 'service/legalEntitySettings';
import Button from 'components/base/Button';
import ReturnSettlement from './ReturnSettlement';

export type SettelementData = {
  product_provider: string;
  currency: string;
  debt: string;
  credit: string;
  remainder: string;
  status: string;
  can_update: boolean;
  guid: string;
  bg_color: string;
  text_color: string;
};

interface PurchaseWithCustomerHistoryProps {
  selectedCustomerId?: any;
}

const PurchaseWithCustomerHistory = ({
  selectedCustomerId
}: PurchaseWithCustomerHistoryProps) => {
  const { t, i18n } = useTranslation();
  const [openReturn, setOpenReturn] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any>({});

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  const { data, refetch } = useQuery(
    ['GET_HISTORY_ID'],
    () => {
      if (selectedCustomerId) {
        const res = customerEntityService
          .getListHistorySettlement({
            offset: 0,
            limit: 100,
            debit_credit_id: selectedCustomerId
          })
          .then((res: any) => res?.debit_credit_settlements);
        return res;
      }
    },
    { enabled: !!selectedCustomerId }
  );

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const historyData: SettelementData[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    return (
      data?.map((items: any) => {
        return {
          product_provider: items?.creditor?.name,
          debt: items?.amount_debit ?? 0,
          credit: items?.amount_credit ?? 0,
          remainder:
            (parseFloat(items?.amount_debit) || 0) -
            (parseFloat(items?.amount_credit) || 0),
          status: items?.status?.name?.[i18n?.language],
          creditor_id: items?.creditor_id,
          debitor_id: items?.debitor_id,
          can_update: items?.can_update === true ? false : true,
          currency: items?.currency?.name?.[i18n?.language],
          bg_color: items?.status?.background_color,
          text_color: items?.status?.text_color,
          date_create:
            items?.date_create === 0 || !items?.date_create
              ? ''
              : formatDateToTimeZone(items?.date_create, timeZone),
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, dataSettings, t, i18n?.language]);

  const settelementDataTableColumns: ColumnDef<SettelementData>[] = [
    // {
    //   id: 'action',
    //   cell: rowData => (
    //     <div>
    //       {rowData?.row?.original?.can_update ? (
    //         <Button
    //           variant="primary"
    //           onClick={() => handleAccept(rowData?.row?.original?.guid)}
    //         >
    //           Подтвердить
    //         </Button>
    //       ) : (
    //         ''
    //       )}
    //     </div>
    //   ),
    //   meta: {
    //     headerProps: { style: { width: '7%' } },
    //     cellProps: { className: 'text-center' }
    //   }
    // },
    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'debt',
      header: t('debit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'credit',
      header: t('credit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'remainder',
      header: t('remainder'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'date_create',
      header: t('maturity_date'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        return (
          /* prettier-ignore */
          <div
            style={{
              background: `${rowData?.row?.original?.bg_color}`,
              padding: '8px 10px',
              textAlign: 'center',
              borderRadius: '8px',
              color: `${rowData?.row?.original?.text_color}`,
              fontWeight: "600"
            }}
          >
            {rowData?.row?.original?.status}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      id: 'action',
      cell: rowData => (
        <div>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip style={{ position: 'fixed' }}>{t('return')}</Tooltip>
            }
          >
            <div>
              <Button
                variant="hover"
                className="p-2"
                disabled={rowData?.row?.original?.can_update}
                onClick={() => {
                  setOpenReturn(true);
                  setSelectedItems(rowData?.row?.original);
                }}
              >
                <FontAwesomeIcon icon={faArrowRotateBack} className="fs-7" />
              </Button>
            </div>
          </OverlayTrigger>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-center' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: historyData,
    columns: settelementDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div>
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>

      <Modal
        show={openReturn}
        onHide={() => {
          setOpenReturn(false);
          setSelectedItems({});
        }}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
        contentClassName="border border-300"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('return')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReturnSettlement
            selectedItems={selectedItems}
            setOpenReturn={setOpenReturn}
            refetch={refetch}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PurchaseWithCustomerHistory;
