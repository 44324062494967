/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClockRotateLeft,
  faPencil,
  faSearch,
  faBarcode,
  faTag
} from '@fortawesome/free-solid-svg-icons';
import { Offcanvas, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Button from 'components/base/Button';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import { pageToOffset } from 'utils/pageToOffset';
import useAdvanceTable from 'hooks/useAdvanceTable';
import productSearchService from 'service/productSearch';
import UpdateMyProduct from './UpdateMyProduct';
import MyProductHistory from './MyProductHistory';
import MyProductModalPriceList from './modal-price-list';

export type ProductsType = {
  name: string;
  barcode: string;
  measure_unit: string;
  category: string;
  partion_number: string;
  price: string;
  price_whosale: string;
  product_kind: string;
  article: string;
  package_quantity: string;
  measure_unit_id: string;
  scale_code: string;
  guid: string;
};

export const myProductsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'my_products',
    active: true
  }
];

const MyProducts = () => {
  const { t, i18n } = useTranslation();

  const [searchInputValue, setSearchInputValue] = useState<any>('');
  const [getBarcode, setGetBarcode] = useState<any>('');
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [openProductUpdate, setOpenProductUpdate] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState('');
  const [printModal, setPrintModal] = useState(false);
  const [customBarcodes, setCustomBarcodes] = useState(false);
  const [changedProducts, setChangedProducts] = useState(false);
  const [priceListDataTable, setPriceListDataTable] = useState<any>([]);

  //Get Product Search
  const { data, refetch, isFetching, isLoading } = useQuery(
    [
      'GET_PRODUCT',
      currentPage,
      searchInputValue,
      getBarcode,
      customBarcodes,
      changedProducts
    ],
    async () => {
      const res = await productSearchService
        .getListProductLast({
          limit: 10,
          offset: pageToOffset(currentPage, 10),
          name: searchInputValue,
          barcode: getBarcode,
          is_custom: customBarcodes,
          is_changed: changedProducts
        })
        .then((res: any) => {
          return res;
        });
      return res;
    },
    {
      enabled:
        !!currentPage ||
        !searchInputValue ||
        searchInputValue?.length > 2 ||
        !!getBarcode ||
        !!customBarcodes ||
        changedProducts
    }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const loading = isLoading || isFetching;

  const productsData: ProductsType[] = useMemo(() => {
    return (
      data?.products?.map((items: any) => {
        return {
          barcode: items?.barcode,
          name: items?.name,
          measure_unit:
            items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          measure_unit_id: items?.nomenclature?.measure_unit?.id,
          measure_unit_kind_id: items?.nomenclature?.measure_unit_kind_id,
          product_kind:
            items?.nomenclature?.productKind?.name?.[i18n?.language],
          product_kind_id: items?.nomenclature?.productKind?.id,
          package_quantity: items?.package_quantity,
          article: items?.nomenclature?.article,
          category: items?.category?.name?.[i18n?.language],
          partion_number:
            items?.partion_number === 'undefined' ? '0' : items?.partion_number,
          price: items?.price ?? 0,
          price_whosale: items?.price_whosale ?? 0,
          is_custom: items?.is_custom ?? false,
          scale_code:
            items?.nomenclature?.measure_unit?.id ===
            '208baabf-b8dd-4e2e-b3a0-05c2f6744052'
              ? items?.scale_code ?? 0
              : '',
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const productsDataTableColumns: ColumnDef<ProductsType>[] = [
    {
      id: 'action',
      cell: rowData => {
        const newDataObj = {
          name: rowData?.row?.original?.name,
          barcode: rowData?.row?.original?.barcode,
          price_sale: rowData?.row?.original?.price,
          price_whosale: rowData?.row?.original?.price_whosale,
          measure_unit: rowData?.row?.original?.measure_unit,
          measure_unit_id: rowData?.row?.original?.measure_unit_id,
          scale_code: rowData?.row?.original?.scale_code,
          quantity: 0
        };
        return (
          <div className="d-flex gap-2">
            <Button
              variant="hover"
              className="p-2"
              onClick={() => {
                setSelectedProduct(rowData?.row?.original);
                setOpenProductUpdate(true);
              }}
            >
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faPencil}
                transform={{ size: 20 }}
              />
            </Button>
            <Button
              variant="hover"
              className="p-2"
              onClick={() => {
                setOpenHistory(true);
                setSelectedProductId(rowData?.row?.original?.barcode);
              }}
            >
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faClockRotateLeft}
                transform={{ size: 20 }}
              />
            </Button>
            <Button
              variant="hover"
              className="p-2"
              onClick={() => {
                setPriceListDataTable(() => [newDataObj]);
                setPrintModal(true);
              }}
            >
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faTag}
                transform={{ size: 20 }}
              />
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'order_number',
      header: '№',
      cell: rowData => {
        const orderNumber = rowData.row.index + 1 + (currentPage - 1) * 10;
        return <div>{orderNumber}</div>;
      },
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },
    {
      accessorKey: 'name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },
    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'measure_unit',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'product_kind',
      header: t('product_type'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'article',
      header: t('article'),
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },
    {
      accessorKey: 'category',
      header: t('category'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'partion_number',
      header: t('serial_number'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'price_whosale',
      header: t('wholesale_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'price',
      header: t('selling_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: productsData,
    columns: productsDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <PageBreadcrumb items={myProductsBreadcrumbItems} />

      <h2 className="mb-5">{t('my_products')}</h2>

      <div className="d-flex gap-2">
        <div className="form-icon-container">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder={t('enter_product')}
              onFocus={e => e.target.select()}
              onChange={e => setSearchInputValue(e.target.value)}
              className="form-icon-input"
              autoComplete="off"
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              {t('enter_product')}
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faSearch}
            className="text-900 fs-9 form-icon"
          />
        </div>
        <div className="form-icon-container">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder={t('enter_barcode')}
              onFocus={e => e.target.select()}
              onChange={e => setGetBarcode(e.target.value)}
              className="form-icon-input"
              autoComplete="off"
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              {t('enter_barcode')}
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faBarcode}
            className="text-900 fs-9 form-icon"
          />
        </div>
        <Form.Group className="d-flex align-items-center">
          <Form.Check
            label={t('custom_barcodes')}
            checked={customBarcodes}
            onChange={e => setCustomBarcodes(e?.target?.checked)}
          />
        </Form.Group>
        <Form.Group className="d-flex align-items-center">
          <Form.Check
            label={t('changed_products')}
            checked={changedProducts}
            onChange={e => setChangedProducts(e?.target?.checked)}
          />
        </Form.Group>
        <div className="ms-auto">
          <Button
            variant="primary"
            style={{ height: '48px' }}
            onClick={() => {
              setPrintModal(true);
              setPriceListDataTable([]);
            }}
          >
            {t('printing_price_tags')}
          </Button>
        </div>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
          <AdvanceTable
            tableProps={{ className: 'phoenix-table fs-9' }}
            isLoading={loading}
          />
          <AdvanceTableCPagination
            count={pageCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </AdvanceTableProvider>

      <Offcanvas
        show={openProductUpdate}
        onHide={() => {
          setOpenProductUpdate(false);
        }}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            {t('change_product')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <UpdateMyProduct
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            refetch={refetch}
            setOpenProductUpdate={setOpenProductUpdate}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={openHistory}
        onHide={() => {
          setOpenHistory(false);
          setSelectedProductId('');
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('history_changes')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MyProductHistory selectedProductId={selectedProductId} />
        </Modal.Body>
      </Modal>

      <Modal
        show={printModal}
        onHide={() => {
          setPrintModal(false);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('printing_price_tags')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MyProductModalPriceList
            priceListDataTable={priceListDataTable}
            setPriceListDataTable={setPriceListDataTable}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MyProducts;
