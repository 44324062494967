import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const outgoingBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'applications',
    url: '/applications/outgoing'
  },
  {
    label: 'outgoing',
    active: true
  }
];

export type OutgoingType = {
  orders: string;
  sender: string;
  recipient: string;
  currency: string;
  status: string;
  create_date: string;
  guid: string;
  can_update: boolean;
};

export type OutgoingResult = {
  product_name: string;
  barcode: string;
  currency: string;
  units: string;
  cost: string;
  amount: string;
  history: string;
  status: string;
  guid: string;
};
