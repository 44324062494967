/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const marriageGoodsService = {
  getList: (params: any) => request.get(`/defective-product`, { params }),
  create: (data: any) => request.post('/defective-product', data),
  createWriteOff: (data: any) =>
    request.post('/defective-product/write-off', data),
  createReturn: (data: any) => request.post('/defective-product/return', data),
  delete: (id: any) => request.delete(`/defective-product/${id}`),
  update: (id: any, data: any) => request.put(`/defective-product/${id}`, data)
};

export default marriageGoodsService;
