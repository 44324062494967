/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const countryService = {
  getList: (params: any) => request.get('/guidebook/country', { params }),
  getListForRegister: (params: any) => request.get('/country', { params }),
  getCountryById: (id: any) => request.get(`/guidebook/country/${id}`),
  createCountry: (data: any) => request.post('/guidebook/country', data),
  deleteCountry: (id: any) => request.delete(`/guidebook/country/${id}`),
  updateCountry: (id: any, data: any) =>
    request.put(`/guidebook/country/${id}`, data)
};

export default countryService;
