/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Col, Row, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

import { showAlert } from 'store/alert/alert.thunk';
import walletService from 'service/wallet';
import Button from 'components/base/Button';

const formatNumber = (value: string) => {
  return value?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const removeSpaces = (value: string) => {
  return value?.replace(/\s/g, '');
};

interface CreateAddAmountProps {
  refetch?: any;
  setModalShow: (show: boolean) => void;
  addId?: any;
}

const CreateAddAmount = ({
  refetch,
  setModalShow,
  addId
}: CreateAddAmountProps) => {
  const { t } = useTranslation();

  const {
    register,
    getValues,
    control,
    formState: { errors }
  } = useForm();
  const dispatch: Dispatch<any> = useDispatch();

  const [displayValue, setDisplayValue] = useState('');
  const [openSupperModal, setOpenSupperModal] = useState(false);

  const handleInputChange = (e: any, field: any) => {
    const rawValue = removeSpaces(e.target.value);
    const formattedValue = formatNumber(rawValue);
    setDisplayValue(formattedValue);
    field.onChange(rawValue); // Ensure validation is triggered with raw value
  };

  const handleSave = () => {
    const data = getValues();
    const amountWithoutSpaces = removeSpaces(data.amount);

    if (data.amount === undefined) {
      dispatch(
        showAlert({
          title: t('amount_not_empty')
        })
      );
    }
    const createData = {
      note: data?.note,
      balance: +amountWithoutSpaces,
      wallet_id: addId
    };

    if (data.amount !== undefined) {
      walletService.createWalletSettlement(createData).then((res: any) => {
        if (res) {
          setModalShow(false);
          dispatch(
            showAlert({
              title: t('successfully_added'),
              type: 'success'
            })
          );
          refetch();
          setOpenSupperModal(false);
        }
      });
    }
  };

  return (
    <>
      <Form
        noValidate
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row>
          <Col>
            <Form.Floating className="md-4 mb-2">
              <Controller
                name="amount"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder={t('enter_amount')}
                    onFocus={e => e.target.select()}
                    value={displayValue}
                    onChange={e => handleInputChange(e, field)}
                    onKeyPress={e => {
                      if (e.key === '-' || e.key < '0' || e.key > '9') {
                        e.preventDefault();
                      }
                    }}
                    isInvalid={!!errors.amount}
                  />
                )}
              />
              <label htmlFor="floatingInputCustom">{t('enter_amount')}</label>
              {errors.amount && (
                <Form.Control.Feedback type="invalid">
                  {t('required_field')}
                </Form.Control.Feedback>
              )}
            </Form.Floating>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Floating className="md-4">
              <Form.Control
                {...register('note')}
                type="text"
                placeholder={t('note')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('note')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <div style={{ marginTop: 'auto', display: 'flex', gap: '10px' }}>
          <Button
            style={{ height: '48px', width: '100%' }}
            variant="outline-secondary"
            onClick={() => setModalShow(false)}
          >
            {t('close')}
          </Button>
          <Button
            style={{ height: '48px', width: '100%' }}
            variant="primary"
            type="button"
            onClick={() => setOpenSupperModal(true)}
          >
            {t('add')}
          </Button>
        </div>

        <Modal
          show={openSupperModal}
          onHide={() => setOpenSupperModal(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>{t('add_amount')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t('you_want_add')}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setOpenSupperModal(false)}
            >
              {t('no')}
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={() => handleSave()}
            >
              {t('yes')}
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
};

export default CreateAddAmount;
