/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Modal } from 'react-bootstrap';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

import { isValidGtin } from 'utils/isValidGtin';
import { showAlert } from 'store/alert/alert.thunk';
import OpenSearchMovingTable from './OpenSearchMovingTable';
import productSearchService from 'service/productSearch';
import Button from 'components/base/Button';
import '../../styles.scss';

interface NewMovementsTableSearchProps {
  setMovingItemData?: any;
  movingItemData?: any;
  focusRef?: any;
  isAutoTab?: any;
}

const NewMovementsTableSearch = ({
  setMovingItemData,
  movingItemData,
  focusRef,
  isAutoTab
}: NewMovementsTableSearchProps) => {
  const { t, i18n } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();

  const [openSearch, setOpenSearch] = useState(false);
  const [searchedValue, setsearchedValue] = useState('');

  const handleGetProduct = useCallback(
    async (e: any) => {
      if (e?.key !== 'Enter') return;

      if (isValidGtin(searchedValue)) {
        try {
          const res = await productSearchService
            .getList({
              offset: 0,
              limit: 20,
              barcode: searchedValue ? searchedValue : undefined
            })
            .then((res: any) => res);

          const resCurrency = res?.currency?.name?.[i18n?.language];
          const resData = res?.products ?? [];

          const createdData = resData?.map((items: any) => ({
            product_name: items?.nomenclature?.name,
            variation:
              items?.nomenclature?.is_variation === true ? t('yes') : t('no'),
            barcode: items?.nomenclature?.barcode,
            units: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
            // this dataes need for static rendering dataes
            unit: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
            measure_unit_id: items?.nomenclature?.measure_unit?.id,
            amount: items?.quantity ?? 0,
            current_balance: items?.quantity ?? 0,
            total_price: items?.price_wholesale ?? 0,
            full_cost: items?.price_whosale,
            nomenclature_id: items?.nomenclature_id,
            product_id: items?.id,
            product_parent_id: items?.id,
            price: items?.price ?? 0,
            price_in: items?.price_in ?? 0,
            price_whosale: items?.price_whosale ?? 0,
            guid: items?.id,
            is_vat: items?.is_vat ? items?.is_vat : false,
            currency: resCurrency
          }));

          if (createdData.length === 0) {
            dispatch(
              showAlert({
                title: t('barcode_not_found')
              })
            );
            return;
          }

          if (movingItemData?.length > 0) {
            const foundProductIndex = movingItemData?.findIndex((item: any) => {
              return item?.barcode === searchedValue;
            });
            if (foundProductIndex !== -1) {
              const updatedProductInvoiceData = [...movingItemData];
              updatedProductInvoiceData[foundProductIndex].amount += 1;
              setMovingItemData(updatedProductInvoiceData);
            } else {
              setMovingItemData((prevData: any) => {
                const newData = [...createdData, ...prevData];

                if (isAutoTab) {
                  // when auto tab true focus to quantity
                  setTimeout(() => {
                    const firstProductIndex = 0;
                    focusRef(`amount_${firstProductIndex}`);
                  }, 500);
                }
                return newData;
              });
            }
          } else {
            setMovingItemData((prevData: any) => {
              const newData = [...createdData, ...prevData];

              if (isAutoTab) {
                // when auto tab true focus to quantity
                setTimeout(() => {
                  const firstProductIndex = 0;
                  focusRef(`amount_${firstProductIndex}`);
                }, 500);
              }
              return newData;
            });
          }
        } catch (error) {
          dispatch(
            showAlert({
              title: t('barcode_not_found')
            })
          );
        }
      }
    },

    [searchedValue, movingItemData, dispatch, t, i18n?.language]
  );

  return (
    <div className="mt-4">
      <div className="mov-rows">
        <Form.Floating>
          <Form.Control
            value={searchedValue}
            type="text"
            placeholder={t('scan_barcode')}
            onChange={e => setsearchedValue(e?.target?.value)}
            onKeyDown={e => handleGetProduct(e)}
            onFocus={e => e.target.select()}
          />
          <label htmlFor="floatingInputCustom">{t('scan_barcode')}</label>
        </Form.Floating>

        <Form.Group className="mov-btn">
          <Button
            type="button"
            variant="primary"
            onClick={() => setOpenSearch(true)}
          >
            {t('search')}
          </Button>
        </Form.Group>
      </div>

      <Modal
        show={openSearch}
        onHide={() => setOpenSearch(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('search')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OpenSearchMovingTable
            setOpenSearch={setOpenSearch}
            setMovingItemData={setMovingItemData}
            movingItemData={movingItemData}
            isAutoTab={isAutoTab}
            focusRef={focusRef}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewMovementsTableSearch;
