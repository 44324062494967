/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import InfoTable from './InfoTable';
import InfoFilter from './InfoFilter';

export const infoInventoryBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'inventory',
    url: '/inventory/create'
  },
  {
    label: 'inventory_information',
    active: true
  }
];

const InfoInventory = () => {
  const { t } = useTranslation();
  const [dateInventory, setDateInventory] = useState(null);
  const [inventoryValue, setInventoryValue] = useState('');
  const [optionWarehouseId, setOptionWarehouseId] = useState('');
  const [optionStatusId, setOptionStatusId] = useState('');
  const [barcodeValue, setBarcodeValue] = useState('');
  const [infoValues, setInfoValues] = useState<any>([]);

  return (
    <div>
      <PageBreadcrumb items={infoInventoryBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{t('inventory_information')}</h2>

        <InfoFilter
          dateInventory={dateInventory}
          setDateInventory={setDateInventory}
          setOptionWarehouseId={setOptionWarehouseId}
          setOptionStatusId={setOptionStatusId}
          setInventoryValue={setInventoryValue}
          inventoryValue={inventoryValue}
          setBarcodeValue={setBarcodeValue}
          barcodeValue={barcodeValue}
          infoValues={infoValues}
        />
        <InfoTable
          dateInventory={dateInventory}
          inventoryValue={inventoryValue}
          optionWarehouseId={optionWarehouseId}
          optionStatusId={optionStatusId}
          barcodeValue={barcodeValue}
          setInfoValues={setInfoValues}
        />
      </div>
    </div>
  );
};

export default InfoInventory;
