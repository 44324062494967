/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import warehouseService from 'service/warehouse';
import './styles.scss';

interface CreateStockProps {
  setOpenStock: (show: boolean) => void;
  selectedWarehouseId?: any;
  refetch?: any;
  setSelectedWarehouseId?: any;
}

const CreateStock = ({
  setOpenStock,
  selectedWarehouseId,
  refetch,
  setSelectedWarehouseId
}: CreateStockProps) => {
  const { t } = useTranslation();

  const options = [
    {
      value: true,
      label: t('active')
    },
    {
      value: false,
      label: t('blocked')
    }
  ];
  const schema = yup.object().shape({
    name: yup.string().required(t('required_field')),
    status: yup.string().required(t('required_field'))
  });
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });
  const hasFetchedData = useRef(false);
  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();

  const fetchData = () => {
    if (selectedWarehouseId === '') return setLoading(false);

    warehouseService
      .getWarehouseById(selectedWarehouseId)
      .then((res: any) => {
        const computed = {
          name: res?.name,
          status: res?.status
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };
  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedWarehouseId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const createdData = {
      name: data?.name,
      status: data?.status === 'true' ? true : false
    };

    warehouseService.createWarehouse(createdData).then((res: any) => {
      if (res) {
        dispatch(
          showAlert({
            title: `${t('warehouse')} ${t('successfully_added')}`,
            type: 'success'
          })
        );
        refetch();
        setOpenStock(false);
        setLoading(false);
        setSelectedWarehouseId('');
      }
    });
  };

  const update = (data: any) => {
    setLoading(true);

    const updateData = {
      name: data?.name,
      status: data?.status === 'true' || data?.status === true ? true : false,
      id: selectedWarehouseId
    };
    warehouseService
      .updateWarehouse(selectedWarehouseId, updateData)
      .finally(() => {
        refetch();
        setLoading(false);
        setOpenStock(false);
        setSelectedWarehouseId('');
      });
  };

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
      setTimeout(() => {
        inputRef?.current?.select();
      }, 500);
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (selectedWarehouseId !== '' && !hasFetchedData.current) {
      fetchData();
      hasFetchedData.current = true;
    }
  }, [selectedWarehouseId]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-2">
          <Col>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...field}
                    ref={inputRef}
                    type="text"
                    placeholder={t('warehouse_name')}
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.name}
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('warehouse_name')}
                  </label>
                  {errors.name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.name.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.status}>
                      <option className="d-none" value=""></option>
                      {options?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      )) ?? []}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('interaction_type')}
                    </label>
                    {errors?.status?.message && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {errors?.status?.message}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
          loadingPosition="start"
        >
          {t('add')}
        </Button>
      </Form>
    </>
  );
};

export default CreateStock;
