/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useRef } from 'react';
import { UilCalendarAlt } from '@iconscout/react-unicons';
import { useTranslation } from 'react-i18next';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { UzbekLatin } from 'flatpickr/dist/l10n/uz_latn.js';

import roleService from 'service/role';
import 'flatpickr/dist/flatpickr.css';
import Button from 'components/base/Button';
import './styles.scss';
import usersService from 'service/users';
import { showAlert } from 'store/alert/alert.thunk';
import PhoneNumberInput from 'components/forms/PhoneNumberInput';
import useCountryUnicode from 'hooks/useCountryUnicode';
import usersStatusService from 'service/usersStatus';
import regionService from 'service/region';
import countryService from 'service/country';
import areaService from 'service/district';
import legalEntityService from 'service/legalEntity';

interface CreateClientsProps {
  setOpenClient: (show: boolean) => void;
  selectedClientId?: any;
  refetch?: any;
  setSelectedClientId?: any;
}

const CreateClients = ({
  setOpenClient,
  selectedClientId,
  refetch,
  setSelectedClientId
}: CreateClientsProps) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors }
  } = useForm();

  const { t, i18n } = useTranslation();

  const genderOption = [
    {
      value: 'MALE',
      label: t('male')
    },
    {
      value: 'FEMALE',
      label: t('female')
    }
  ];

  const [loading, setLoading] = useState(false);
  const [roleOption, setRoleOption] = useState([]);
  const [userStatusOption, setUserStatusOption] = useState([]);
  const [areaOption, setAreaOption] = useState([]);
  const [regionOption, setRegionOption] = useState<any>([]);
  const [countryOption, setCountryOption] = useState<any>([]);
  const [legalEntityOption, setLegalEntityOption] = useState<any>([
    { label: '', value: '', legal_entity_type: '' }
  ]);

  const [selectedLegelEntityId, setSelectedLegalEntityId] = useState('');
  const [selectedCountryId, setSelectedCountryId] = useState('');
  const [selectedRegionId, setSelectedRegionId] = useState('');

  const hasFetchedData = useRef(false);
  const dispatch: Dispatch<any> = useDispatch();
  const unicode = useCountryUnicode();
  const legalEntityType = watch('legal_entity_type');
  const inputRef = useRef<HTMLInputElement | any>();

  // GET ROLE
  const { refetch: refetchRole } = useQuery(
    ['GET_ROLE', selectedLegelEntityId],
    async () => {
      if (selectedLegelEntityId !== '') {
        const res = await roleService.getList({
          offset: 0,
          limit: 100,
          system_level: legalEntityType,
          legal_entity_id: selectedLegelEntityId
        });
        const options = res?.data?.roles?.map((role: any) => ({
          value: role.id,
          label: role.name
        }));
        setRoleOption(options);
      }
    },
    { enabled: !!selectedLegelEntityId }
  );

  // GET USER STATUS
  useQuery(['GET_USER_STATUS'], async () => {
    const res = await usersStatusService.getList({ offset: 0, limit: 100 });
    const options = res?.data?.user_statuses?.map((option: any) => ({
      value: option.id,
      label: option.name
    }));
    setUserStatusOption(options);
  });

  //GET Country to options
  useQuery(['GET_COUNTRY'], async () => {
    await countryService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.countries?.map((country: any) => ({
        value: country.id,
        label: country.name?.[i18n?.language]
      }));
      setCountryOption(options);
    });
  });

  //GET Area to options
  useQuery(
    ['GET_AREA', selectedRegionId],
    async () => {
      if (selectedRegionId !== '') {
        await areaService
          .getList({ offset: 0, limit: 100, region_id: selectedRegionId })
          .then((res: any) => {
            const options = res?.districts?.map((currency: any) => ({
              value: currency.id,
              label: currency.name?.[i18n?.language]
            }));
            setAreaOption(options);
          });
      }
    },
    { enabled: !!selectedRegionId }
  );

  //GET Region to options
  useQuery(
    ['GET_REGION', selectedCountryId],
    async () => {
      if (selectedCountryId !== '') {
        await regionService
          .getList({ offset: 0, limit: 100, country_id: selectedCountryId })
          .then((res: any) => {
            const options = res?.regions?.map((region: any) => ({
              value: region.id,
              label: region.name?.[i18n?.language]
            }));
            setRegionOption(options);
          });
      }
    },
    { enabled: !!selectedCountryId }
  );

  //GET LEgal Entity to options
  useQuery(['GET_LEGAL_ENTITY'], async () => {
    await legalEntityService
      .getList({ offset: 0, limit: 20 })
      .then((res: any) => {
        const options = res?.legal_entities?.map((option: any) => ({
          value: option.id,
          label: option.name,
          legal_entity_type: option.legal_entity_type
        }));
        setLegalEntityOption(options);
      });
  });

  //Fetch Users By ID
  const fetchData = () => {
    if (selectedClientId === '') return setLoading(false);

    usersService
      .getById(selectedClientId)
      .then((res: any) => {
        const computed = {
          date_birth: res?.data?.birth_date,
          first_name: res?.data?.first_name,
          gender: res?.data?.gender,
          second_name: res?.data?.last_name,
          phone: res?.data?.phone_number,
          client_patronymic: res?.data?.surname,
          legal_entity_type: res?.data?.legal_entity?.system_level ?? '',
          legal_name: res?.data?.legal_entity?.id,
          role: res?.data?.role?.id,
          country: res?.data?.country?.id,
          region: res?.data?.region?.id,
          area: res?.data?.district?.id,
          status: res?.data?.user_staus?.id,
          is_charger_inventory: res?.data?.is_charger_inventory,
          is_charger_return: res?.data?.is_charger_return
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedClientId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const createData = {
      birth_date: Date.parse(data?.date_birth),
      first_name: data?.first_name,
      gender: data?.gender,
      is_master: false,
      last_name: data?.second_name,
      law_subject_type: 'LEGAL',
      password: data?.client_password,
      phone_number: data?.phone,
      surname: data?.client_patronymic,
      role_id: data?.role,
      country_id: data?.country,
      district_id: data?.area,
      legal_entity_id: data?.legal_name,
      region_id: data?.region,
      user_status_id: data?.status,
      legal_entity_type: data?.legal_entity_type,
      is_charger_inventory: data?.is_charger_inventory,
      is_charger_return: data?.is_charger_return
    };

    usersService
      .create(createData)
      .then((res: any) => {
        if (res) {
          setOpenClient(false);
          setLoading(false);
          dispatch(
            showAlert({
              title: `${t('users')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
          refetch();
          setSelectedClientId('');
        }
      })
      .catch((error: any) => {
        setLoading(false);

        if (error.status === 409) {
          console.log('error409', error);
        }
      });
  };

  const update = (data: any) => {
    setLoading(true);

    const updateData = {
      birth_date: Date.parse(data?.date_birth),
      first_name: data?.first_name,
      gender: data?.gender,
      is_master: false,
      last_name: data?.second_name,
      law_subject_type: 'LEGAL',
      password: data?.client_password,
      phone_number: data?.phone,
      surname: data?.client_patronymic,
      role_id: data?.role,
      legal_entity_type: data?.legal_entity_type,
      legal_entity_id: data?.legal_name,
      id: selectedClientId,
      country_id: data?.country,
      district_id: data?.area,
      region_id: data?.region,
      user_status_id: data?.status,
      is_charger_inventory: data?.is_charger_inventory,
      is_charger_return: data?.is_charger_return
    };

    usersService.update(updateData).finally(() => {
      setLoading(false);
      setOpenClient(false);
      refetch();
      setSelectedClientId('');
    });
  };

  const getFlatpickrLocale = () => {
    switch (i18n.language) {
      case 'ru':
        return Russian;
      case 'uz':
        return UzbekLatin;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef.current.select();
      }, 500);
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (selectedClientId !== '' && !hasFetchedData.current) {
      fetchData();
      hasFetchedData.current = true;
    }
  }, [selectedClientId]);

  useEffect(() => {
    if (legalEntityType !== '') {
      refetchRole();
    }
  }, [legalEntityType]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-2">
          <Col>
            <div className="form-icon-container">
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: true
                }}
                defaultValue=""
                render={({ field }) => (
                  <PhoneNumberInput
                    field={field}
                    ref={inputRef}
                    unicode={unicode}
                    isInvalid={!!errors.phone}
                  />
                )}
              />
              {errors?.phone && (
                <span
                  style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
                >
                  {t('required_field')}
                </span>
              )}
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="legal_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = legalEntityOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setValue(
                            'legal_entity_type',
                            selectedOption.legal_entity_type
                          );
                          setSelectedLegalEntityId(selectedOption?.value);
                        }
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {legalEntityOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('legal_entities')}
                    </label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="role"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {roleOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      )) ?? []}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('role')}</label>
                  </Form.Floating>
                )}
              />
              {errors?.role?.message && (
                <span
                  style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
                ></span>
              )}
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating className="md-4">
              <Form.Control
                {...register('second_name')}
                type="text"
                placeholder={t('second_name')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('second_name')}</label>
              {errors.second_name && (
                <Form.Control.Feedback type="invalid">
                  {t('required_field')}
                </Form.Control.Feedback>
              )}
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Controller
              name="first_name"
              control={control}
              rules={{
                required: true
              }}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder={t('name')}
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.first_name}
                  />
                  <label htmlFor="floatingInputCustom">{t('name')}</label>
                  {errors?.first_name && (
                    <Form.Control.Feedback type="invalid">
                      {t('required_field')}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating className="md-4">
              <Form.Control
                {...register('client_patronymic')}
                type="text"
                placeholder={t('surname')}
                onFocus={e => e.target.select()}
                autoComplete="new-patronymic"
              />
              <label htmlFor="floatingInputCustom">{t('surname')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="country"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = countryOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setSelectedCountryId(selectedOption?.value);
                        }
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {countryOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      )) ?? []}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('country')}</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="region"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = regionOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setSelectedRegionId(selectedOption?.value);
                        }
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {regionOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      )) ?? []}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('region')}</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="area"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {areaOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      )) ?? []}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('area')}</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        {!selectedClientId && (
          <Row className="mb-2">
            <Col>
              <Controller
                name="client_password"
                control={control}
                rules={{
                  required: true
                }}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating className="md-4">
                    <Form.Control
                      {...field}
                      type="password"
                      placeholder={t('password')}
                      onFocus={e => e.target.select()}
                      isInvalid={!!errors.client_password}
                      autoComplete="new-password"
                    />
                    <label htmlFor="floatingInputCustom">{t('password')}</label>
                    {errors?.client_password && (
                      <Form.Control.Feedback type="invalid">
                        {t('required_field')}
                      </Form.Control.Feedback>
                    )}
                  </Form.Floating>
                )}
              />
            </Col>
          </Row>
        )}

        {!selectedClientId && (
          <Row className="mb-2">
            <Col>
              <Form.Floating className="md-4">
                <Form.Control
                  {...register('reset_password')}
                  type="password"
                  placeholder={t('repeat_password')}
                  onFocus={e => e.target.select()}
                  autoComplete="new-resetpassword"
                />
                <label htmlFor="floatingInputCustom">
                  {t('repeat_password')}
                </label>
              </Form.Floating>
            </Col>
          </Row>
        )}

        <Row className="mb-2">
          <Col>
            <div className="flatpickr-input-container">
              <Controller
                name="date_birth"
                control={control}
                render={({ field }) => (
                  <Form.Floating>
                    <Flatpickr
                      className={classNames('form-control')}
                      options={{
                        nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                        prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                        locale: getFlatpickrLocale(),
                        monthSelectorType: 'static',
                        onDayCreate: (...[, , , dayElem]) => {
                          if (
                            dayElem.dateObj.getDay() === 5 ||
                            dayElem.dateObj.getDay() === 6
                          ) {
                            dayElem.className += ' weekend-days';
                          }
                        },
                        dateFormat: 'd-m-Y',
                        enableTime: false,
                        disableMobile: true
                      }}
                      placeholder={t('date_birth')}
                      {...field}
                    />
                    <label htmlFor="startDatepicker" className="ps-6">
                      {t('date_birth')}
                    </label>
                  </Form.Floating>
                )}
              />
              <UilCalendarAlt className="flatpickr-icon text-700" size={16} />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="gender"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.gender}>
                      <option className="d-none" value=""></option>
                      {genderOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('gender')}</label>
                  </Form.Floating>
                )}
              />
              {errors?.gender && (
                <span
                  style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
                >
                  {t('required_field')}
                </span>
              )}
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {userStatusOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('status')}</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Form.Group className="d-flex align-items-center mb-3 gap-2">
          <Controller
            name="is_charger_inventory"
            control={control}
            render={({ field }) => (
              <Form.Check
                {...field}
                label={t('inventory_manager')}
                className="cursor-pointer"
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
              />
            )}
          />

          <Controller
            name="is_charger_return"
            control={control}
            render={({ field }) => (
              <Form.Check
                {...field}
                label={t('responsible_for_returns')}
                className="cursor-pointer"
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
              />
            )}
          />
        </Form.Group>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
          loadingPosition="start"
        >
          {t('add')}
        </Button>
      </Form>
    </>
  );
};

export default CreateClients;
