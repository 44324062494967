/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import { ColumnDef } from '@tanstack/react-table';
import { Form } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { useRef, useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import productSearchService from 'service/productSearch';
import { showAlert } from 'store/alert/alert.thunk';
import Button from 'components/base/Button';

export type UpdateSelection = {
  product_name: string;
  barcode: string;
  price_in: string;
  price: string;
  col: string;
  stock: string;
  buy_date: string;
};

interface UpdateSelectionAmountProps {
  setOpenAmount: (show: boolean) => void;
  changedOrderItems?: any;
  setChangedOrderItems?: any;
  selectedRowId?: any;
}

const UpdateSelectionAmount = ({
  setOpenAmount,
  changedOrderItems,
  setChangedOrderItems,
  selectedRowId
}: UpdateSelectionAmountProps) => {
  const { t, i18n } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch: Dispatch<any> = useDispatch();

  const [rowSingleData, setRowSingleData] = useState<any>({});
  const [value, setValue] = useState('');
  const [isProduct, setIsProduct] = useState(false);
  const [disabledPlusIcons, setDisabledPlusIcons] = useState<{
    [key: string]: boolean;
  }>({});
  const [isControl, setIsControl] = useState(false);
  const [extraNumbers, setExtraNumbers] = useState<any>({
    extra_charge: 0,
    extra_charge_wholesale: 0
  });

  const { data } = useQuery(
    [
      'GET_PRODUCT',
      selectedRowId?.recipient_id,
      selectedRowId?.nomenclature_id
    ],
    () => {
      if (
        selectedRowId?.recipient_id !== '' &&
        selectedRowId?.nomenclature_id !== ''
      ) {
        const res = productSearchService
          .getProductWithAccessByIds(
            selectedRowId?.recipient_id,
            selectedRowId?.nomenclature_id,
            {
              offset: 0,
              limit: 10
            }
          )
          .then((res: any) => {
            setIsControl(res?.is_control ?? false);
            setExtraNumbers({
              extra_charge: res?.extra_charge,
              extra_charge_wholesale: res?.extra_charge_wholesale
            });
            setIsProduct(res?.is_product ?? false);
            return res?.products;
          });

        return res;
      }
    },
    {
      enabled: !!selectedRowId?.recipient_id && !!selectedRowId?.nomenclature_id
    }
  );

  const comingResultData: UpdateSelection[] = useMemo(() => {
    const resultData =
      data?.map((items: any) => {
        console.log('isControl', isControl);
        console.log('extraNumbers', extraNumbers);

        console.log('extraNumbers.extra_change', extraNumbers?.extra_charge);
        console.log('items?.price_wholesale', items?.price_whosale);

        console.log(
          'case2',
          extraNumbers?.extra_charge && +items?.price_whosale
        );

        return {
          nomenclature_id: items?.nomenclature?.id,
          product_name:
            isProduct === true ? items?.name : items?.nomenclature?.name,
          barcode:
            isProduct === true ? items?.barcode : items?.nomenclature?.barcode,
          /* prettier-ignore */
          price: isControl
            ? items?.price
            : extraNumbers?.extra_charge && items?.price_whosale
              ? (extraNumbers?.extra_charge * +items?.price_whosale) / 100 +
                +items?.price_whosale
              : 0,
          /* prettier-ignore */
          price_in: isControl
            ? items?.price_in ?? 0
            : items?.price_whosale ?? 0,
          /* prettier-ignore */
          price_whosale: isControl
            ? items?.price_whosale_org
            : extraNumbers?.extra_charge_wholesale && items?.price_whosale
              ? (extraNumbers?.extra_charge_wholesale * +items?.price_whosale) /
                  100 +
                +items?.price_whosale
              : 0,
          col: items?.quantity ?? 0,
          stock: items?.warehouse?.name?.[i18n?.language],
          product_id: items?.id,
          product_parent_id: items?.id,
          warehouse_id: items?.warehouse_id,
          buy_date: new Date(items?.date_created)
            .toLocaleDateString('en-GB')
            .replace(/\//g, '-')
        };
      }) ?? [];

    return resultData;
  }, [data, extraNumbers, t, i18n?.language]);

  const handlePlusIconClick = (rowId: any) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setDisabledPlusIcons(() => {
      const newDisabledPlusIcons: { [key: string]: boolean } = {};
      newDisabledPlusIcons[rowId] = true;
      return newDisabledPlusIcons;
    });
  };

  const handleAccept = () => {
    if (value) {
      const updatedData = changedOrderItems.map((item: any, index: any) => {
        if (index === +selectedRowId.rowId) {
          return {
            ...item,
            warehouse_id: rowSingleData?.warehouse_id,
            product_id: rowSingleData?.product_id,
            product_parent_id: rowSingleData?.product_parent_id,
            quantity_fact: +value,
            price: rowSingleData?.price,
            price_in: rowSingleData?.price_in,
            price_whosale: rowSingleData?.price_whosale,
            nomenclature_id: rowSingleData?.nomenclature_id
          };
        }
        return item;
      });

      setChangedOrderItems(updatedData);
      setOpenAmount(false);
    } else {
      dispatch(
        showAlert({
          title: t('select_product_add_quantity')
        })
      );
    }
  };

  const updateSelectionTableColumns: ColumnDef<UpdateSelection>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Link to={`#`}>
            <FeatherIcon
              icon={disabledPlusIcons[rowData.row.id] ? 'check' : 'plus'}
              className="cursor-pointer"
              size={22}
              onClick={() => {
                handlePlusIconClick(rowData.row.id);
                setRowSingleData(rowData?.row?.original);
              }}
            />
          </Link>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'price_in',
      header: t('purchase_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'price',
      header: t('selling_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'col',
      header: t('short_quantity'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'stock',
      header: t('warehouse'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'buy_date',
      header: 'Дата закупа',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const handleChange = (e: any) => {
    const newValue = e.target.value;

    // Convert the input string to a number
    const numValue = parseInt(newValue, 10);

    // If the number is valid and within range, update the state
    if (!isNaN(numValue) && numValue > 0 && numValue <= +rowSingleData?.col) {
      setValue(numValue.toString());
    } else if (!newValue) {
      setValue(''); // Clears the value if it's empty
    }
  };

  const handleKeyDown = (e: any) => {
    // Prevent the minus sign and other non-numeric characters except numbers
    if (e.key === '-' || e.key === '+' || e.key === 'e') {
      e.preventDefault();
    }
  };

  const table = useAdvanceTable({
    data: comingResultData,
    columns: updateSelectionTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <AdvanceTableProvider {...table}>
      <div>
        <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
        <AdvanceTableFooter pagination />

        <div className="d-flex justify-content-end align-items-center text-center p-2 gap-3 border-top border-200">
          <div className="d-flex gap-3 align-items-center bg-light p-2">
            <p className="mb-0 text-bold">{t('order_quantity')}:</p>
            <span>{selectedRowId?.quantity_asked}</span>
          </div>

          <Form.Floating>
            <Form.Control
              ref={inputRef}
              value={value}
              type="number"
              placeholder={t('select_quantity')}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onFocus={e => e.target.select()}
            />
            <label htmlFor="floatingInputCustom">{t('select_quantity')}</label>
          </Form.Floating>

          <Button variant="primary" onClick={() => handleAccept()}>
            {t('apply')}
          </Button>
        </div>
      </div>
    </AdvanceTableProvider>
  );
};

export default UpdateSelectionAmount;
