/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import productGroupService from 'service/productGroup';
import productSearchService from 'service/productSearch';
import { showAlert } from 'store/alert/alert.thunk';
import { store } from 'store';
import ChildTableData from './ChildTableData';

interface CreateChildProductProps {
  parentId?: any;
}

const CreateChildProduct = ({ parentId }: CreateChildProductProps) => {
  const { t } = useTranslation();

  const { register, handleSubmit, control, getValues, reset } = useForm({});

  const inputRef = useRef<HTMLInputElement | any>();
  const dispatch: Dispatch<any> = useDispatch();

  const [searchedValue, setSearchedValue] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const [openTable, setOpenTable] = useState(false);

  const handleGetProduct = (e: any) => {
    if (e.key === 'Enter') {
      if (searchedValue !== '') {
        productSearchService
          .getListProduct({
            offset: 0,
            limit: 20,
            barcode: searchedValue ? searchedValue : undefined
          })
          .then((res: any) => {
            const computed = res?.products?.map((el: any) => ({
              barcode: el?.barcode,
              name: res?.is_product === true ? el?.name : el?.nomenclature?.name
            }));
            if (computed?.length > 0) {
              reset(computed?.[0]);
            } else {
              dispatch(showAlert({ title: t('barcode_not_found') }));
              reset({
                barcode: '',
                name: ''
              });
            }
          });
      }
    }
  };

  const onSubmit = () => {
    const data = getValues();

    if (data?.barcode !== '') {
      setLoading(true);
      productGroupService
        ?.createProductGroup({
          group_products: [
            {
              parent_barcode: parentId,
              child_barcode: data?.barcode
            }
          ]
        })
        .then(res => {
          if (res) {
            dispatch(
              showAlert({
                title: t('product_successfully_added_grouping'),
                type: 'success'
              })
            );

            setLoading(false);
            reset({
              barcode: '',
              name: ''
            });
            setSearchedValue('');
          }
        })
        .catch(error => {
          if (error?.response?.status === 409) {
            store.dispatch(showAlert({ title: t('product_already_added') }));
          }

          setLoading(false);
        });
    } else {
      dispatch(showAlert({ title: t('barcode_not_added') }));
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 500);
    }
  }, [inputRef]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Row className="mb-2">
          <Col className="modal-rows">
            <Controller
              name="scan"
              control={control}
              render={({ field }) => (
                <Form.Floating className="first-item">
                  <Form.Control
                    {...field}
                    ref={inputRef}
                    type="text"
                    placeholder={t('scan_barcode')}
                    value={searchedValue}
                    onChange={e => setSearchedValue(e?.target?.value)}
                    onFocus={e => {
                      e.target.select();
                    }}
                    onKeyDown={e => handleGetProduct(e)}
                    autoComplete="off"
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('scan_barcode')}
                  </label>
                </Form.Floating>
              )}
            />

            <Button
              variant="primary"
              type="button"
              onClick={() => setOpenTable(true)}
            >
              {t('search')}
            </Button>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('name')}
                type="text"
                placeholder={t('product_names')}
                onFocus={e => e.target.select()}
                autoComplete="off"
              />
              <label htmlFor="floatingInputCustom">{t('product_names')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Button
          style={{ height: '48px' }}
          className="w-100 mt-auto"
          variant="primary"
          type="button"
          onClick={() => onSubmit()}
          loading={loading}
          loadingPosition="start"
        >
          {t('add')}
        </Button>

        <Modal
          show={openTable}
          onHide={() => setOpenTable(false)}
          size="xl"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('search_product')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ChildTableData
              setOpenTable={setOpenTable}
              reset={reset}
              setSearchedValue={setSearchedValue}
            />
          </Modal.Body>
        </Modal>
      </Form>
    </>
  );
};

export default CreateChildProduct;
