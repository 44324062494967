/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './componenToCheck.scss';

interface ComponentCheckToPrintProps {
  data?: any;
}

const ComponentCheckToPrint = ({ data }: ComponentCheckToPrintProps) => {
  const { t, i18n } = useTranslation();

  const legalEntityInfo = useSelector(
    (state: any) => state?.auth?.legal_entity
  );

  return (
    <div className="print-check-container">
      <div className="legal-entity-info">
        <p className="dublicat">{t('duplicate')}</p>
        <p className="title">{legalEntityInfo?.name}</p>
        <p className="phone">
          {t('phone_number')}: {legalEntityInfo?.phone_number}
        </p>
        <p className="address">
          {t('address')}: {legalEntityInfo?.address}
        </p>
      </div>

      <div className="receipt-info">
        <ul className="receipt-titles-header">
          <li>{t('cashier')}</li>
          <li>{t('check_number')}</li>
          <li>{t('payment_date')}</li>
        </ul>

        <ul className="receipt-titles">
          <li>
            {data?.receipt?.employee?.first_name +
              ' ' +
              data?.receipt?.employee?.last_name}
          </li>
          <li>{data?.receipt?.receipt_number}</li>
          <li>
            {new Date(data?.receipt?.date_create)
              .toLocaleString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
              })
              .replace(/\//g, '-')
              .replace(',', '')}
          </li>
        </ul>
      </div>

      <div className="sales-info">
        <div className="sales-header">
          <p className="header-print">№</p>
          <p className="header-print">{t('product')}</p>
          <p
            className={
              i18n?.language === 'ru' ? 'header-print' : 'header-print-uz'
            }
          >
            {t('qnt')}
          </p>
          <p
            className={
              i18n?.language === 'ru' ? 'header-print' : 'header-print-uz-pay'
            }
          >
            {t('to_pay')}
          </p>
        </div>

        {data?.sales?.map((el: any, index: any) => {
          const packageQuantity = el?.product?.package_quantity || 1;
          const quantity = String(el?.quantity ?? 0) || '0';

          let integerPart = 0;
          let numerator = 0;
          let denominator = 1;

          if (quantity?.includes('.')) {
            [numerator, denominator] = quantity.split('.').map(Number);
            integerPart = numerator; // Whole number part
            denominator = +`0.${denominator}`; // Remainder of the division
          } else {
            integerPart = +quantity;
          }
          const quantityDisplay = quantity?.includes('.')
            ? `${integerPart}/${+denominator * packageQuantity}`
            : integerPart;

          return (
            <div key={el?.id} className="sales-box">
              <div className="sales-names">
                <p className="order">{index + 1}</p>
                <p className="titles">{el?.product?.name}</p>
              </div>

              <div className="sales-costs-box">
                <div className="sales-costs">
                  {/* prettier-ignore */}
                  <p className="calculation">
                    {el?.product?.measure_unit_id ===
                    '443bfff1-61e0-4057-8583-d040dc5a0454'
                      ? quantityDisplay
                      : el?.quantity
                        ? new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                            useGrouping: true
                          })
                            .format(el?.quantity)
                            .replace(/,/g, ' ')
                        : 0}{' '}
                    X{' '}
                    {el?.payment_amount
                      ? new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          useGrouping: true
                        })
                          .format(el?.payment_amount)
                          .replace(/,/g, ' ')
                      : 0}
                  </p>
                  <p className="total">
                    {el?.payment_amount_total
                      ? new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          useGrouping: true
                        })
                          .format(el?.payment_amount_total)
                          .replace(/,/g, ' ')
                      : 0}
                  </p>
                </div>
                {el?.amount_returned && (
                  <div className="sales-costs-returned">
                    <p className="calculation">
                      {el?.quantity_returned
                        ? new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                            useGrouping: true
                          })
                            .format(el?.quantity_returned)
                            .replace(/,/g, ' ')
                        : 0}{' '}
                      X{' '}
                      {el?.payment_amount
                        ? new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                            useGrouping: true
                          })
                            .format(el?.payment_amount)
                            .replace(/,/g, ' ')
                        : 0}
                    </p>
                    <p className="total">
                      {el?.amount_returned
                        ? new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                            useGrouping: true
                          })
                            .format(el?.amount_returned)
                            .replace(/,/g, ' ')
                        : 0}
                    </p>
                  </div>
                )}
                {/* <div className="sales-costs-vat">
                  <p className="vat">
                    в.т.ч. НДС 12% {el?.vat_value?.toFixed(2) ?? 0}
                  </p>
                </div> */}
              </div>
            </div>
          );
        })}
      </div>

      <div className="sales-total">
        <ul>
          <li>{t('sales_amount')}</li>
          <li>{t('discount')}</li>
        </ul>
        <ul>
          <li>
            {data?.receipt?.amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                  useGrouping: true
                })
                  .format(
                    data?.receipt?.amount +
                      (data?.receipt?.discount_amount ?? 0)
                  )
                  .replace(/,/g, ' ')
              : 0}
          </li>
          <li>
            {data?.receipt?.discount_amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                  useGrouping: true
                })
                  .format(data?.receipt?.discount_amount)
                  .replace(/,/g, ' ')
              : 0}
          </li>
        </ul>
      </div>

      <div className="to-pay">
        <p className="pay-title">{t('to_pay')}</p>
        <p className="pay-sum">
          {data?.receipt?.amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
                useGrouping: true
              })
                .format(data?.receipt?.amount)
                .replace(/,/g, ' ')
            : 0}
        </p>
      </div>

      <div className="is-paied">
        <p className="is-paied-title">{t('paid')}</p>
        <p className="is-paied-sum">
          {data?.receipt?.payed_amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
                useGrouping: true
              })
                .format(data?.receipt?.payed_amount)
                .replace(/,/g, ' ')
            : 0}
        </p>
      </div>

      {data?.sale_transaction &&
        data?.sale_transaction?.map((el: any) => (
          <div key={el?.id} className="sale-transaction">
            <p className="title">
              {el?.payment_method?.name?.[i18n?.language]}
            </p>
            <p className="sum">
              {el?.amount_in
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                    useGrouping: true
                  })
                    .format(el?.amount_in)
                    .replace(/,/g, ' ')
                : 0}{' '}
              {el?.currency?.name?.[i18n?.language]}
            </p>
          </div>
        ))}

      {/* <div className="extra">
        <ul>
          <li>НДС</li>
          <li>Бонус</li>
        </ul>
        <ul>
          <li>0</li>
          <li>0</li>
        </ul>
      </div>

      <div className="is-debt">
        <p className="is-debt-title">Сумма долга</p>
        <p className="is-debt-sum">{debtCost}</p>
      </div> */}
    </div>
  );
};

export default ComponentCheckToPrint;
