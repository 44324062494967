/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import ExpensesFilter from './ExpensesFilter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import expensesService from 'service/expenses';
import legalEntitySettingsService from 'service/legalEntitySettings';

import './styles.scss';

export type ExpensesData = {
  contacts: string;
  source: string;
  currency: string;
  cost_type: string;
  cost_amount: string;
  note: string;
  date_create: string;
};

export const expensesBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'cost',
    active: true
  }
];

const Expenses = () => {
  const { t, i18n } = useTranslation();
  const [dateFrom, setDateFrom] = useState<any>(null);
  const [dateTo, setDateTo] = useState<any>(null);
  const [optionUserId, setOptionUserId] = useState('');
  const [optionArticleId, setOptionArticleId] = useState('');
  const [optionCurrencyId, setOptionCurrencyId] = useState('');

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? undefined
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000
          : undefined;

  //Get EXpenses
  const { data, refetch } = useQuery(
    ['GET_EXPENSES'],
    () => {
      const res = expensesService
        .getList({
          offset: 0,
          limit: 1000,
          date_from: dateFrom,
          date_to: adjustedDateTo,
          user_id: optionUserId ? optionUserId : undefined,
          article_id: optionArticleId ? optionArticleId : undefined,
          currency_id: optionCurrencyId ? optionCurrencyId : undefined
        })
        .then((res: any) => res?.expenses);
      return res;
    },
    {
      enabled:
        !!dateFrom ||
        !!adjustedDateTo ||
        !!optionUserId ||
        !!optionArticleId ||
        !!optionCurrencyId
    }
  );

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const expenseData: ExpensesData[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    return (
      data?.map((items: any) => {
        return {
          contacts: items?.employee?.phone_number,
          cost_amount: items?.amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.amount)
                .replace(/,/g, ' ')
            : 0,
          source: items?.expense_source?.name?.[i18n?.language],
          currency: items?.currency?.name?.[i18n?.language],
          cost_type: items?.article?.name?.[i18n?.language],
          note: items?.note,
          /* prettier-ignore */
          date_create: formatDateToTimeZone(items?.date_create, timeZone)
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const expensesDataTableColumns: ColumnDef<ExpensesData>[] = [
    {
      accessorKey: 'contacts',
      header: t('contacts'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'source',
      header: t('source'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'cost_type',
      header: t('type_expenses'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'cost_amount',
      header: t('total_costs'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'note',
      header: t('note'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'date_create',
      header: t('date_create'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: expenseData,
    columns: expensesDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const sumOfAllCost = data?.reduce((acc: any, item: any) => {
    const allCost = item?.amount === undefined ? 0 : +item.amount;
    return acc + allCost;
  }, 0);

  useEffect(() => {
    refetch();
  }, [dateFrom, dateTo, optionUserId, optionArticleId, optionCurrencyId]);

  return (
    <div>
      <PageBreadcrumb items={expensesBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{t('cost')}</h2>

        <ExpensesFilter
          refetch={refetch}
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          setOptionUserId={setOptionUserId}
          setOptionArticleId={setOptionArticleId}
          setOptionCurrencyId={setOptionCurrencyId}
        />

        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>

        <div className="footer-expen">
          <div className="box">
            <p className="mb-0 text-bold">{t('total_amount_shortages')}:</p>
            <span>{sumOfAllCost}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expenses;
