/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import {
  useForm,
  Controller,
  FieldValues,
  SubmitHandler
} from 'react-hook-form';
import { useQuery } from 'react-query';
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import Flatpickr from 'react-flatpickr';
import { UilCalendarAlt } from '@iconscout/react-unicons';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { UzbekLatin } from 'flatpickr/dist/l10n/uz_latn.js';

import Button from 'components/base/Button';
import legalEntityService from 'service/legalEntity';
import warehouseService from 'service/warehouse';
import unitService from 'service/units';
import legalEntityCurrencyService from 'service/legalEntityCurrency';
import reportInvoiceService from 'service/reportInvoice';
import { pageToOffset } from 'utils/pageToOffset';

interface RemainderCreateFiltertProps {
  setGeneratedTableData?: any;
  savedValues?: any;
  setSavedValues?: any;
  setOpenModal: (show: boolean) => void;
  getName?: any;
  getBarcode?: any;
  setGetFilterData?: any;
  setCurrencyName?: any;
  setTotalQuantity?: any;
  setTotalincome?: any;
  setTotalSale?: any;
  currentPage?: any;
  setPageCount?: any;
}

const RemainderCreateFiltert = ({
  setGeneratedTableData,
  savedValues,
  setSavedValues,
  setOpenModal,
  getName,
  getBarcode,
  setGetFilterData,
  setCurrencyName,
  setTotalQuantity,
  setTotalincome,
  setTotalSale,
  currentPage,
  setPageCount
}: RemainderCreateFiltertProps) => {
  const { t, i18n } = useTranslation();

  const { control, handleSubmit, reset, setValue, watch } = useForm();
  const currencyId = useSelector(
    (state: any) => state?.auth?.legal_entity?.currency_id
  );

  const [legalEntityOption, setLegalEntityOption] = useState<any>([]);
  const [warehouseOption, setWarehouseOption] = useState<any>([]);
  const [currencyOption, setCurrencyOption] = useState<any>([]);
  const [unitsOption, setUnitsOption] = useState<any>([]);

  //Get by id
  const fetchData = () => {
    if (savedValues === '') return false;

    savedValues?.map((el: any) => {
      const computed = {
        date_on: el?.date_from,
        provider: el?.supplier_id,
        stock: el?.warehouse_id,
        currency: el?.currency_id,
        unit: el?.measure_unit_id,
        grouped: el?.is_group ?? false,
        falsed: el?.is_negative ?? false
      };
      reset(computed);
    });
  };

  //GET LEgal Entity to options
  useQuery(['GET_LEGAL_ENTITY'], async () => {
    await legalEntityService
      .getLegalEntityOption({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entities?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));
        setLegalEntityOption(options);
      });
  });

  //GET Warehouse Entity to options
  useQuery(['GET_WAREHOUSE'], async () => {
    await warehouseService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.ware_houses?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));
        setWarehouseOption(options);
      });
  });

  //GET UNITS to options
  useQuery(['GET_UNITS'], async () => {
    await unitService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.measure_units?.map((option: any) => ({
        value: option?.id,
        label: option?.name?.[i18n?.language]
      }));
      setUnitsOption(options);
    });
  });

  //GET Currency Entity to options
  useQuery(['GET_CURRENCY'], async () => {
    await legalEntityCurrencyService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.legal_entity_currencies?.map((option: any) => ({
          value: option?.currency?.id,
          label: option?.currency?.name?.[i18n?.language]
        }));
        setCurrencyOption(options);
      });
  });

  const onSubmit: SubmitHandler<FieldValues> = data => {
    const createFilterData = {
      // prettier-ignore
      date: data?.date_on
          ? Date.parse(data?.date_on)
          : typeof data?.date_on === 'number'
            ? data?.date_on
            : undefined,

      supplier_id: data?.provider ? data?.provider : undefined,
      currency_id: data?.currency ? data?.currency : undefined,
      warehouse_id: data?.stock ? data?.stock : undefined,
      measure_unit_id: data?.unit ? data?.unit : undefined,
      is_group: data?.grouped ? data?.grouped : undefined,
      is_negative: data?.falsed ? data?.falsed : undefined
    };
    setGetFilterData(createFilterData);

    const selectedCurrency = currencyOption?.find(
      (el: any) => el?.value === data?.currency
    );

    setCurrencyName(selectedCurrency?.label);

    reportInvoiceService
      .getReportRemainingList({
        limit: 10,
        offset: pageToOffset(currentPage, 10),
        name: getName,
        barcode: getBarcode,
        ...createFilterData
      })
      .then((res: any) => {
        if (res) {
          setTotalQuantity(res?.total_quantity);
          setTotalincome(res?.total_income_amount);
          setTotalSale(res?.total_sale_amount);
          setPageCount(Math.ceil(res?.count / 10));

          const resData =
            res?.products?.map((items: any) => {
              const sum =
                items?.quantity && items?.price
                  ? items?.quantity * items?.price
                  : 0;
              const packageQuantity = items?.package_quantity || 1;
              const quantity = String(items?.quantity ?? 0) || '0';

              let integerPart = 0;
              let numerator = 0;
              let denominator = 1;

              if (quantity?.includes('.')) {
                [numerator, denominator] = quantity.split('.').map(Number);
                integerPart = numerator; // Whole number part
                denominator = +`0.${denominator}`; // Remainder of the division
              } else {
                integerPart = +quantity;
              }
              /* prettier-ignore */
              const quantityDisplay = quantity?.includes('.')
                ? `${integerPart}/${Math?.round(+denominator * packageQuantity)}`
                : integerPart;

              return {
                product_name: items?.name,
                barcode: items?.barcode,
                unit: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
                currency: items?.currency?.name?.[i18n?.language],
                category: items?.nomenclature?.category?.name?.[i18n?.language],
                quantity:
                  items?.measure_unit_id ===
                  '443bfff1-61e0-4057-8583-d040dc5a0454'
                    ? quantityDisplay
                    : items?.quantity ?? 0,
                quantity_in: items?.quantity ?? 0,
                package_quantity: items?.package_quantity,
                measure_unit_id: items?.measure_unit_id,
                price: items?.price ?? 0,
                cost: items?.price
                  ? new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 4,
                      useGrouping: true
                    })
                      .format(items?.price)
                      .replace(/,/g, ' ')
                  : 0,
                cost_in: items?.price_in
                  ? new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 4,
                      useGrouping: true
                    })
                      .format(items?.price_in)
                      .replace(/,/g, ' ')
                  : 0,
                sum: sum
                  ? new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 4,
                      useGrouping: true
                    })
                      .format(sum)
                      .replace(/,/g, ' ')
                  : 0
              };
            }) ?? [];

          if (resData) {
            setGeneratedTableData(resData);
            setSavedValues([
              {
                date_on: data?.date_from ? data?.date_from : undefined,
                supplier_id: data?.provider ? data?.provider : undefined,
                measure_unit_id: data?.unit ? data?.unit : undefined,
                currency_id: data?.currency ? data?.currency : undefined,
                warehouse_id: data?.stock ? data?.stock : undefined,
                is_group: data?.grouped ? data?.grouped : undefined,
                is_negative: data?.falsed ? data?.falsed : undefined
              }
            ]);
            setOpenModal(false);
          }
        }
      });
  };

  const handleClear = () => {
    reportInvoiceService
      .getReportRemainingList({
        limit: 10,
        offset: pageToOffset(currentPage, 10),
        name: getName,
        barcode: getBarcode,
        currency_id: currencyId
      })
      .then((res: any) => {
        if (res) {
          setTotalQuantity(res?.total_quantity);
          setTotalincome(res?.total_income_amount);
          setTotalSale(res?.total_sale_amount);
          setPageCount(Math.ceil(res?.count / 10));

          const resData =
            res?.products?.map((items: any) => {
              const sum =
                items?.quantity && items?.price
                  ? items?.quantity * items?.price
                  : 0;
              const packageQuantity = items?.package_quantity || 1;
              const quantity = String(items?.quantity ?? 0) || '0';

              let integerPart = 0;
              let numerator = 0;
              let denominator = 1;

              if (quantity?.includes('.')) {
                [numerator, denominator] = quantity.split('.').map(Number);
                integerPart = numerator; // Whole number part
                denominator = +`0.${denominator}`; // Remainder of the division
              } else {
                integerPart = +quantity;
              }
              /* prettier-ignore */
              const quantityDisplay = quantity?.includes('.')
                ? `${integerPart}/${Math?.round(+denominator * packageQuantity)}`
                : integerPart;

              return {
                product_name: items?.name,
                barcode: items?.barcode,
                unit: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
                currency: items?.currency?.name?.[i18n?.language],
                category: items?.nomenclature?.category?.name?.[i18n?.language],
                quantity:
                  items?.measure_unit_id ===
                  '443bfff1-61e0-4057-8583-d040dc5a0454'
                    ? quantityDisplay
                    : items?.quantity ?? 0,
                quantity_in: items?.quantity ?? 0,
                package_quantity: items?.package_quantity,
                measure_unit_id: items?.measure_unit_id,
                price: items?.price ?? 0,
                cost: items?.price
                  ? new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 4,
                      useGrouping: true
                    })
                      .format(items?.price)
                      .replace(/,/g, ' ')
                  : 0,
                cost_in: items?.price_in
                  ? new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 4,
                      useGrouping: true
                    })
                      .format(items?.price_in)
                      .replace(/,/g, ' ')
                  : 0,
                sum: sum
                  ? new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 4,
                      useGrouping: true
                    })
                      .format(sum)
                      .replace(/,/g, ' ')
                  : 0
              };
            }) ?? [];

          if (resData) {
            setGeneratedTableData(resData);
            reset({
              date_on: '',
              provider: '',
              stock: '',
              currency: '',
              unit: '',
              grouped: true,
              falsed: false
            });
            setSavedValues([]);
            setOpenModal(false);
            setCurrencyName('');
          }
        }
      });
  };

  const getFlatpickrLocale = () => {
    switch (i18n.language) {
      case 'ru':
        return Russian;
      case 'uz':
        return UzbekLatin;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (savedValues.length > 0) {
      fetchData();
    }
  }, [savedValues]);

  useEffect(() => {
    if (savedValues.length === 0) {
      setValue('grouped', true);
    }
  }, [savedValues]);

  useEffect(() => {
    if (savedValues.length === 0) {
      setValue('falsed', true);
    }
  }, [savedValues]);

  useEffect(() => {
    if (currencyId) {
      const defaultCurrency = currencyOption.find(
        (option: any) => option.value === currencyId
      );
      if (defaultCurrency && savedValues?.length === 0) {
        setValue('currency', defaultCurrency.value);
      }
    }
  }, [currencyId, currencyOption, savedValues]);

  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          gap: '10px'
        }}
      >
        <Form.Group>
          <div className="react-select-container position-relative">
            <Controller
              name="provider"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select {...field}>
                    <option className="d-none" value=""></option>
                    {legalEntityOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">{t('supplier')}</label>
                  {watch('provider') !== '' && (
                    <Button
                      variant="link"
                      style={{
                        position: 'absolute',
                        right: '5%',
                        top: '15%',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setValue('provider', '');
                      }}
                    >
                      <FeatherIcon icon="delete" size={16} />
                    </Button>
                  )}
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group>
          <div className="react-select-container position-relative">
            <Controller
              name="stock"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select {...field}>
                    <option className="d-none" value=""></option>
                    {warehouseOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">{t('warehouse')}</label>
                  {watch('stock') !== '' && (
                    <Button
                      variant="link"
                      style={{
                        position: 'absolute',
                        right: '5%',
                        top: '15%',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setValue('stock', '');
                      }}
                    >
                      <FeatherIcon icon="delete" size={16} />
                    </Button>
                  )}
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group>
          <div className="react-select-container position-relative">
            <Controller
              name="unit"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select {...field}>
                    <option className="d-none" value=""></option>
                    {unitsOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">
                    {t('measure_unit')}
                  </label>
                  {watch('unit') !== '' && (
                    <Button
                      variant="link"
                      style={{
                        position: 'absolute',
                        right: '5%',
                        top: '15%',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setValue('unit', '');
                      }}
                    >
                      <FeatherIcon icon="delete" size={16} />
                    </Button>
                  )}
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group>
          <div className="react-select-container position-relative">
            <Controller
              name="currency"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select {...field}>
                    <option className="d-none" value=""></option>
                    {currencyOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">{t('currency')}</label>
                  {watch('currency') !== '' && (
                    <Button
                      variant="link"
                      style={{
                        position: 'absolute',
                        right: '5%',
                        top: '15%',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setValue('currency', '');
                      }}
                    >
                      <FeatherIcon icon="delete" size={16} />
                    </Button>
                  )}
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group className="flatpickr-input-container">
          <Controller
            name="date_on"
            control={control}
            render={({ field }) => (
              <Form.Floating>
                <Flatpickr
                  className={classNames('form-control')}
                  options={{
                    nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                    prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                    locale: getFlatpickrLocale(),
                    monthSelectorType: 'static',
                    onDayCreate: (...[, , , dayElem]) => {
                      if (
                        dayElem.dateObj.getDay() === 5 ||
                        dayElem.dateObj.getDay() === 6
                      ) {
                        dayElem.className += ' weekend-days';
                      }
                    },
                    dateFormat: 'M j, Y',
                    disableMobile: true
                  }}
                  placeholder={t('date_from')}
                  {...field}
                />
                <label htmlFor="startDatepicker" className="ps-6">
                  {t('date_from')}
                </label>
              </Form.Floating>
            )}
          />
          <UilCalendarAlt className="flatpickr-icon text-700" size={16} />
        </Form.Group>

        <Form.Group className="d-flex gap-3 mt-2">
          <Controller
            name="falsed"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Form.Check
                {...field}
                label={t('negative_balance')}
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
              />
            )}
          />
          <Controller
            name="grouped"
            control={control}
            render={({ field }) => (
              <Form.Check
                {...field}
                label={t('group')}
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
              />
            )}
          />
        </Form.Group>

        <Form.Group style={{ display: 'flex', gap: '10px', marginTop: 'auto' }}>
          <Button
            style={{ height: '48px', width: '100%' }}
            variant="outline-primary"
            type="button"
            onClick={() => handleClear()}
          >
            {t('reset')}
          </Button>
          <Button
            style={{ height: '48px', width: '100%' }}
            variant="primary"
            type="submit"
          >
            {t('apply')}
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default RemainderCreateFiltert;
