/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import productSearchService from 'service/productSearch';
import { showAlert } from 'store/alert/alert.thunk';
import unitService from 'service/units';
import productKindService from 'service/productKind';

interface UpdateMyProductProps {
  selectedProduct?: any;
  setSelectedProduct?: any;
  refetch?: any;
  setOpenProductUpdate?: any;
}

const UpdateMyProduct = ({
  selectedProduct,
  setSelectedProduct,
  refetch,
  setOpenProductUpdate
}: UpdateMyProductProps) => {
  const { t, i18n } = useTranslation();

  const { handleSubmit, control, getValues, reset, register, setValue } =
    useForm({});

  const inputRef = useRef<HTMLInputElement | any>();
  const dispatch: Dispatch<any> = useDispatch();

  const [loading, setLoading] = useState(false);
  const [measureUnitOption, setMeasureUnitOption] = useState<any>([]);
  const [productKindOption, setProductKindOption] = useState<any>([]);
  const [selectedMeasureUnitKindId, setSelectedMeasureUnitKindId] =
    useState('');

  //GET Measure Unit to options
  useQuery(['GET_MEASURE_UNIT'], async () => {
    await unitService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.measure_units?.map((option: any) => ({
        value: option.id,
        label: option.name?.[i18n?.language],
        measure_unit_kind_id: option?.measure_unit_kind_id
      }));
      setMeasureUnitOption(options);
    });
  });

  //GET Product kind to options
  useQuery(['GET_PRODUCT_KIND'], async () => {
    await productKindService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.product_kinds?.map((option: any) => ({
          value: option.id,
          label: option.name?.[i18n?.language]
        }));
        setProductKindOption(options);
      });
  });

  const fetchData = () => {
    if (!selectedProduct) return setLoading(false);

    const computed = {
      barcode: selectedProduct?.barcode,
      name: selectedProduct?.name,
      partion_number: selectedProduct?.partion_number,
      price: selectedProduct?.price,
      price_whosale: selectedProduct?.price_whosale,
      package_quantity: selectedProduct?.package_quantity,
      measure_unit_id: selectedProduct?.measure_unit_id,
      measure_unit_kind_id: selectedProduct?.measure_unit_kind_id,
      product_kind_id: selectedProduct?.product_kind_id,
      article: selectedProduct?.article
    };
    reset(computed);
    setSelectedMeasureUnitKindId(computed?.measure_unit_kind_id);
  };

  const onSubmit = () => {
    setLoading(true);
    const data = getValues();

    console.log('data', data);

    const updatedData = {
      name: data?.name,
      partion_number: String(data?.partion_number),
      price: +data?.price,
      price_whosale: +data?.price_whosale,
      measure_unit_id: data?.measure_unit_id,
      product_kind_id: data?.product_kind_id,
      package_quantity: +data?.package_quantity,
      article: data?.article
    };
    productSearchService
      ?.updateProduct(selectedProduct?.guid, updatedData)
      .then(res => {
        if (res) {
          dispatch(
            showAlert({
              title: t('product_successfully_changed'),
              type: 'success'
            })
          );
          setSelectedProduct({});
          refetch();
          setOpenProductUpdate(false);
        }
      });
  };

  useEffect(() => {
    if (selectedProduct) {
      fetchData();
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 500);
    }
  }, [inputRef]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Row className="mb-2">
          <Col>
            <Controller
              name="barcode"
              disabled={true}
              control={control}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder={t('barcode')}
                    onFocus={e => {
                      e.target.select();
                    }}
                    autoComplete="off"
                  />
                  <label htmlFor="floatingInputCustom">{t('barcode')}</label>
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Control
                    {...field}
                    ref={inputRef}
                    type="text"
                    placeholder={t('product_names')}
                    onFocus={e => {
                      e.target.select();
                    }}
                    autoComplete="off"
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('product_names')}
                  </label>
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        {selectedProduct?.is_custom && (
          <Row className="mb-2 ">
            <Col>
              <div className="d-flex gap-2">
                <div className="react-select-container flex-1">
                  <Controller
                    name="measure_unit_id"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Form.Floating>
                        <Form.Select
                          className="flex-1"
                          {...field}
                          onChange={(e: any) => {
                            const selectedOption = measureUnitOption.find(
                              (option: any) => option.value === e.target.value
                            );

                            if (selectedOption) {
                              setSelectedMeasureUnitKindId(
                                selectedOption?.measure_unit_kind_id
                              );
                              setValue(
                                'measure_unit_kind_id',
                                selectedOption?.measure_unit_kind_id
                              );
                            }
                            field.onChange(e);
                          }}
                        >
                          <option className="d-none" value=""></option>
                          {measureUnitOption?.map((option: any) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Form.Select>

                        <label htmlFor="floatingInputCustom">
                          {t('measure_unit')}{' '}
                          <span className="text-danger">*</span>
                        </label>
                      </Form.Floating>
                    )}
                  />
                </div>
                {selectedMeasureUnitKindId ===
                  '3c7cee55-ad41-4ee3-a409-a7bb5f428b36' && (
                  <Form.Floating className="md-4">
                    <Form.Control
                      {...register(`package_quantity`)}
                      type="number"
                      placeholder={t('quantity_packaging')}
                      onFocus={e => e.target.select()}
                    />
                    <label htmlFor="floatingInputCustom">
                      {t('quantity_packaging')}
                    </label>
                  </Form.Floating>
                )}
              </div>
            </Col>
          </Row>
        )}

        {selectedProduct?.is_custom && (
          <Row className="mb-2">
            <Col>
              <div className="react-select-container">
                <Controller
                  name="product_kind_id"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Floating>
                      <Form.Select {...field}>
                        <option className="d-none" value=""></option>
                        {productKindOption?.map((option: any) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>

                      <label htmlFor="floatingInputCustom">
                        {t('product_type')}{' '}
                        <span className="text-danger">*</span>
                      </label>
                    </Form.Floating>
                  )}
                />
              </div>
            </Col>
          </Row>
        )}

        {selectedProduct?.is_custom && (
          <Row className="mb-2">
            <Col>
              <Controller
                name="article"
                control={control}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Control
                      {...field}
                      type="text"
                      placeholder={t('article')}
                      onFocus={e => {
                        e.target.select();
                      }}
                      autoComplete="off"
                    />
                    <label htmlFor="floatingInputCustom">{t('article')}</label>
                  </Form.Floating>
                )}
              />
            </Col>
          </Row>
        )}

        <Row className="mb-2">
          <Col>
            <Controller
              name="partion_number"
              control={control}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder={t('serial_number')}
                    onFocus={e => {
                      e.target.select();
                    }}
                    autoComplete="off"
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('serial_number')}
                  </label>
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Controller
              name="price_whosale"
              control={control}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Control
                    {...field}
                    type="number"
                    placeholder={t('wholesale_price')}
                    onFocus={e => {
                      e.target.select();
                    }}
                    autoComplete="off"
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('wholesale_price')}
                  </label>
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Control
                    {...field}
                    type="number"
                    placeholder={t('selling_price')}
                    onFocus={e => {
                      e.target.select();
                    }}
                    autoComplete="off"
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('selling_price')}
                  </label>
                </Form.Floating>
              )}
            />
          </Col>
        </Row>
        <Button
          style={{ height: '48px' }}
          className="w-100 mt-auto"
          variant="primary"
          type="button"
          onClick={() => onSubmit()}
          loading={loading}
          loadingPosition="start"
        >
          {t('change_product')}
        </Button>
      </Form>
    </>
  );
};

export default UpdateMyProduct;
