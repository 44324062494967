import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import WriteOffInventoryTable from './WriteOffInventoryTable';
import { useTranslation } from 'react-i18next';

export const infoInventoryBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'write-off',
    url: '/write-off/write-off-inventory'
  },
  {
    label: 'result_inventory',
    active: true
  }
];

const WriteOffInventory = () => {
  const { t } = useTranslation();
  return (
    <div>
      <PageBreadcrumb items={infoInventoryBreadcrumbItems} />

      <div>
        <h2 className="mb-5">{t('result_inventory')}</h2>

        <WriteOffInventoryTable />
      </div>
    </div>
  );
};

export default WriteOffInventory;
