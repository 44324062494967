/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useMemo, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import reportInvoiceService from 'service/reportInvoice';
import Button from 'components/base/Button';
import PurchaseByInvoiceModalPriceList from './modal-price-list';
import legalEntitySettingsService from 'service/legalEntitySettings';

export type PurchaseData = {
  invoice: string;
  invoice_date: string;
  supplier: string;
  warehouse: string;
  currency: string;
  type: string;
  payment_procedure: string;
  creation_date: string;
  purchase_date: string;
  guid: string;
};

interface PurchaseByInvoiceTableProps {
  dateFrom?: any;
  dateTo?: any;
  paymentId?: any;
  optionSenderId?: any;
  warehouseId?: any;
  currencyId?: any;
  typeId?: any;
}

const PurchaseByInvoiceTable = ({
  dateFrom,
  dateTo,
  paymentId,
  optionSenderId,
  currencyId,
  typeId,
  warehouseId
}: PurchaseByInvoiceTableProps) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [modalPriceList, setModalPriceList] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState('');

  const navigateTo = (row: any) => {
    navigate(
      `/reports/purchase/invoice/purchase-invoice-result/${row?.original?.guid}`
    );
  };

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  // Initialize today's date in milliseconds
  const todayStart = new Date().setHours(0, 0, 0, 0);
  const todayEnd = new Date().setHours(0, 0, 0, 0);

  // Use the provided dates or default to today's date
  const adjustedDateFrom = dateFrom || todayStart;
  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? todayEnd + 86400000
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000
          : undefined;

  const { data, refetch } = useQuery(
    ['GET_REPORTS'],
    async () => {
      const res = await reportInvoiceService
        .getList({
          offset: 0,
          limit: 100,
          date_from: adjustedDateFrom,
          date_to: adjustedDateTo,
          supplier_id: optionSenderId,
          payment_order_id: paymentId,
          invoice_type_id: typeId,
          currency_id: currencyId,
          warehouse_id: warehouseId
        })
        .then((res: any) => res?.invoices);
      return res;
    },
    {
      enabled:
        !!adjustedDateFrom ||
        !!adjustedDateTo ||
        !!paymentId ||
        !!optionSenderId ||
        !!currencyId ||
        !!typeId ||
        !!warehouseId
    }
  );

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const reportData: PurchaseData[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    const resData =
      data?.map((items: any) => {
        return {
          invoice: items?.invoice_number,
          /* prettier-ignore */
          invoice_date: formatDateToTimeZone(items?.invoice_date, timeZone),
          supplier: items?.legal_entity?.name,
          warehouse: items?.warehouse?.name,
          currency: items?.currency?.name?.[i18n?.language],
          type: items?.invoice_type?.name?.ru,
          payment_procedure: items?.payment_order?.name?.[i18n?.language],
          /* prettier-ignore */
          creation_date: formatDateToTimeZone(items?.date_created, timeZone),
          /* prettier-ignore */
          purchase_date:items?.maturity_date ? formatDateToTimeZone(items?.maturity_date, timeZone)             : '',
          guid: items?.id
        };
      }) ?? [];

    return resData;
  }, [data, dataSettings, t, i18n?.language]);

  const purchaseDataTableColumns: ColumnDef<PurchaseData>[] = [
    {
      accessorKey: 'order_number',
      header: '№',
      cell: rowData => {
        const orderNumber = rowData.row.index + 1; // Calculate the order number based on the row index
        return <div>{orderNumber}</div>;
      },
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },

    {
      accessorKey: 'invoice',
      header: t('invoice_number'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'invoice_date',
      header: t(''),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'supplier',
      header: t('supplier'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'warehouse',
      header: t('warehouse'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'type',
      header: t('type'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'payment_procedure',
      header: t('payment_procedure'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'creation_date',
      header: t('date_create'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'purchase_date',
      header: t('maturity_date'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      id: 'action',
      header: t('price_tag'),
      cell: rowData => {
        return (
          <div>
            <Button
              variant="hover"
              onClick={(e: any) => {
                setSelectedInvoiceId(rowData?.row?.original?.guid);
                e.preventDefault();
                e.stopPropagation();
                setModalPriceList(true);
              }}
            >
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faTag}
                transform={{ size: 24 }}
              />
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '5%', textAlign: 'center' } },
        cellProps: { className: 'text-center' }
      }
    }
  ];
  const table = useAdvanceTable({
    data: reportData,
    columns: purchaseDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    refetch();
  }, [
    adjustedDateFrom,
    adjustedDateTo,
    paymentId,
    optionSenderId,
    currencyId,
    typeId,
    warehouseId
  ]);

  return (
    <AdvanceTableProvider {...table}>
      <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          navigateTo={navigateTo}
        />
        <AdvanceTableFooter pagination />
      </div>

      <Modal
        show={modalPriceList}
        onHide={() => {
          setModalPriceList(false);
          setSelectedInvoiceId('');
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('printing_price_tags')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PurchaseByInvoiceModalPriceList
            selectedInvoiceId={selectedInvoiceId}
          />
        </Modal.Body>
      </Modal>
    </AdvanceTableProvider>
  );
};

export default PurchaseByInvoiceTable;
