/* eslint-disable @typescript-eslint/no-explicit-any */
import requestAuth from 'utils/requestAuth';
import request from 'utils/request';

const purchaserService = {
  getList: (params: any) => requestAuth.get('/customer', { params }),
  getById: (id: string) => requestAuth.get(`/customer/${id}`),
  create: (data: any) => requestAuth.post('/customer', data),
  createExcel: (data: any) =>
    request.post('/dc/charterer/customer/excel', data),
  update: (data: any) => requestAuth.put(`/customer`, data)
};

export default purchaserService;
