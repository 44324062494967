/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Offcanvas } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { UzbekLatin } from 'flatpickr/dist/l10n/uz_latn.js';

import customerEntityService from 'service/charterer';
import Createbuyyers from './Createbuyyers';
import Button from 'components/base/Button';

import './styles.scss';

interface FilterProps {
  setDateFrom?: any;
  setDateTo?: any;
  dateFrom?: any;
  dateTo?: any;
  setCustomersData?: any;
}

const Filter = ({
  setDateFrom,
  setDateTo,
  dateFrom,
  dateTo,
  setCustomersData
}: FilterProps) => {
  const { t, i18n } = useTranslation();
  const [openCreate, setOpenCreate] = useState(false);

  const handleStartDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const startDate = new Date(date[0]);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    } else {
      const startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    }
  };

  const handleEndDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const endDateInMillis = date[0]?.getTime();
      setDateTo(endDateInMillis);
    } else {
      const endDateInMillis = date.getTime();
      setDateTo(endDateInMillis);
    }
  };
  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? undefined
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000
          : undefined;

  const handleGetData = () => {
    if (dateFrom || dateTo) {
      customerEntityService
        .getListCharterer({
          offset: 0,
          limit: 10,
          date_from: dateFrom,
          date_to: adjustedDateTo,
          law_subject_type: 'LEGAL'
        })
        .then((res: any) => {
          if (res) {
            setCustomersData(res?.debit_credit_list);
          }
        });
    }
  };

  const getFlatpickrLocale = () => {
    switch (i18n.language) {
      case 'ru':
        return Russian;
      case 'uz':
        return UzbekLatin;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (dateFrom || adjustedDateTo || openCreate) {
      handleGetData();
    }
  }, [dateFrom, adjustedDateTo, openCreate]);

  useEffect(() => {
    // Set initial dateFrom to the beginning of the current month
    const today = new Date();
    const beginningOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    setDateFrom(beginningOfMonth.getTime());

    // Set initial dateTo to today's date
    setDateTo(today.getTime());
  }, []);

  return (
    <div className="new-rows">
      <Form.Group className="md-4">
        <Form.Floating>
          <Flatpickr
            className={classNames('form-control')}
            options={{
              nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
              prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
              locale: getFlatpickrLocale(),
              monthSelectorType: 'static',
              onDayCreate: (...[, , , dayElem]) => {
                if (
                  dayElem.dateObj.getDay() === 5 ||
                  dayElem.dateObj.getDay() === 6
                ) {
                  dayElem.className += ' weekend-days';
                }
              },
              dateFormat: 'M j, Y',
              disableMobile: true
            }}
            value={dateFrom}
            onChange={handleStartDateChange}
            placeholder={t('date_from')}
          />
          <label htmlFor="floatingInputCustom">{t('date_from')}</label>
        </Form.Floating>
      </Form.Group>

      <Form.Group className="md-4">
        <Form.Floating>
          <Flatpickr
            className={classNames('form-control')}
            options={{
              nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
              prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
              locale: getFlatpickrLocale(),
              monthSelectorType: 'static',
              onDayCreate: (...[, , , dayElem]) => {
                if (
                  dayElem.dateObj.getDay() === 5 ||
                  dayElem.dateObj.getDay() === 6
                ) {
                  dayElem.className += ' weekend-days';
                }
              },
              dateFormat: 'M j, Y',
              disableMobile: true
            }}
            value={dateTo}
            onChange={handleEndDateChange}
            placeholder={t('date_to')}
          />
          <label htmlFor="floatingInputCustom">{t('date_to')}</label>
        </Form.Floating>
      </Form.Group>

      <div className="ms-auto">
        <Button
          variant="primary"
          style={{ height: '48px' }}
          onClick={() => setOpenCreate(true)}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          {t('new')}
        </Button>
      </div>

      <Offcanvas
        show={openCreate}
        onHide={() => {
          setOpenCreate(false);
        }}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            {t('new')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Createbuyyers setOpenCreate={setOpenCreate} />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Filter;
