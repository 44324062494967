/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';

export type LostProfitData = {
  product_name: string;
  barcode: string;
  daily_sale: string;
  missing_days: string;
  quantity_missing: string;
  amount_lost: string;
};

interface LostProfitTableProps {
  tableData?: any;
}

const LostProfitTable = ({ tableData }: LostProfitTableProps) => {
  const { t } = useTranslation();

  const lostData: LostProfitData[] = useMemo(() => {
    return (
      tableData?.items?.map((items: any) => {
        return {
          product_name: items?.product?.name,
          barcode: items?.product?.barcode,
          daily_sale: items?.dayly_sale ?? 0,
          missing_days: items?.lost_days ?? 0,
          quantity_missing: items?.lost_quantity ?? 0,
          amount_lost: items?.lost_amount ?? 0
        };
      }) ?? []
    );
  }, [tableData]);

  const lostProfitDataTableColumns: ColumnDef<LostProfitData>[] = [
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'daily_sale',
      header: t('daily_sale'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'missing_days',
      header: t('missed_days'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'quantity_missing',
      header: t('number_missed'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'amount_lost',
      header: t('amount_lost'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: lostData,
    columns: lostProfitDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });
  return (
    <AdvanceTableProvider {...table}>
      <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
        <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
        <AdvanceTableFooter pagination />
      </div>
    </AdvanceTableProvider>
  );
};

export default LostProfitTable;
