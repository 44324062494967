/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import specificationService from 'service/specification';

interface CreateCharacteristicsProps {
  setOpenCharacter: (show: boolean) => void;
  setCharacterFormData?: any;
  characterFormData?: any;
}

const CreateCharacteristics = ({
  setOpenCharacter,
  setCharacterFormData,
  characterFormData
}: CreateCharacteristicsProps) => {
  const { t, i18n } = useTranslation();

  const { register, control, getValues, setValue } = useForm();

  const [specificationOption, setSpecificationOption] = useState<any>([]);

  //GET Specification to options
  useQuery(['GET_SPECIFICATION'], async () => {
    await specificationService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.specifications?.map((option: any) => ({
          value: option.id,
          label: option.name?.[i18n?.language]
        }));
        setSpecificationOption(options);
      });
  });

  const onSubmit = () => {
    const data = getValues();
    const formDataItem = {
      character_name: data?.label,
      values: data?.values,
      specification_id: data?.name
    };

    const updatedFormData = characterFormData.filter(
      (item: any) => item.specification_id !== formDataItem.specification_id
    );

    // Add the new item
    setCharacterFormData([...updatedFormData, formDataItem]);
    setOpenCharacter(false);
  };
  return (
    <div>
      <Form noValidate>
        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = specificationOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setValue('label', selectedOption.label);
                        }
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {specificationOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                    <label htmlFor="floatingInputCustom">
                      {t('characteristic')}
                    </label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <Form.Floating className="md-4">
              <Form.Control
                {...register('values')}
                type="text"
                placeholder={t('meanings')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('meanings')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Button
          className="w-100"
          variant="primary"
          type="button"
          onClick={onSubmit}
        >
          {t('save')}
        </Button>
      </Form>
    </div>
  );
};

export default CreateCharacteristics;
