/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import dashboardService from 'service/dashboard';
import styles from './styles.module.scss';

export const branchOption = [
  {
    label: 'Epa',
    value: 'Epa'
  }
];

interface SalesAndGoodsInfoProps {
  selectedCurrencyId?: any;
  dateFrom?: any;
  dateTo?: any;
}

const SalesAndGoodsInfo = ({
  selectedCurrencyId,
  dateFrom,
  dateTo
}: SalesAndGoodsInfoProps) => {
  const { t } = useTranslation();

  //Get RATING
  const { data } = useQuery(
    ['GET_RATING', dateFrom, dateTo, selectedCurrencyId],
    () => {
      if (selectedCurrencyId && dateFrom) {
        const res = dashboardService
          .getRating({
            offset: 0,
            limit: 10,
            date_from: dateFrom,
            date_to: dateTo,
            currency_id: selectedCurrencyId
          })
          .then((res: any) => res);
        return res;
      }
    },
    { enabled: !!dateFrom || !!dateTo || !!selectedCurrencyId }
  );

  return (
    <div className={styles.SGContainer}>
      <div className={styles.SGBox}>
        <p className={styles.title}>{t('top_sellers')}</p>

        <div className={styles.SGSelects}>
          {/* <Form.Floating className="w-100">
            <Form.Select>
              <option className="d-none" value=""></option>
              {branchOption?.map((option: any) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>

            <label htmlFor="floatingInputCustom">Сумма продаж</label>
          </Form.Floating> */}
        </div>

        {data?.cashiers?.map((items: any) => (
          <div key={items} className={styles.SGBInfo}>
            <ul className={styles.bTitles}>
              <li>{`${items?.user?.first_name} ${items?.user?.last_name}`}</li>
            </ul>

            <ul className={styles.bCounts}>
              <li>
                {items?.amount
                  ? new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 4,
                      useGrouping: true
                    })
                      .format(items?.amount)
                      .replace(/,/g, ' ')
                  : '0'}{' '}
                {t('sum')}
              </li>
            </ul>
          </div>
        ))}
      </div>

      <div className={styles.SGBox}>
        <p className={styles.title}>{t('top_products')}</p>

        <div className={styles.SGSelects}>
          {/* <Form.Floating className="w-100">
            <Form.Select>
              <option className="d-none" value=""></option>
              {branchOption?.map((option: any) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>

            <label htmlFor="floatingInputCustom">Обозначение</label>
          </Form.Floating>

          <Form.Floating className="w-100">
            <Form.Select>
              <option className="d-none" value=""></option>
              {branchOption?.map((option: any) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>

            <label htmlFor="floatingInputCustom">Чистая выручка</label>
          </Form.Floating> */}
        </div>

        {data?.products?.map((items: any) => (
          <div key={items} className={styles.SGBInfo}>
            <ul className={styles.bTitles}>
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                    {items?.product?.name}
                  </Tooltip>
                }
              >
                <li>{items?.product?.name}</li>
              </OverlayTrigger>
            </ul>

            <ul className={styles.bCounts}>
              <li>
                {items?.amount
                  ? new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 4,
                      useGrouping: true
                    })
                      .format(items?.amount)
                      .replace(/,/g, ' ')
                  : '0'}{' '}
                {t('sum')}
              </li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SalesAndGoodsInfo;
