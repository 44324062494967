/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import reportInvoiceService from 'service/reportInvoice';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import legalEntitySettingsService from 'service/legalEntitySettings';

import './styles.scss';

export type PurchaseData = {
  product_name: string;
  barcode: string;
  warehouse: string;
  currency: string;
  category: string;
  unit: string;
  quantity: string;
  incoming_price: string;
  wholesale_price: string;
  payment_procedure: string;
  supplier: string;
  retail_price: string;
  apply: string;
  total_price_received: string;
  total_price: string;
  creation_date: string;
};

interface PurchaseByGoodsTableProps {
  generateTableData?: any;
  setGeneratedTableData?: any;
  getName?: any;
  getBarcode?: any;
  getFilterData?: any;
  totalAmount?: any;
  setTotalAmount?: any;
}

const PurchaseByGoodsTable = ({
  generateTableData,
  setGeneratedTableData,
  getName,
  getBarcode,
  getFilterData,
  totalAmount,
  setTotalAmount
}: PurchaseByGoodsTableProps) => {
  const { t, i18n } = useTranslation();
  // Initialize today's date in milliseconds
  const todayStart = new Date().setHours(0, 0, 0, 0);
  const todayEnd = new Date().setHours(0, 0, 0, 0);

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  const { data, refetch } = useQuery(
    ['GET_REPORTS_PRODUCT', getName, getBarcode],
    async () => {
      const res = await reportInvoiceService
        .getReportProductList({
          offset: 0,
          limit: 100,
          name: getName,
          date_from: Object.keys(getFilterData).length === 0 && todayStart,
          date_to:
            Object.keys(getFilterData).length === 0 && todayEnd + 86400000,
          barcode: getBarcode,
          ...getFilterData
        })
        .then((res: any) => {
          setTotalAmount(res?.total_amount);
          return res?.products;
        });
      return res;
    },
    {
      enabled:
        getName.length === 0 ||
        getName.length > 2 ||
        getBarcode.length === 0 ||
        getBarcode.length > 2
    }
  );

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  useEffect(() => {
    if (data) {
      let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

      timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

      const resData =
        data?.map((items: any) => {
          const packageQuantity = items?.package_quantity || 1;
          const quantity = String(items?.quantity_in ?? 0) || '0';

          let integerPart = 0;
          let numerator = 0;
          let denominator = 1;

          if (quantity?.includes('.')) {
            [numerator, denominator] = quantity.split('.').map(Number);
            integerPart = numerator; // Whole number part
            denominator = +`0.${denominator}`; // Remainder of the division
          } else {
            integerPart = +quantity;
          }
          const quantityDisplay = quantity?.includes('.')
            ? `${integerPart}/${Math?.round(+denominator * packageQuantity)}`
            : integerPart;
          return {
            product_name: items?.name,
            barcode: items?.barcode,
            warehouse: items?.ware_house?.name,
            unit: items?.nomenclature?.measure_unit?.name?.ru,
            currency: items?.currency?.name?.ru,
            payment_procedure: items?.payment_order?.name?.ru,
            category: items?.category?.name?.ru,
            supplier: items?.legal_entity?.name,
            quantity:
              items?.nomenclature?.measure_unit_id ===
              '443bfff1-61e0-4057-8583-d040dc5a0454'
                ? quantityDisplay
                : items?.quantity_in ?? 0,
            measure_unit_id: items?.nomenclature?.measure_unit_id,
            incoming_price: items?.price_in
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(items?.price_in)
                  .replace(/,/g, ' ')
              : 0,
            wholesale_price: items?.price_whosale
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(items?.price_whosale)
                  .replace(/,/g, ' ')
              : 0,
            retail_price: items?.price
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(items?.price)
                  .replace(/,/g, ' ')
              : 0,
            apply: items?.is_vat == true ? 'Да' : 'Нет',
            /* prettier-ignore */
            total_price_received:
            items?.price_in && items?.quantity
              ? +items.price_in * +items.quantity
                : 0,
            /* prettier-ignore */
            total_price: items?.price && items?.quantity ? (+items.price * +items.quantity) : 0,
            /* prettier-ignore */
            creation_date: formatDateToTimeZone(items?.date_created, timeZone)
          };
        }) ?? [];

      if (resData) {
        setGeneratedTableData(resData);
      }
    }
  }, [data, setGeneratedTableData, dataSettings]);

  const purchaseDataTableColumns: ColumnDef<PurchaseData>[] = [
    {
      accessorKey: 'order_number',
      header: '№',
      cell: rowData => {
        const orderNumber = rowData.row.index + 1; // Calculate the order number based on the row index
        return <div>{orderNumber}</div>;
      },
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },
    {
      accessorKey: 'product_name',
      header: t('product_name'),
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },
    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'warehouse',
      header: t('warehouse'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'payment_procedure',
      header: t('payment_procedure'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'unit',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'supplier',
      header: t('supplier'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'category',
      header: t('category'),
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },
    {
      accessorKey: 'quantity',
      header: t('quantity'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'incoming_price',
      header: t('price_admission'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'wholesale_price',
      header: t('wholesale_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'retail_price',
      header: t('price_difference'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'apply',
      header: t('apply_vat'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'total_price_received',
      header: t('total_price_admission'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'total_price',
      header: t('final_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'creation_date',
      header: t('date_create'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: generateTableData,
    columns: purchaseDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    refetch();
  }, [getName, getBarcode]);

  const formatPrice = (price: any) => {
    const priceNumber = Number(price);
    if (isNaN(priceNumber)) {
      return { main: '0', decimals: '00' };
    }
    const priceParts = Number.isInteger(priceNumber)
      ? [priceNumber?.toString(), '00']
      : priceNumber?.toFixed(4).split('.');
    return {
      main: priceParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
      decimals: priceParts[1]
    };
  };

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>

      <div className="purchase-footer-info">
        <div className="box">
          <p className="mb-0" style={{ fontWeight: '800' }}>
            {t('total_amount_receipts')}
          </p>

          {totalAmount?.map((amount: any) => {
            const { main, decimals } = formatPrice(
              amount?.total_price_in_amount
            );
            return (
              <div
                key={amount.currency_id}
                className="d-flex justify-content-between gap-2"
              >
                <span>{amount?.currency.name.ru}:</span>
                <span style={{ fontWeight: '800' }}>
                  {main}.{decimals}
                </span>
              </div>
            );
          })}
        </div>

        <div className="box">
          <p className="mb-0" style={{ fontWeight: '800' }}>
            {t('total_sale_amount')}
          </p>
          {totalAmount?.map((amount: any) => {
            const { main, decimals } = formatPrice(amount?.total_price_amount);
            return (
              <div
                key={amount?.currency_id}
                className="d-flex justify-content-between gap-2"
              >
                <span>{amount?.currency.name.ru}:</span>
                <span style={{ fontWeight: '800' }}>
                  {main}.{decimals}
                </span>
              </div>
            );
          })}
        </div>

        <div className="box">
          <p className="mb-0" style={{ fontWeight: '800' }}>
            {t('total_difference')}
          </p>
          {totalAmount?.map((amount: any) => {
            const { main, decimals } = formatPrice(
              amount?.total_difference_price_and_price_in_amount
            );

            return (
              <div
                key={amount?.currency_id}
                className="d-flex justify-content-between gap-2"
              >
                <span>{amount?.currency.name?.[i18n?.language]}:</span>
                <span style={{ fontWeight: '800' }}>
                  {main}.{decimals}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PurchaseByGoodsTable;
