/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';

export type FrozenRemedyData = {
  product_name: string;
  barcode: string;
  current_balance: string;
  frozen_remedy: string;
};

interface FrozenRemedyTableProps {
  tableData?: any;
}

const FrozenRemedyTable = ({ tableData }: FrozenRemedyTableProps) => {
  const { t } = useTranslation();

  const frozenData: FrozenRemedyData[] = useMemo(() => {
    return (
      tableData?.items?.map((items: any) => {
        return {
          product_name: items?.product?.name,
          barcode: items?.product?.barcode,
          current_balance: items?.frozen_quantity ?? 0,
          frozen_remedy: items?.frozen_amount ?? 0
        };
      }) ?? []
    );
  }, [tableData]);

  const frozenRemedyDataTableColumns: ColumnDef<FrozenRemedyData>[] = [
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'current_balance',
      header: t('current_balance'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'frozen_remedy',
      header: t('frozen_remedy'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: frozenData,
    columns: frozenRemedyDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });
  return (
    <AdvanceTableProvider {...table}>
      <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
        <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
        <AdvanceTableFooter pagination />
      </div>
    </AdvanceTableProvider>
  );
};

export default FrozenRemedyTable;
