/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import UAParser from 'ua-parser-js';

import legalEntitySignService from 'service/legalEntitySign';
import activityService from 'service/activity';
import currencyService from 'service/currency';
import Button from 'components/base/Button';
import useAuth from 'service/auth/useAuth';
import countryService from 'service/country';
import { authActions } from 'store/auth/auth.slice';
import { shiftIdActions } from 'store/shiftId/shiftId.slice';

const typeOption = [
  {
    value: 'DEALER',
    label: 'Дилер'
  },
  {
    value: 'STORE',
    label: 'Магазин'
  },
  {
    value: 'BRAND',
    label: 'Бранд'
  }
];

const SwitchControl = ({ field, label }: { field: any; label: any }) => {
  return (
    <Form.Check
      type="switch"
      label={label}
      className="cursor-pointer"
      checked={field.value}
      onChange={e => {
        field.onChange(e.target.checked);
      }}
    />
  );
};

const RegisterSecondForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({});

  const registerData = useSelector((state: any) => state.register);
  const phoneNumber = useSelector((state: any) => state.auth?.phone_number);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [uaResult, setUaResult] = useState<any>(null);

  const [activityOption, setActivityOption] = useState([]);
  const [signOption, setSignOption] = useState([]);
  const [currencyOption, setCurrencyOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);

  useEffect(() => {
    const parser = new UAParser();
    const result = parser.getResult();
    setUaResult(result);
  }, []);

  //GET Activity to options
  useQuery(['GET_ACTIVITY_KIND'], async () => {
    await activityService
      .getListForRegister({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.activity_kinds?.map((activity: any) => ({
          value: activity.id,
          label: activity.name?.ru
        }));
        setActivityOption(options);
      });
  });

  //GET Sign to options
  useQuery(['GET_SIGN'], async () => {
    await legalEntitySignService
      .getListForRegister({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entity_signes?.map((option: any) => ({
          value: option.id,
          label: option.name?.ru
        }));
        setSignOption(options);
      });
  });

  //GET Currency to options
  useQuery(['GET_CURRENCY'], async () => {
    await currencyService
      .getSignCurrency({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.currencies?.map((option: any) => ({
          value: option.id,
          label: option.name?.ru
        }));
        setCurrencyOption(options);
      });
  });

  //GET Country to options
  useQuery(['GET_COUNTRY'], async () => {
    await countryService
      .getListForRegister({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.countries?.map((option: any) => ({
          value: option.id,
          label: option.name?.ru
        }));
        setCountryOption(options);
      });
  });

  const { register } = useAuth({
    registerProps: {
      onSuccess: (value: any) => {
        const states = {
          token: value.data.access_token,
          user: value.data.user,
          legal_entity: value?.data?.legal_entity
        };
        dispatch(authActions.login(states));
        dispatch(
          shiftIdActions.setShiftId(
            value.data.cashbox_id ? value.data.cashbox_id : ''
          )
        );
        navigate('/');
      }
    }
  });

  const onSubmit = async (data: any) => {
    console.log('data', data);

    const deviceModel = uaResult?.device?.model
      ? `_${uaResult.device.model}`
      : '';

    const createdData = {
      legal_entity: {
        activity_kind_id: data?.activity_id,
        address: data?.address,
        country_id: data?.country_id,
        currency_id: data?.currency_id,
        email: data?.email,
        is_vat_payer: data?.is_nds,
        legal_entity_sign_id: data?.legal_entity_sign,
        legal_entity_type: data?.legal_entity_type,
        name: data?.name,
        phone_number: `+${phoneNumber}`,
        location: '12.25'
      },
      user: {
        first_name: registerData?.first_name,
        last_name: registerData?.last_name,
        password: registerData?.password,
        gender: registerData?.gender,
        phone: `+${phoneNumber}`
      },
      user_device: `WEB#${uaResult.browser.name}${deviceModel}`
    };

    try {
      await register.mutateAsync(createdData);
    } catch (error: any) {
      console.log('register next step error', error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="text-center mb-7">
        <h3 className="text-1000">Регистрация</h3>
        <p className="text-700">
          Для начала работы в системе необходима регистрация
        </p>
      </div>

      <div className="react-select-container mb-2">
        <Controller
          name="legal_entity_type"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <Form.Floating>
              <Form.Select {...field} isInvalid={!!errors.legal_entity_type}>
                <option className="d-none" value=""></option>
                {typeOption?.map((option: any) => (
                  <option
                    key={option.value}
                    value={option.value}
                    className="option"
                  >
                    {option.label}
                  </option>
                ))}
              </Form.Select>

              <label htmlFor="floatingInputCustom">Тип Юридического лица</label>
              {errors?.legal_entity_type && (
                <span
                  style={{
                    marginTop: '2px',
                    color: 'red',
                    fontSize: '12px'
                  }}
                >
                  Обязательное поле
                </span>
              )}
            </Form.Floating>
          )}
        />
      </div>

      <div className="react-select-container mb-2">
        <Controller
          name="legal_entity_sign"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <Form.Floating>
              <Form.Select {...field} isInvalid={!!errors.legal_entity_sign}>
                <option className="d-none" value=""></option>
                {signOption?.map((option: any) => (
                  <option
                    key={option.value}
                    value={option.value}
                    className="option"
                  >
                    {option.label}
                  </option>
                ))}
              </Form.Select>

              <label htmlFor="floatingInputCustom">
                Признак Юридического лица
              </label>
              {errors?.legal_entity_sign && (
                <span
                  style={{
                    marginTop: '2px',
                    color: 'red',
                    fontSize: '12px'
                  }}
                >
                  Обязательное поле
                </span>
              )}
            </Form.Floating>
          )}
        />
      </div>

      <div className="react-select-container mb-2">
        <Controller
          name="activity_id"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <Form.Floating>
              <Form.Select {...field} isInvalid={!!errors.activity_id}>
                <option className="d-none" value=""></option>
                {activityOption?.map((option: any) => (
                  <option
                    key={option.value}
                    value={option.value}
                    className="option"
                  >
                    {option.label}
                  </option>
                ))}
              </Form.Select>

              <label htmlFor="floatingInputCustom">Вид деятельности</label>
              {errors?.activity_id && (
                <span
                  style={{
                    marginTop: '2px',
                    color: 'red',
                    fontSize: '12px'
                  }}
                >
                  Обязательное поле
                </span>
              )}
            </Form.Floating>
          )}
        />
      </div>

      <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <div className="form-icon-container">
            <Form.Floating className="mb-2">
              <Form.Control
                {...field}
                type="text"
                placeholder="Наименование"
                onFocus={e => e.target.select()}
                isInvalid={!!errors.name}
              />
              <label htmlFor="firstName">Наименование</label>
              {errors.name && (
                <Form.Control.Feedback type="invalid">
                  Обязательное поле
                </Form.Control.Feedback>
              )}
            </Form.Floating>
          </div>
        )}
      />

      <Controller
        name="email"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <div className="form-icon-container">
            <Form.Floating className="mb-2">
              <Form.Control
                {...field}
                type="email"
                placeholder="Электронная почта"
                onFocus={e => e.target.select()}
                isInvalid={!!errors.email}
              />
              <label htmlFor="firstName">Электронная почта</label>
              {errors.email && (
                <Form.Control.Feedback type="invalid">
                  Обязательное поле
                </Form.Control.Feedback>
              )}
            </Form.Floating>
          </div>
        )}
      />

      <div className="react-select-container mb-2">
        <Controller
          name="country_id"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <Form.Floating>
              <Form.Select {...field} isInvalid={!!errors.country_id}>
                <option className="d-none" value=""></option>
                {countryOption?.map((option: any) => (
                  <option
                    key={option.value}
                    value={option.value}
                    className="option"
                  >
                    {option.label}
                  </option>
                ))}
              </Form.Select>

              <label htmlFor="floatingInputCustom">Страна</label>
              {errors?.country_id && (
                <span
                  style={{
                    marginTop: '2px',
                    color: 'red',
                    fontSize: '12px'
                  }}
                >
                  Обязательное поле
                </span>
              )}
            </Form.Floating>
          )}
        />
      </div>

      <Controller
        name="address"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <div className="form-icon-container">
            <Form.Floating className="mb-2">
              <Form.Control
                {...field}
                type="text"
                placeholder="Адрес"
                onFocus={e => e.target.select()}
                isInvalid={!!errors.address}
              />
              <label htmlFor="firstName">Адрес</label>
              {errors.address && (
                <Form.Control.Feedback type="invalid">
                  Обязательное поле
                </Form.Control.Feedback>
              )}
            </Form.Floating>
          </div>
        )}
      />

      <div className="react-select-container mb-2">
        <Controller
          name="currency_id"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <Form.Floating>
              <Form.Select {...field} isInvalid={!!errors.currency_id}>
                <option className="d-none" value=""></option>
                {currencyOption?.map((option: any) => (
                  <option
                    key={option.value}
                    value={option.value}
                    className="option"
                  >
                    {option.label}
                  </option>
                ))}
              </Form.Select>

              <label htmlFor="floatingInputCustom">Валюта</label>
              {errors?.currency_id && (
                <span
                  style={{
                    marginTop: '2px',
                    color: 'red',
                    fontSize: '12px'
                  }}
                >
                  Обязательное поле
                </span>
              )}
            </Form.Floating>
          )}
        />
      </div>

      <Form.Group className="d-flex align-items-center mb-3">
        <Controller
          name="is_nds"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <SwitchControl field={field} label="Плательщик НДС " />
          )}
        />
      </Form.Group>

      <Button
        style={{ height: '48px', marginTop: 'auto' }}
        className="w-100 mb-3"
        variant="primary"
        type="submit"
      >
        Регистрация
      </Button>
    </Form>
  );
};

export default RegisterSecondForm;
