import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const comingBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'movements',
    url: '/movements/outgoing'
  },
  {
    label: 'incoming',
    active: true
  }
];

export const outgoingBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'movements',
    url: '/movements/outgoing'
  },
  {
    label: 'outgoing',
    active: true
  }
];

export type Coming = {
  orders: string;
  recipient: string;
  sender: string;
  status: string;
  create_date: string;
  guid: string;
};

export type ComingResult = {
  product_name: string;
  barcode: string;
  currency: string;
  units: string;
  sent: string;
  accept: string;
  discrepancy: string;
  status: string;
  guid: string;
  can_update: string;
};

export type OutgoingMovements = {
  orders: string;
  sender: string;
  recipient: string;
  currency: string;
  status: string;
  create_date: string;
  guid: string;
};

export type OutgoingMovementsResult = {
  product_name: string;
  barcode: string;
  currency: string;
  units: string;
  purchase_price: string;
  wholesale_price: string;
  retail_price: string;
  shipment_quantity: string;
  accept: string;
  discrepancy: string;
  discrepancy_amount: string;
  final_price: string;
  total_price: string;
  status: string;
  guid: string;
  can_update: string;
};
