/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { OutgoingMovementsResult } from 'data/movement';
import Button from 'components/base/Button';
import movingItemService from 'service/movingItem';

const OutgoingMovementsResultTable = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const movingId = pathname.split('/').pop();

  const [getRowGuid, setGetRowGuid] = useState('');

  const { data, refetch } = useQuery(
    ['GET_MOVING_ITEM', movingId],
    async () => {
      if (movingId !== '') {
        const res = await movingItemService
          .getMovingItemOutById(movingId, {
            offset: 0,
            limit: 10
          })
          .then((res: any) => {
            return res?.moving_items;
          });
        return res;
      }
    },
    { enabled: !!movingId }
  );

  const outgoingMovingData: OutgoingMovementsResult[] = useMemo(() => {
    const resultData =
      data?.map((items: any) => {
        return {
          guid: items?.id,
          can_update: items?.can_update ?? false,
          product_id: items?.product_id,
          parent_product_id: items?.parent_product_id,
          nomenclature_id: items?.nomenclature_id,
          recipient_id: items?.recipient_id,
          product_name: items?.product?.name,
          purchase_price: items?.price_in ?? 0,
          wholesale_price: items?.price_wholesale ?? 0,
          shipment_quantity: items?.quantity_send ?? 0,
          accept: items?.quantity_fact ?? 0,
          retail_price: items?.price ?? 0,
          discrepancy: items?.quantity_defference ?? 0,
          discrepancy_amount: Math.max(
            (items?.quantity_defference ?? 0) + (items?.price_in ?? 0),
            0
          ),
          barcode: items?.product?.barcode,
          currency: items?.currency?.name?.[i18n?.language],
          units: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          measure_unit_id: items?.nomenclature?.measure_unit?.id,
          amount: items?.quantity ?? 0,
          status: items?.status?.name?.[i18n?.language],
          cost: items?.price ? items?.price : '0',
          cost_change: items?.price_in ? items?.price_in : '0',
          quantity: items?.quantity_send ?? 0,
          /* prettier-ignore */
          final_price:
            items?.quantity_fact && items?.price_in
              ? +items.quantity_fact * +items.price_in
              : items?.quantity_send && items?.price_in
                ? +items.quantity_send * +items.price_in
                : 0,
          /* prettier-ignore */
          total_price:
            items?.quantity_fact && items?.price_wholesale
              ? +items.quantity_fact * +items.price_wholesale
              : items?.price_wholesale && items?.quantity_send
                ? +items.price_wholesale * +items.quantity_send
                : 0
        };
      }) ?? [];

    return resultData;
  }, [data]);

  const movingBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'movements',
      url: '/movements/outgoing'
    },
    {
      label: 'outgoing',
      url: '/movements/outgoing'
    },
    {
      label: 'application_number',
      active: true
    }
  ];

  const item = useMemo(() => {
    return outgoingMovingData.find((item: any) => item.guid === getRowGuid);
  }, [outgoingMovingData, getRowGuid]);

  const handleAcceptProduct = (id: any) => {
    if (id) {
      movingItemService
        .updateMovingItemOut(id, {
          status_id: '00525476-8e64-40bc-9dd2-083583c95b30'
        })
        .finally(() => {
          refetch();
        });
    }
  };

  const outgoingResultTableColumns: ColumnDef<OutgoingMovementsResult>[] = [
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'purchase_price',
      header: t('purchase_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'wholesale_price',
      header: t('wholesale_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'retail_price',
      header: t('short_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'shipment_quantity',
      header: t('short_quantity_shipment'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'accept',
      header: t('short_qunatity_accept'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'discrepancy',
      header: t('short_no_expoenses'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'discrepancy_amount',
      header: t('asmount_discrepancy'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'final_price',
      header: t('final_purchase_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'total_price',
      header: t('final_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'accept_remainder',
      header: t('accept_remainder'),
      cell: rowData => {
        setGetRowGuid(rowData.row.original.guid);
        const item = outgoingMovingData.find(
          (item: any) => item.guid === rowData.row.original.guid
        );

        return (
          <div style={{ display: item?.can_update ? 'block' : 'none' }}>
            <Button
              className="p-2"
              variant="primary"
              onClick={() => handleAcceptProduct(rowData?.row?.original?.guid)}
            >
              {t('accept_remainder')}
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: {
          style: {
            display: item?.can_update ? 'block' : 'none'
          }
        },
        cellProps: {
          className: 'text-900',
          style: {
            display: item?.can_update ? 'block' : 'none'
          }
        }
      }
    }
  ];

  const table = useAdvanceTable({
    data: outgoingMovingData,
    columns: outgoingResultTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const sum = outgoingMovingData.reduce((acc: any, item: any) => {
    const allCost = item?.total_price === undefined ? 0 : +item?.total_price;
    return acc + allCost;
  }, 0);
  return (
    <div>
      <PageBreadcrumb items={movingBreadcrumbItems} />

      <div>
        <h2 className="mb-5">{t('application_number')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>
        <div className="footer-returning">
          <div className="box">
            <p className="mb-0 text-bold">{t('total_amount')}</p>
            <span>{sum ?? 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutgoingMovementsResultTable;
