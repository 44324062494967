/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { useMemo, useState, useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import profitAndLostService from 'service/profitAndLost';
import NestedAdvanceTable from 'components/base/NestedAdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import ProfitAndLostFilter from './ProfitAndLostFilter';

const profitAndLostBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'finance',
    url: '/finance/cashflow'
  },
  {
    label: 'p_and_l',
    active: true
  }
];

export type ColumnType = {
  name: string;
  total_amount?: number;
};

const ProfitAndLost = () => {
  const { t, i18n } = useTranslation();
  const [dateFrom, setDateFrom] = useState<any>(null);
  const [dateTo, setDateTo] = useState<any>(null);
  const [currencyId, setCurrencyId] = useState('');

  useEffect(() => {
    const today = new Date();
    const startOfYear = new Date(today.getFullYear(), 0, 1); // Start of the current year
    setDateFrom(startOfYear.getTime());
    setDateTo(today.getTime());
  }, []);

  const keyTranslationMap: Record<string, string> = {
    gross_profit: t('gross_profit'),
    net_income: t('net_profit'),
    operating_income: t('operating_profit'),
    operation_expense_articles: t('operating_expenses'),
    other_income_articles: t('other_income'),
    other_expense_articles: t('other_expenses'),
    profit_without_tax: t('profit_without_tax'),
    revenue: t('revenue'),
    gross_revenue: t('gross_revenue'),
    net_revenue: t('net_revenue'),
    return: t('return'),
    taxes_articles: t('taxes')
  };

  const translateKey = (key: string): string => keyTranslationMap[key] || key;

  const extractHeaders = (data: any): string[] => {
    const headers = new Set<string>();
    const extract = (obj: any) => {
      if (obj?.amount) {
        Object.keys(obj.amount).forEach(key => headers.add(key));
      }
      Object.values(obj).forEach(value => {
        if (typeof value === 'object' && !Array.isArray(value)) {
          extract(value);
        }
      });
    };
    extract(data);
    return Array.from(headers);
  };

  const ensureZeroForMissingDates = (obj: any, headers: string[]) => {
    headers.forEach(header => {
      if (obj[header] === undefined) {
        obj[header] = 0;
      }
    });
  };

  const transformData = (data: any, headers: string[]): any[] => {
    const removeDuplicateNames = (rows: any[], parentName: string): any[] => {
      const seenNames = new Set<string>();
      return rows.filter(row => {
        if (row.name === parentName || seenNames.has(row.name)) {
          return false;
        }
        seenNames.add(row.name);
        return true;
      });
    };

    const processCategory = (categoryData: any): any[] => {
      const rows: any[] = [];

      for (const key in categoryData) {
        let displayName = translateKey(key);

        if (categoryData[key]?.name?.[i18n?.language]) {
          displayName = categoryData[key].name?.[i18n?.language];
        } else if (typeof categoryData[key] === 'string') {
          displayName = categoryData[key];
        }

        if (key === 'amount' || key === 'total_amount') {
          continue;
        }

        if (
          categoryData[key]?.amount ||
          categoryData[key]?.total_amount !== undefined
        ) {
          const row = {
            name: displayName,
            ...categoryData[key]?.amount,
            total_amount: categoryData[key]?.total_amount ?? 0,
            children: []
          };
          ensureZeroForMissingDates(row, headers);
          rows.push(row);
        } else if (Array.isArray(categoryData[key])) {
          const additionalChildren = categoryData[key]
            .map((item: any) => {
              if (item.name && item.name?.[i18n?.language]) {
                return {
                  name: item.name?.[i18n?.language],
                  ...headers.reduce((acc: any, date) => {
                    acc[date] = item.amount?.[date] ?? 0;
                    return acc;
                  }, {}),
                  total_amount: item.total_amount ?? 0,
                  children: []
                };
              }
              return null;
            })
            .filter((item: any) => item !== null);

          rows.push(...additionalChildren);

          const children = categoryData[key].map((item: any) =>
            processCategory(item)
          );

          rows.push(...children.flat());
        } else if (typeof categoryData[key] === 'object' && key !== 'name') {
          const children = removeDuplicateNames(
            processCategory(categoryData[key]),
            displayName
          );
          if (children.length > 0) {
            const row = {
              name: displayName,
              children: children,
              ...categoryData[key]?.amount,
              total_amount: categoryData[key]?.total_amount ?? 0
            };
            ensureZeroForMissingDates(row, headers);
            rows.push(row);
          } else {
            // Handling empty objects by adding a row with zero values
            const row = {
              name: displayName,
              ...headers.reduce((acc: any, date) => {
                acc[date] = 0;
                return acc;
              }, {}),
              total_amount: 0,
              children: []
            };
            rows.push(row);
          }
        } else if (Object.keys(categoryData[key]).length === 0) {
          const row = {
            name: displayName,
            ...headers.reduce((acc: any, date) => {
              acc[date] = 0;
              return acc;
            }, {}),
            total_amount: 0,
            children: []
          };
          rows.push(row);
        }
      }
      return rows;
    };

    const revenue = processCategory(data.revenue);

    const gross_profit = processCategory(data.gross_profit);

    const net_income = processCategory(data.net_income);

    const operating_income = processCategory(data.operating_income);

    const operation_expense_articles = processCategory(
      data.operation_expense_articles
    );

    const other_income_articles = processCategory(data.other_income_articles);

    const profit_without_tax = processCategory(data.profit_without_tax);

    const taxes_articles = processCategory(data.taxes_articles);

    const other_expense_articles = processCategory(data.other_expense_articles);

    const rootRevenue = {
      name: translateKey('revenue'),
      children: removeDuplicateNames(revenue, translateKey('revenue')),
      ...data.revenue.amount,
      total_amount: data.revenue.total_amount ?? 0
    };
    ensureZeroForMissingDates(rootRevenue, headers);

    const rootGrossProfit = {
      name: translateKey('gross_profit'),
      children: removeDuplicateNames(
        gross_profit,
        translateKey('gross_profit')
      ),
      ...data.gross_profit.amount,
      total_amount: data.gross_profit.total_amount ?? 0
    };
    ensureZeroForMissingDates(rootGrossProfit, headers);

    const rootNetIncome = {
      name: translateKey('net_income'),
      children: removeDuplicateNames(net_income, translateKey('net_income')),
      ...data.net_income.amount,
      total_amount: data.net_income.total_amount ?? 0
    };
    ensureZeroForMissingDates(rootNetIncome, headers);

    const rootOperationIncome = {
      name: translateKey('operating_income'),
      children: removeDuplicateNames(
        operating_income,
        translateKey('operating_income')
      ),
      ...data.operating_income.amount,
      total_amount: data.operating_income.total_amount ?? 0
    };
    ensureZeroForMissingDates(rootOperationIncome, headers);

    const rootOperationExpenseArticles = {
      name: translateKey('operation_expense_articles'),
      children: removeDuplicateNames(
        operation_expense_articles,
        translateKey('operation_expense_articles')
      ),
      ...data.operation_expense_articles.amount,
      total_amount: data.operation_expense_articles.total_amount ?? 0
    };
    ensureZeroForMissingDates(rootOperationExpenseArticles, headers);

    const rootOtherExpenseArticles = {
      name: translateKey('other_expense_articles'),
      children: removeDuplicateNames(
        other_expense_articles,
        translateKey('other_expense_articles')
      ),
      ...data.other_expense_articles.amount,
      total_amount: data.other_expense_articles.total_amount ?? 0
    };
    ensureZeroForMissingDates(rootOtherExpenseArticles, headers);

    const rootOperationIncomeArticles = {
      name: translateKey('other_income_articles'),
      children: removeDuplicateNames(
        other_income_articles,
        translateKey('other_income_articles')
      ),
      ...data.other_income_articles.amount,
      total_amount: data.other_income_articles.total_amount ?? 0
    };
    ensureZeroForMissingDates(rootOperationIncomeArticles, headers);

    const rootProfitWithoutTax = {
      name: translateKey('profit_without_tax'),
      children: removeDuplicateNames(
        profit_without_tax,
        translateKey('profit_without_tax')
      ),
      ...data.profit_without_tax.amount,
      total_amount: data.profit_without_tax.total_amount ?? 0
    };
    ensureZeroForMissingDates(rootProfitWithoutTax, headers);

    const rootTaxesArticles = {
      name: translateKey('taxes_articles'),
      children: removeDuplicateNames(
        taxes_articles,
        translateKey('taxes_articles')
      ),
      ...data.taxes_articles.amount,
      total_amount: data.taxes_articles.total_amount ?? 0
    };
    ensureZeroForMissingDates(rootTaxesArticles, headers);

    return [
      rootRevenue,
      rootGrossProfit,
      rootOperationExpenseArticles,
      rootOperationIncome,
      rootOperationIncomeArticles,
      rootOtherExpenseArticles,
      rootProfitWithoutTax,
      rootTaxesArticles,
      rootNetIncome
    ];
  };

  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? undefined
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000
          : undefined;

  const { data, isLoading, isError, refetch } = useQuery(
    ['GET_PROFIT_LOST'],
    () =>
      profitAndLostService.getList({
        offset: 0,
        limit: 10,
        currency_id: currencyId,
        date_from: dateFrom,
        date_to: adjustedDateTo
      }),
    {
      enabled: !!dateFrom || !!adjustedDateTo || !!currencyId,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    refetch();
  }, [dateFrom, dateTo, currencyId]);

  const headers = useMemo(() => (data ? extractHeaders(data) : []), [data]);
  const tableData = useMemo(
    () => (data ? transformData(data, headers) : []),
    [data, headers, t, i18n?.language]
  );

  const columns: ColumnDef<ColumnType>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('cash_flow_names'),
        meta: { cellProps: { className: 'text-900' } }
      },
      {
        accessorKey: 'total_amount',
        header: t('total_amount'),
        meta: { cellProps: { className: 'text-900' } }
      },
      ...headers.map(header => ({
        accessorKey: header,
        header,
        meta: { cellProps: { className: 'text-900' } }
      }))
    ],
    [headers]
  );

  const table = useAdvanceTable({
    data: tableData,
    columns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  if (isLoading) {
    return <div>Loading...</div>; // Add a loading state
  }

  if (isError || !data) {
    return <div>No data available</div>; // Handle the case where there is no data or an error occurred
  }

  return (
    <div>
      <PageBreadcrumb items={profitAndLostBreadcrumbItems} />

      <h2 className="mb-5">{t('p_and_l')}</h2>

      <ProfitAndLostFilter
        dateFrom={dateFrom}
        setDateFrom={setDateFrom}
        dateTo={dateTo}
        setDateTo={setDateTo}
        setCurrencyId={setCurrencyId}
      />

      <AdvanceTableProvider {...table}>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
          <NestedAdvanceTable
            bodyClassName="bg-white"
            rowClassName="text-nowrap"
            tableProps={{ className: 'phoenix-table fs-9' }}
            hasFooter={false}
            data={tableData}
            columns={columns}
          />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default ProfitAndLost;
