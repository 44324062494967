/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import legalEntityCurrencyService from 'service/legalEntityCurrency';

interface CashFilterPops {
  setDateFrom?: any;
  setDateTo?: any;
  dateFrom?: any;
  dateTo?: any;
  setCurrencyId?: any;
}

const ProfitAndLostFilter = ({
  setDateFrom,
  setDateTo,
  dateFrom,
  dateTo,
  setCurrencyId
}: CashFilterPops) => {
  const { t, i18n } = useTranslation();
  const [legalCurrencyOption, setLegalCurrencyOption] = useState<any>([]);

  const currencyId = useSelector(
    (state: any) => state?.auth?.legal_entity?.currency_id
  );

  const handleStartDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const startDate = new Date(date[0]);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    } else {
      const startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    }
  };

  const handleEndDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const endDateInMillis = date[0]?.getTime();
      setDateTo(endDateInMillis);
    } else {
      const endDateInMillis = date.getTime();
      setDateTo(endDateInMillis);
    }
  };

  //GET LEgal Entity to options
  useQuery(['GET_LEGAL_CURRENCY'], async () => {
    await legalEntityCurrencyService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.legal_entity_currencies?.map((option: any) => ({
          value: option?.currency?.id,
          label: option?.currency?.name?.[i18n?.language]
        }));
        setLegalCurrencyOption(options);
      });
  });

  useEffect(() => {
    if (currencyId) {
      const defaultCurrency = legalCurrencyOption.find(
        (option: any) => option.value === currencyId
      );
      if (defaultCurrency) {
        setCurrencyId(defaultCurrency.value);
      }
    }
  }, [currencyId, legalCurrencyOption, setCurrencyId]);

  return (
    <div className="d-flex gap-2 mb-3">
      <Form.Group className="md-4">
        <div className="react-select-container">
          <Form.Floating>
            <Form.Select
              onChange={(e: any) => {
                const selectedOption = legalCurrencyOption.find(
                  (option: any) => option.value === e.target.value
                );
                if (selectedOption) {
                  setCurrencyId(selectedOption.value);
                }
              }}
            >
              {legalCurrencyOption?.map((option: any) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>

            <label htmlFor="floatingInputCustom">{t('sender')}</label>
          </Form.Floating>
        </div>
      </Form.Group>

      <Form.Group className="md-4">
        <Form.Floating>
          <Flatpickr
            className={classNames('form-control')}
            options={{
              nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
              prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
              locale: {
                firstDayOfWeek: 1
              },
              monthSelectorType: 'static',
              onDayCreate: (...[, , , dayElem]) => {
                if (
                  dayElem.dateObj.getDay() === 5 ||
                  dayElem.dateObj.getDay() === 6
                ) {
                  dayElem.className += ' weekend-days';
                }
              },
              dateFormat: 'M j, Y',
              disableMobile: true
            }}
            value={dateFrom ? new Date(dateFrom) : ''}
            onChange={handleStartDateChange}
            placeholder={t('date_from')}
          />
          <label htmlFor="floatingInputCustom">{t('date_from')}</label>
        </Form.Floating>
      </Form.Group>

      <Form.Group className="md-4">
        <Form.Floating>
          <Flatpickr
            className={classNames('form-control')}
            options={{
              nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
              prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
              locale: {
                firstDayOfWeek: 1
              },
              monthSelectorType: 'static',
              onDayCreate: (...[, , , dayElem]) => {
                if (
                  dayElem.dateObj.getDay() === 5 ||
                  dayElem.dateObj.getDay() === 6
                ) {
                  dayElem.className += ' weekend-days';
                }
              },
              dateFormat: 'M j, Y',
              disableMobile: true
            }}
            value={dateTo ? new Date(dateTo) : ''}
            onChange={handleEndDateChange}
            placeholder={t('date_to')}
          />
          <label htmlFor="floatingInputCustom">{t('date_to')}</label>
        </Form.Floating>
      </Form.Group>
    </div>
  );
};

export default ProfitAndLostFilter;
