/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Modal } from 'react-bootstrap';
import { useState, useMemo, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import SearchProductTable from './SearchProductTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import productSearchService from 'service/productSearch';
import { SearchProductType } from 'data/searchProduct';
import { showAlert } from 'store/alert/alert.thunk';
import AddQuantityToItems from './AddQuantityToItems';

interface SearchProductProps {
  setModalShow: (show: boolean) => void;
  setProductInvoiceData?: any;
  productInvoiceData?: any;
  focusRef?: any;
  isAutoTab?: any;
  saleToMinus?: any;
  lastCurrenciesData?: any;
  invoiceCurrencyId?: any;
  closeSearchTable?: any;
  setCloseSearchTable?: any;
  isForceQuantity?: any;
}

const SearchProduct = ({
  setModalShow,
  setProductInvoiceData,
  productInvoiceData,
  focusRef,
  isAutoTab,
  saleToMinus,
  lastCurrenciesData,
  invoiceCurrencyId,
  closeSearchTable,
  setCloseSearchTable,
  isForceQuantity
}: SearchProductProps) => {
  const { t, i18n } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [globalSearch, setGlobalSearch] = useState(false);
  const [isProduct, setIsProduct] = useState(false);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [addedProduct, setAddedProduct] = useState<SearchProductType>();

  console.log('saleToMinus', saleToMinus);

  const inputRef = useRef<HTMLInputElement | any>();
  const dispatch: Dispatch<any> = useDispatch();

  //Get Product Search
  const { data } = useQuery(
    ['GET_PRODUCT', searchValue],
    () => {
      const res = productSearchService
        .getList({
          offset: 0,
          limit: 100,
          is_global: globalSearch,
          name: searchValue
        })
        .then((res: any) => {
          setIsProduct(res?.is_product ?? false);

          return res?.products;
        });

      return res;
    },
    { enabled: !searchValue || searchValue.length > 2 }
  );

  const searchData: SearchProductType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        const packageQuantity = items?.package_quantity || 1;
        const totalQuantity = String(items?.total_quantity) || '0';

        let integerPartTotal = 0;
        let numeratorTotal = 0;
        let denominatorTotal = 1;

        if (totalQuantity?.includes('.')) {
          [numeratorTotal, denominatorTotal] = totalQuantity
            .split('.')
            .map(Number);
          integerPartTotal = numeratorTotal; // Whole number part
          denominatorTotal = +`0.${denominatorTotal}`; // Remainder of the division
        } else {
          integerPartTotal = +totalQuantity;
        }

        const multipleFraction = Math?.round(
          +denominatorTotal * packageQuantity
        );

        const totalQuantityDisplay = totalQuantity?.includes('.')
          ? `${integerPartTotal}/${multipleFraction}`
          : integerPartTotal;

        return {
          name: isProduct === true ? items?.name : items?.nomenclature?.name,
          variation: items?.nomenclature?.is_variation ? t('yes') : t('no'),
          barcode: items?.nomenclature?.barcode,
          original_barcode: items?.nomenclature?.barcode_original,
          unit: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          currency_id: items?.currency_id,

          // this dates need for set to productInvoiceData
          barcode_user:
            isProduct === true ? items?.barcode : items?.nomenclature?.barcode,
          barcode_original: items?.nomenclature?.barcode_original,
          weght_code: items?.scale_code,
          units: items?.nomenclature?.measure_unit?.name?.ru,
          measure_unit_kind_id: items?.nomenclature?.measure_unit_kind?.id,
          measure_unit_id: items?.nomenclature?.measure_unit_id,
          update_price: items?.update_price ?? false,
          // quantity: saleToMinus ? 0 : 1,
          quantity: 0,
          amount: items?.nomenclature?.package_quantity ?? 1,
          package_amount: items?.price_in,
          cost: items?.price_whosale,
          all_cost: items?.price ?? 0,
          /* prettier-ignore */
          current_balance:
            items?.nomenclature?.measure_unit_id ===
            '443bfff1-61e0-4057-8583-d040dc5a0454'
              ? totalQuantityDisplay
              : items?.total_quantity
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(items?.total_quantity)
                    .replace(/,/g, ' ')
                : 0,
          guid: items?.nomenclature?.id
        };
      }) ?? []
    );
  }, [data, searchValue, globalSearch, t, i18n?.language]);

  const handleCheckItem = (product: SearchProductType) => {
    const isDuplicate = selectedData.some(
      (item: SearchProductType) => item.barcode === product.barcode
    );

    if (!isDuplicate) {
      if (isForceQuantity) {
        setOpenAddProduct(true);
      } else {
        setSelectedData([...selectedData, product]);
        dispatch(
          showAlert({
            title: t('product_successfully_added'),
            type: 'success'
          })
        );

        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, 300);
      }
    } else {
      dispatch(
        showAlert({
          title: t('product_barcode_already_added!'),
          type: 'warning'
        })
      );
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 300);
    }
  };

  const handleRemoveProductItem = (indexToRemove: number) => {
    setSelectedData(
      selectedData.filter((_: any, index: any) => index !== indexToRemove)
    );
  };

  const searchProductTableColumns: ColumnDef<SearchProductType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() => {
              handleCheckItem(rowData.row.original);
              setAddedProduct(rowData.row.original);
            }}
          >
            <FeatherIcon icon="plus" className="cursor-pointer" size={18} />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },
    {
      accessorKey: 'name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'variation',
      header: t('variation'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'original_barcode',
      header: t('barcode_original'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'unit',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'all_cost',
      header: t('selling_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const selectedProductTableColumns: ColumnDef<SearchProductType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() => handleRemoveProductItem(+rowData?.row?.id)}
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={18}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },
    {
      accessorKey: 'name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'variation',
      header: t('variation'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'original_barcode',
      header: t('barcode_original'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'unit',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'quantity',
      header: t('quantity'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'all_cost',
      header: t('selling_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: searchData,
    columns: searchProductTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const selectedTableData = useAdvanceTable({
    data: selectedData,
    columns: selectedProductTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const setSelectedRows = () => {
    const updatedProductInvoiceData = [...productInvoiceData];
    console.log('updatedProductInvoiceData', updatedProductInvoiceData);

    selectedData.forEach((selectedDate: any) => {
      const foundProductIndex = updatedProductInvoiceData.findIndex(
        (item: any) => item.barcode_user === selectedDate.barcode_user
      );

      if (foundProductIndex !== -1) {
        const quantityCheck =
          updatedProductInvoiceData[foundProductIndex].quantity || '0';

        let integerPart = 0;
        let numerator = 0;
        let denominator = 1;

        if (typeof quantityCheck === 'string' && quantityCheck?.includes('/')) {
          // eslint-disable-next-line
          [numerator, denominator] = quantityCheck?.split('/').map(Number);

          integerPart = numerator += selectedDate?.quantity; // Whole number part
          denominator = +denominator; // Remainder of the division
        } else {
          integerPart = +quantityCheck;
        }

        const quantityDisplay =
          typeof quantityCheck === 'string' && quantityCheck?.includes('/')
            ? `${integerPart}/${+denominator}`
            : (integerPart += selectedDate?.quantity);

        // Item already exists, increment quantity and move to the top
        // updatedProductInvoiceData[foundProductIndex].quantity += 1;
        // const [updatedItem] = updatedProductInvoiceData.splice(
        //   foundProductIndex,
        //   1
        // );

        if (
          updatedProductInvoiceData[foundProductIndex]?.measure_unit_kind_id ===
          '3c7cee55-ad41-4ee3-a409-a7bb5f428b36'
        ) {
          updatedProductInvoiceData[foundProductIndex].quantity =
            quantityDisplay;
        } else {
          updatedProductInvoiceData[foundProductIndex].quantity =
            (+updatedProductInvoiceData[foundProductIndex].quantity || 0) +
            selectedDate?.quantity;
        }

        const [updatedItem] = updatedProductInvoiceData.splice(
          foundProductIndex,
          1
        );

        updatedProductInvoiceData.unshift(updatedItem);
      } else {
        // Item does not exist, add it with appropriate currency conversion
        let packageAmount = selectedDate.package_amount;
        let cost = selectedDate.cost;
        let allCost = selectedDate.all_cost;

        if (selectedDate.currency_id === invoiceCurrencyId) {
          // No conversion needed
          packageAmount = selectedDate.package_amount;
          cost = selectedDate.cost;
          allCost = selectedDate.all_cost;
        } else {
          // Apply currency conversion
          if (Array.isArray(lastCurrenciesData)) {
            const currencyData = lastCurrenciesData?.find(
              (currency: any) =>
                currency?.from_currency_id === selectedDate?.currency_id &&
                currency?.to_currency_id === invoiceCurrencyId
            );

            if (currencyData) {
              packageAmount = (
                selectedDate.package_amount * currencyData.to_currency_rate
              ).toFixed(2);
              cost = (
                selectedDate.cost * currencyData.to_currency_rate
              ).toFixed(2);
              allCost = (
                selectedDate.all_cost * currencyData.to_currency_rate
              ).toFixed(2);
            }
          } else {
            console.error(
              'lastCurrenciesData is not iterable',
              lastCurrenciesData
            );
          }
        }

        updatedProductInvoiceData.unshift({
          ...selectedDate,
          package_amount: packageAmount,
          cost: cost,
          all_cost: allCost
        });
      }
    });

    setProductInvoiceData(updatedProductInvoiceData);
    setSelectedData([]);
    setModalShow(false);

    if (isAutoTab) {
      setTimeout(() => {
        const firstProductIndex = 0;
        focusRef(`quantity_${firstProductIndex}`);
      }, 500);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (closeSearchTable) {
      setSelectedRows();
      setCloseSearchTable(false);
    }
  }, [closeSearchTable]);

  return (
    <div>
      <div className="d-flex gap-3 mb-4">
        <div className="form-icon-container">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder={t('enter_product')}
              onFocus={e => e.target.select()}
              onChange={e => setSearchValue(e.target.value)}
              className="form-icon-input"
              autoComplete="off"
              ref={inputRef}
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              {t('enter_product')}
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faSearch}
            className="text-900 fs-9 form-icon"
          />
        </div>

        <Form.Group className="d-flex align-items-center">
          <Form.Check
            type="switch"
            label={t('global_search')}
            className="cursor-pointer"
            checked={globalSearch}
            onChange={() => setGlobalSearch(!globalSearch)}
          />
        </Form.Group>
      </div>
      <AdvanceTableProvider {...table}>
        <SearchProductTable />
      </AdvanceTableProvider>

      <div className="d-flex mt-5 align-items-center justify-content-between border-top pt-2">
        <h4>{t('added_products')}</h4>
        <Button variant="primary" onClick={() => setSelectedRows()}>
          {t('add')}
        </Button>
      </div>
      <AdvanceTableProvider {...selectedTableData}>
        <SearchProductTable />
      </AdvanceTableProvider>

      <Modal
        show={openAddProduct}
        onHide={() => setOpenAddProduct(false)}
        size="sm"
        centered
        contentClassName="border border-300"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('add')} {t('quantity')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddQuantityToItems
            setOpenAddProduct={setOpenAddProduct}
            addedProduct={addedProduct}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            inputRef={inputRef}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SearchProduct;
