/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useEffect } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useState, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { UilWallet } from '@iconscout/react-unicons';
import { useTranslation } from 'react-i18next';

import Unicon from 'components/base/Unicon';
import Button from 'components/base/Button';
import WalletTable from './WalletTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { walletBreadcrumbItems } from 'data/wallet';
import CreateWallet from './CreateWallet';
import { WalletType } from 'data/wallet';
import walletService from 'service/wallet';
import SupperModal from 'components/modals/SupperModal';
import './styles.scss';

const Wallet = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [openWallet, setOpenWallet] = useState(false);
  const [selectedWalletId, setSelectedWalletId] = useState('');
  const [openSupperModal, setOpenSupperModal] = useState(false);
  const [confirmToDelete, setConfirmToDelete] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(
    null
  );

  //Get WAllet
  const { data, refetch } = useQuery(['GET_WALLET'], () => {
    const res = walletService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.wallets);
    return res;
  });

  const walletData: WalletType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.name,
          balance: items?.total_balance
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.total_balance)
                .replace(/,/g, ' ')
            : '0',
          currency: items?.currency?.name?.[i18n?.language],
          currency_id: items?.currency_id,
          status: items?.status === true ? t('active') : t('blocked'),
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const handleDelete = (id: string) => {
    setSelectedInvoiceId(id);
    setOpenSupperModal(true);
  };

  const walletTableColumns: ColumnDef<WalletType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex gap-2">
          <Button
            variant="hover"
            className="p-2"
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              navigate(
                `/directories/wallet/add/${rowData?.row?.original?.guid}`
              );
            }}
          >
            <Unicon icon={UilWallet} className="cursor-pointer" size={20} />
          </Button>
          <Button
            variant="hover"
            className="p-2"
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              setSelectedWalletId(rowData?.row?.original?.guid);
              setOpenWallet(true);
            }}
          >
            <FeatherIcon icon="edit-2" className="cursor-pointer" size={20} />
          </Button>
          {!rowData?.row?.original?.balance && (
            <Button
              variant="hover"
              className="p-2"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                handleDelete(rowData?.row?.original?.guid);
              }}
            >
              <FeatherIcon
                icon="trash-2"
                className="cursor-pointer text-danger"
                size={20}
              />
            </Button>
          )}
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'name',
      header: t('main_name'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'balance',
      header: t('balance'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: walletData,
    columns: walletTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  useEffect(() => {
    if (selectedInvoiceId && confirmToDelete) {
      walletService.deleteWallet(selectedInvoiceId).finally(() => {
        refetch();
        setSelectedWalletId('');
        setOpenSupperModal(false);
        setConfirmToDelete(false);
        setSelectedInvoiceId(null);
      });
    }
  }, [confirmToDelete, selectedInvoiceId, refetch]);
  return (
    <div>
      <PageBreadcrumb items={walletBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">{t('wallet')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex justify-content-between">
              <SearchBox
                placeholder={t('search')}
                onChange={handleSearchInputChange}
              />

              <div className="d-flex justify-content-between gap-2">
                <Button variant="primary" onClick={() => setOpenWallet(true)}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  {t('add')}
                </Button>
                {/* <Col xs="auto">
                    <Button variant="primary">Применить</Button>
                  </Col> */}
              </div>
            </div>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <WalletTable />
          </div>

          <SupperModal
            show={openSupperModal}
            setShow={setOpenSupperModal}
            setConfirm={setConfirmToDelete}
            title={t('wallet')}
            bodyText={t('you_want_delete')}
          />

          <Offcanvas
            show={openWallet}
            onHide={() => {
              setOpenWallet(false);
              setSelectedWalletId('');
            }}
            placement="end"
            backdrop="static"
            keyboard={false}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
                {t('new')}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <CreateWallet
                setOpenWallet={setOpenWallet}
                selectedWalletId={selectedWalletId}
                refetch={refetch}
                setSelectedWalletId={setSelectedWalletId}
              />
            </Offcanvas.Body>
          </Offcanvas>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Wallet;
