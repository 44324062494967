/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useMemo, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import { OutgoingResult } from 'data/outgoing';
import orderItemService from 'service/orderItem';
import orderOutService from 'service/orderOut';
import AcceptanceOfApplication from './AcceptanceOfApplication';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import orderService from 'service/order';
import './styles.scss';

const OutgoingResultTable = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const orderId = pathname.split('/').pop();
  const navigate = useNavigate();

  const [getRowGuid, setGetRowGuid] = useState('');
  const [canUpdate, setCanUpdate] = useState(false);
  const [finishLoading, setFinishLoading] = useState(false);
  const [changedOrderItems, setChangedOrderItems] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);

  //Fetch Users By ID
  const { refetch: refetchOut } = useQuery(
    ['GET_ORDER_IN_CAN_UPDATE', orderId],
    async () => {
      if (orderId) {
        await orderOutService
          .getList({
            offset: 0,
            limit: 10
          })
          .then((res: any) => {
            if (res) {
              const updatedRes = res?.orders?.find(
                (el: any) => el?.id === orderId
              );
              setCanUpdate(updatedRes?.can_update ?? false);
            }
          });
      }
    },
    { enabled: !!orderId }
  );

  console.log('canUpdate', canUpdate);

  const { data, refetch } = useQuery(
    ['GET_ORDER_ITEM', orderId],
    () => {
      if (orderId !== '') {
        const res = orderItemService
          .getOrderItemOutById(orderId, {
            offset: 0,
            limit: 10
          })
          .then((res: any) => {
            return res?.order_items;
          });
        return res;
      }
    },
    { enabled: !!orderId }
  );

  const orderBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'applications',
      url: '/applications/outgoing'
    },
    {
      label: 'outgoing',
      url: '/applications/outgoing'
    },
    {
      label: 'application_number',
      active: true
    }
  ];

  const item = useMemo(() => {
    return changedOrderItems.find((item: any) => item.guid === getRowGuid);
  }, [changedOrderItems, getRowGuid]);

  const outgoingResultData: OutgoingResult[] = useMemo(() => {
    const resultData =
      data?.map((items: any) => {
        return {
          guid: items?.id,
          can_update_order_item: items?.can_update,
          product_id: items?.product_id,
          product_parent_id: items?.product_parent_id,
          nomenclature_id: items?.nomenclature_id,
          recipient_id: items?.recipient_id,
          product_name: items?.nomenclature?.name,
          barcode: items?.nomenclature?.barcode,
          units: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          measure_unit_id: items?.nomenclature?.measure_unit?.id,
          amount: items?.quantity_asked,
          status: items?.status?.name?.[i18n?.language],
          cost: items?.price_wholesale ? items?.price_wholesale : '0',
          quantity_asked: items?.quantity_asked ?? 0,
          quantity_fact: items?.quantity_send ?? 0,
          quantity: items?.quantity_send ?? 0,
          quantity_accept: items?.quantity_fact ?? 0,
          discrepancies: items?.quantity_difference ?? 0,
          price_in: items?.price_in ?? 0,
          price: items?.price ?? 0,
          price_wholesale: items?.price_wholesale ?? 0,
          /* prettier-ignore */
          amount_discrepancies:
            items?.quantity_difference && items?.price_wholesale
              ? +items.quantity_difference * +items.price_wholesale
              : 0,
          /* prettier-ignore */
          history:
            items?.quantity_fact && items?.price_in
              ? +items.quantity_fact * +items.price_in
              : items?.quantity_asked && items?.price_in
                ? +items.quantity_asked * +items.price_in
                : 0,
          /* prettier-ignore */
          total_price:
            items?.quantity_fact && items?.price_wholesale
              ? +items.quantity_fact * +items.price_wholesale
              : items?.price_wholesale && items?.quantity_asked
                ? +items.price_wholesale * +items.quantity_asked
                : 0
        };
      }) ?? [];

    if (orderId) {
      setChangedOrderItems(resultData);
    }

    return resultData;
  }, [data, orderId, t, i18n]);

  const handleAcceptProduct = (id: any) => {
    if (id) {
      orderItemService
        .updateOrderItemOut(id, {
          status_id: '6e22cd85-070e-4779-afcb-8f0be603b60e'
        })
        .finally(() => {
          refetch();
          refetchOut();
        });
    }
  };

  console.log('outgoingResultData', outgoingResultData);

  const outgoingResultTableColumns: ColumnDef<OutgoingResult>[] = [
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'cost',
      header: t('wholesale_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'amount',
      header: t('application_quantity'),
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '0';

        const [value, setValue] = useState(amount);

        const updateProductInvoiceData = (
          name: string,
          value: string,
          index: any
        ) => {
          const updatedItems = changedOrderItems?.map((item: any, i: any) => {
            if (i === +index) {
              return { ...item, [name]: +value };
            }
            return item;
          });
          setChangedOrderItems(updatedItems);
        };

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name, value } = e.target;
          updateProductInvoiceData(name, value, index);
        };

        return (
          <div>
            {canUpdate ? (
              <Form.Group>
                <Form.Control
                  type="text"
                  style={{ width: '50%' }}
                  name="amount"
                  value={value}
                  onChange={e => setValue(e.target.value)}
                  onBlur={(e: any) => onBlur(e, rowData.row?.id)}
                  onFocus={e => e.target.select()}
                />
              </Form.Group>
            ) : (
              amount
            )}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'quantity_fact',
      header: t('short_quantity_shipment'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'quantity_accept',
      header: t('short_qunatity_accept'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'discrepancies',
      header: t('short_no_expoenses'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'amount_discrepancies',
      header: t('asmount_discrepancy'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'history',
      header: t('final_purchase_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'total_price',
      header: t('final_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'status',
      header: t('status'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'accept_remainder',
      header: t('accept_remainder'),
      cell: rowData => {
        setGetRowGuid(rowData.row.original.guid);
        const item = changedOrderItems.find(
          (item: any) => item.guid === rowData.row.original.guid
        );

        return (
          <div
            style={{ display: item?.can_update_order_item ? 'block' : 'none' }}
          >
            <Button
              className="p-2"
              variant="primary"
              onClick={() => handleAcceptProduct(rowData?.row?.original?.guid)}
            >
              Принять в остаток
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: {
          style: { display: item?.can_update_order_item ? 'block' : 'none' }
        },
        cellProps: {
          className: 'text-900',
          style: { display: item?.can_update_order_item ? 'block' : 'none' }
        }
      }
    }
  ];

  const table = useAdvanceTable({
    data: changedOrderItems,
    columns: outgoingResultTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const sum = changedOrderItems.reduce((acc: any, item: any) => {
    const allCost = item?.total_price === undefined ? 0 : +item.total_price;
    return acc + allCost;
  }, 0);

  const handleFinish = () => {
    const createData = changedOrderItems?.map((el: any) => ({
      barcode: el?.barcode,
      name: el?.product_name,
      quantity_asked: el?.quantity_asked,
      quantity_send: el?.quantity_fact,
      currency_id: el?.currency_id,
      measure_unit_id: el?.measure_unit_id,
      nomenclature_id: el?.nomenclature_id,
      product_id: el?.product_id,
      product_parent_id: el?.product_parent_id,
      price: el?.price,
      price_in: el?.price_in,
      price_wholesale: el?.price_wholesale,
      status_id: '4d22cd85-070e-4779-afcb-8f0be603b60d'
    }));

    const updateData = {
      order_id: orderId,
      order_items: createData
    };

    if (orderId !== '') {
      setFinishLoading(true);
      orderService.getOrderById(orderId).then((res: any) => {
        if (res) {
          orderOutService
            ?.updateOrderOut(orderId, {
              ...res,
              status_id: '4d22cd85-070e-4779-afcb-8f0be603b60d'
            })
            .then((inresponse: any) => {
              if (inresponse) {
                orderItemService
                  .createOrderItem(updateData)
                  .then((createres: any) => {
                    if (createres) {
                      navigate('/applications/outgoing');
                    }
                  });
              }
            });
        }
      });
    }
  };

  return (
    <div>
      <PageBreadcrumb items={orderBreadcrumbItems} />

      <div>
        <h2 className="mb-5">{t('application_number')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          </div>
        </AdvanceTableProvider>
        <div className="footer-app-out">
          <div className="box">
            <p className="mb-0 text-bold">{t('total_amount')}:</p>
            <span>{sum ?? 0}</span>
          </div>

          <div className="main-btn-group">
            <Button
              disabled={orderId === '' || !canUpdate}
              variant="primary"
              onClick={() => setOpenModal(true)}
            >
              {t('accept')}
            </Button>
            <Button
              disabled={orderId === '' || !canUpdate}
              variant="danger"
              loading={finishLoading}
              onClick={() => handleFinish()}
            >
              {t('reject')}
            </Button>
          </div>
        </div>
      </div>

      <Modal
        show={openModal}
        onHide={() => setOpenModal(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('acceptance_application')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AcceptanceOfApplication
            changedOrderItems={changedOrderItems}
            setChangedOrderItems={setChangedOrderItems}
            orderId={orderId}
            setOpenModal={setOpenModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OutgoingResultTable;
