import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const invoceBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'purchase',
    url: '/purchase/create'
  },
  {
    label: 'purchase_invoice',
    url: '/purchase/create'
  },
  {
    label: 'new',
    active: true
  }
];

export type InvoiceType = {
  name: string;
  variation: string;
  barcode_original: string;
  barcode_user: string;
  weght_code: string;
  units: string;
  current_balance: string;
  quantity: string;
  amount: string;
  package_amount: string;
  cost: string;
  measure_unit_kind_id: string;
  all_cost: string;
  is_product: boolean;
  guid: string;
};
