/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import {
  useForm,
  Controller,
  FieldValues,
  SubmitHandler
} from 'react-hook-form';
import { useQuery } from 'react-query';
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import Flatpickr from 'react-flatpickr';
import { UilCalendarAlt } from '@iconscout/react-unicons';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { UzbekLatin } from 'flatpickr/dist/l10n/uz_latn.js';

import Button from 'components/base/Button';
import analyticRevaluationService from 'service/analyticRevaluation';
import legalEntityService from 'service/legalEntity';
import legalEntityCurrencyService from 'service/legalEntityCurrency';
import unitService from 'service/units';

interface AccordionCreateFilterProps {
  setGeneratedTableData?: any;
  savedValues?: any;
  setSavedValues?: any;
  setOpenModal: (show: boolean) => void;
}

const AccordionCreateFilter = ({
  setGeneratedTableData,
  savedValues,
  setSavedValues,
  setOpenModal
}: AccordionCreateFilterProps) => {
  const { t, i18n } = useTranslation();
  const { control, handleSubmit, reset, setValue, watch } = useForm();

  const [legalEntityOption, setLegalEntityOption] = useState<any>([]);
  const [currencyOption, setCurrencyOption] = useState<any>([]);
  const [unitsOption, setUnitsOption] = useState<any>([]);
  const [dateFrom, setDateFrom] = useState<any>(null);
  const [dateTo, setDateTo] = useState<any>(null);

  const handleStartDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const startDate = new Date(date[0]);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    } else {
      const startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    }
  };

  const handleEndDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const endDateInMillis = date[0]?.getTime();
      setDateTo(endDateInMillis);
    } else {
      const endDateInMillis = date.getTime();
      setDateTo(endDateInMillis);
    }
  };

  //Get by id
  const fetchData = () => {
    if (savedValues === '') return false;

    savedValues?.map((el: any) => {
      const computed = {
        provider: el?.supplier_id,
        currency: el?.currency_id,
        unit: el?.measure_unit_id
      };
      reset(computed);
      setDateFrom(el?.date_from);
      setDateTo(el?.date_to);
    });
  };

  //GET LEgal Entity to options
  useQuery(['GET_LEGAL_ENTITY'], async () => {
    await legalEntityService
      .getLegalEntityOption({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entities?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));
        setLegalEntityOption(options);
      });
  });

  //GET UNITS to options
  useQuery(['GET_UNITS'], async () => {
    await unitService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.measure_units?.map((option: any) => ({
        value: option?.id,
        label: option?.name?.[i18n?.language]
      }));
      setUnitsOption(options);
    });
  });

  //GET Currency Entity to options
  useQuery(['GET_CURRENCY'], async () => {
    await legalEntityCurrencyService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.legal_entity_currencies?.map((option: any) => ({
          value: option?.currency?.id,
          label: option?.currency?.name?.[i18n?.language]
        }));
        setCurrencyOption(options);
      });
  });

  const onSubmit: SubmitHandler<FieldValues> = data => {
    /* prettier-ignore */
    const adjustedDateTo =
      !dateFrom && !dateTo
        ? undefined
        : dateTo === dateFrom
          ? dateFrom + 86400000
          : dateTo
            ? dateTo + 86400000
            : undefined;

    analyticRevaluationService
      .getInvoiceItemInById({
        offset: 0,
        limit: 100,
        // prettier-ignore
        date_from: dateFrom ? dateFrom : undefined,
        date_to: adjustedDateTo,
        supplier_id: data?.provider ? data?.provider : undefined,
        currency_id: data?.currency ? data?.currency : undefined,
        measure_unit_id: data?.unit ? data?.unit : undefined
      })
      .then((res: any) => {
        if (res) {
          const resData =
            res?.items?.map((items: any) => {
              return {
                product_name: items?.name,
                barcode: items?.barcode,
                currency: items?.currency?.name?.[i18n?.language],
                units: items?.measure_unit?.name?.[i18n?.language],
                old_purchase_price: items?.old_price_in ?? 0,
                quantity: items?.quantity ?? 0,
                new_purchase_price: items?.new_price_in ?? 0,
                revaluation_amount: items?.amount ?? 0
              };
            }) ?? [];

          if (resData) {
            setGeneratedTableData(resData);
            setSavedValues([
              {
                date_from: dateFrom ? dateFrom : undefined,
                date_to: dateTo ? dateTo : undefined,
                supplier_id: data?.provider ? data?.provider : undefined,
                measure_unit_id: data?.unit ? data?.unit : undefined,
                currency_id: data?.currency ? data?.currency : undefined,
                warehouse_id: data?.stock ? data?.stock : undefined
              }
            ]);
            setOpenModal(false);
          }
        }
      });
  };

  const handleClear = () => {
    analyticRevaluationService
      .getInvoiceItemInById({
        offset: 0,
        limit: 100
      })
      .then((res: any) => {
        if (res) {
          const resData =
            res?.items?.map((items: any) => {
              return {
                product_name: items?.name,
                barcode: items?.barcode,
                currency: items?.currency?.name?.[i18n?.language],
                units: items?.measure_unit?.name?.[i18n?.language],
                old_purchase_price: items?.old_price_in ?? 0,
                quantity: items?.quantity ?? 0,
                new_purchase_price: items?.new_price_in ?? 0,
                revaluation_amount: items?.amount ?? 0
              };
            }) ?? [];

          if (resData) {
            setGeneratedTableData(resData);
            reset({
              provider: '',
              currency: '',
              unit: ''
            });
            setDateFrom(null);
            setDateTo(null);
            setSavedValues([]);
            setOpenModal(false);
          }
        }
      });
  };

  const getFlatpickrLocale = () => {
    switch (i18n.language) {
      case 'ru':
        return Russian;
      case 'uz':
        return UzbekLatin;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (savedValues.length > 0) {
      fetchData();
    }
  }, [savedValues]);

  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          gap: '10px'
        }}
      >
        <Form.Group>
          <div className="react-select-container position-relative">
            <Controller
              name="provider"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select {...field}>
                    <option className="d-none" value=""></option>
                    {legalEntityOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">{t('supplier')}</label>
                  {watch('provider') !== '' && (
                    <Button
                      variant="link"
                      style={{
                        position: 'absolute',
                        right: '5%',
                        top: '15%',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setValue('provider', '');
                      }}
                    >
                      <FeatherIcon icon="delete" size={16} />
                    </Button>
                  )}
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group>
          <div className="react-select-container position-relative">
            <Controller
              name="unit"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select {...field}>
                    <option className="d-none" value=""></option>
                    {unitsOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">
                    {t('measure_unit')}
                  </label>
                  {watch('unit') !== '' && (
                    <Button
                      variant="link"
                      style={{
                        position: 'absolute',
                        right: '5%',
                        top: '15%',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setValue('unit', '');
                      }}
                    >
                      <FeatherIcon icon="delete" size={16} />
                    </Button>
                  )}
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group>
          <div className="react-select-container position-relative">
            <Controller
              name="currency"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select {...field}>
                    <option className="d-none" value=""></option>
                    {currencyOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">{t('currency')}</label>
                  {watch('currency') !== '' && (
                    <Button
                      variant="link"
                      style={{
                        position: 'absolute',
                        right: '5%',
                        top: '15%',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setValue('currency', '');
                      }}
                    >
                      <FeatherIcon icon="delete" size={16} />
                    </Button>
                  )}
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group className="flatpickr-input-container">
          <Form.Floating>
            <Flatpickr
              className={classNames('form-control')}
              options={{
                nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                locale: getFlatpickrLocale(),
                monthSelectorType: 'static',
                onDayCreate: (...[, , , dayElem]) => {
                  if (
                    dayElem.dateObj.getDay() === 5 ||
                    dayElem.dateObj.getDay() === 6
                  ) {
                    dayElem.className += ' weekend-days';
                  }
                },
                dateFormat: 'M j, Y',
                disableMobile: true
              }}
              value={dateFrom}
              onChange={handleStartDateChange}
              placeholder={t('date_from')}
            />
            <label htmlFor="startDatepicker" className="ps-6">
              {t('date_from')}
            </label>
          </Form.Floating>
          <UilCalendarAlt className="flatpickr-icon text-700" size={16} />
        </Form.Group>

        <Form.Group className="flatpickr-input-container">
          <Form.Floating>
            <Flatpickr
              className={classNames('form-control')}
              options={{
                nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                locale: getFlatpickrLocale(),
                monthSelectorType: 'static',
                onDayCreate: (...[, , , dayElem]) => {
                  if (
                    dayElem.dateObj.getDay() === 5 ||
                    dayElem.dateObj.getDay() === 6
                  ) {
                    dayElem.className += ' weekend-days';
                  }
                },
                dateFormat: 'M j, Y',
                disableMobile: true
              }}
              value={dateTo}
              onChange={handleEndDateChange}
              placeholder={t('date_to')}
            />
            <label htmlFor="startDatepicker" className="ps-6">
              {t('date_to')}
            </label>
          </Form.Floating>
          <UilCalendarAlt className="flatpickr-icon text-700" size={16} />
        </Form.Group>

        <Form.Group style={{ display: 'flex', gap: '10px', marginTop: 'auto' }}>
          <Button
            style={{ height: '48px', width: '100%' }}
            variant="outline-primary"
            type="button"
            onClick={() => handleClear()}
          >
            {t('reset')}
          </Button>
          <Button
            style={{ height: '48px', width: '100%' }}
            variant="primary"
            type="submit"
          >
            {t('apply')}
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default AccordionCreateFilter;
