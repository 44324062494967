/* eslint-disable @typescript-eslint/no-explicit-any */
import CountUp from 'react-countup';
import { LegacyRef } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import moneyIcon from 'assets/img/icons/money-icon.svg';
import cashIcon from 'assets/img/icons/cash-icon.svg';
import dashboardService from 'service/dashboard';
import styles from './styles.module.scss';

interface ProfitAndCostInfoProps {
  selectedCurrencyId?: string;
  dateFrom?: any;
  dateTo?: any;
}

const ProfitAndCostInfo = ({
  selectedCurrencyId,
  dateFrom,
  dateTo
}: ProfitAndCostInfoProps) => {
  const { t } = useTranslation();

  //Get CASHFLOW
  const { data } = useQuery(
    ['GET_CASHFLOW', dateFrom, dateTo, selectedCurrencyId],
    () => {
      if (selectedCurrencyId && dateFrom) {
        const res = dashboardService
          .getCashflow({
            offset: 0,
            limit: 10,
            date_from: dateFrom,
            date_to: dateTo,
            currency_id: selectedCurrencyId
          })
          .then((res: any) => res);
        return res;
      }
    },
    { enabled: Boolean(dateFrom && selectedCurrencyId) }
  );

  return (
    <div className={styles.infoConteiner}>
      <div className={styles.box}>
        <div className={styles.boxInfo}>
          <p className={styles.boxTitle}>{t('gross_profit')}</p>
          <CountUp
            end={data?.gross_profit_amount ?? 0}
            duration={2.75}
            suffix={
              selectedCurrencyId === 'd69b53bd-da93-4db6-9108-c31210ca5c8f'
                ? ` ${t('sum')}`
                : ' $'
            }
            separator=" "
            delay={0}
          >
            {({ countUpRef }) => (
              <div>
                <span
                  className={styles.boxCount}
                  ref={countUpRef as LegacyRef<HTMLHeadingElement>}
                ></span>
              </div>
            )}
          </CountUp>
        </div>
        <img src={moneyIcon} alt="icon" />
      </div>

      <div className={styles.box}>
        <div className={styles.boxInfo}>
          <p className={styles.boxTitle}>{t('cost')}</p>
          <CountUp
            end={data?.expense_amount ?? 0}
            duration={2.75}
            suffix={
              selectedCurrencyId === 'd69b53bd-da93-4db6-9108-c31210ca5c8f'
                ? ` ${t('sum')}`
                : ' $'
            }
            separator=" "
            delay={0}
          >
            {({ countUpRef }) => (
              <div>
                <span
                  className={styles.boxCount}
                  ref={countUpRef as LegacyRef<HTMLHeadingElement>}
                ></span>
              </div>
            )}
          </CountUp>
        </div>
        <img src={cashIcon} alt="icon" />
      </div>
    </div>
  );
};

export default ProfitAndCostInfo;
