/* eslint-disable @typescript-eslint/no-explicit-any */
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';

import SearchBox from 'components/common/SearchBox';
import Button from 'components/base/Button';
import './styles.scss';

interface PriceListFilterProps {
  searchInputValue?: any;
  setSearchInputValue?: any;
}

const PriceListFilter = ({
  searchInputValue,
  setSearchInputValue
}: PriceListFilterProps) => {
  const { t } = useTranslation();

  return (
    <div className="position-relative">
      <SearchBox
        placeholder={t('search')}
        value={searchInputValue}
        onChange={({ target }) => {
          setSearchInputValue(target.value);
        }}
      />
      {searchInputValue !== '' && (
        <Button
          variant="link"
          style={{
            position: 'absolute',
            left: '22%',
            top: '12%',
            cursor: 'pointer'
          }}
          onClick={() => {
            setSearchInputValue('');
          }}
        >
          <FeatherIcon icon="delete" size={16} />
        </Button>
      )}
    </div>
  );
};

export default PriceListFilter;
