/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';

import './component.scss';

interface ComponentToPrintProps {
  data?: any;
  selectedDateClose?: any;
  selectedDateOpen?: any;
}

const ComponentToPrint = ({
  data,
  selectedDateOpen,
  selectedDateClose
}: ComponentToPrintProps) => {
  const { t } = useTranslation();

  return (
    <div className="print-container">
      <p className="p-0 text-center text-bold">{t('duplicate')}</p>
      <div className="cash-check">
        <ul className="check-list">
          <li>
            {t('availability')} ({t('payment')})
          </li>
          <li>
            {t('availability')} ({t('settlement_with_contact')})
          </li>
          <li>
            {t('plastic_card')} ({t('payment')})
          </li>
          <li>
            {t('sale_on_credit')} ({t('payment')})
          </li>
          <li>
            {t('cost')} ({t('payment')})
          </li>
          <li style={{ fontWeight: '700' }}>{t('discount')}</li>
          <li style={{ fontWeight: '700' }}>{t('total_sale_amount')}</li>
          <li style={{ fontWeight: '700' }}>{t('cash_balance_in_cash')}</li>
          <li style={{ fontWeight: '700' }}>{t('opening_date')}</li>
          <li style={{ fontWeight: '700' }}>{t('opening_date')}</li>
        </ul>

        <ul className="check-cost">
          <li>
            {data?.x_sale_report?.payment_types?.[0]?.amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(data?.x_sale_report?.payment_types?.[0]?.amount)
                  .replace(/,/g, ' ')
              : 0}
          </li>
          <li>
            {data?.x_income_report?.other_income?.payment_types?.[0]?.amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(
                    data?.x_income_report?.other_income?.payment_types?.[0]
                      ?.amount
                  )
                  .replace(/,/g, ' ')
              : 0}
          </li>
          <li>
            {data?.x_sale_report?.payment_types?.[1]?.amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(data?.x_sale_report?.payment_types?.[1]?.amount)
                  .replace(/,/g, ' ')
              : 0}
          </li>
          <li>
            {data?.x_sale_report?.payment_types?.[2]?.amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(data?.x_sale_report?.payment_types?.[2]?.amount)
                  .replace(/,/g, ' ')
              : 0}
          </li>
          <li>
            {data?.x_other_report?.expense_amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(data?.x_other_report?.expense_amount)
                  .replace(/,/g, ' ')
              : 0}
          </li>
          <li>
            {data?.x_other_report?.discount_amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(data?.x_other_report?.discount_amount)
                  .replace(/,/g, ' ')
              : 0}
          </li>
          <li>
            {data?.x_sale_report?.amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(data?.x_sale_report?.amount)
                  .replace(/,/g, ' ')
              : 0}
          </li>
          <li>
            {data?.cash_amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(data?.cash_amount)
                  .replace(/,/g, ' ')
              : 0}
          </li>
          <li>{selectedDateOpen ?? 0}</li>
          <li>{selectedDateClose ?? 0}</li>
        </ul>
      </div>
    </div>
  );
};

export default ComponentToPrint;
