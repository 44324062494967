/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import countryService from 'service/country';
import legalEntityCurrencyService from 'service/legalEntityCurrency';
import { showAlert } from 'store/alert/alert.thunk';

interface CreateCurrencyProps {
  setOpenCurrency: (show: boolean) => void;
  refetch?: any;
  selectedCurrencyId?: any;
  setSelectedCurrencyId?: any;
}

const CreateCurrency = ({
  setOpenCurrency,
  refetch,
  selectedCurrencyId,
  setSelectedCurrencyId
}: CreateCurrencyProps) => {
  const { t, i18n } = useTranslation();

  const options = [
    {
      value: true,
      label: t('active')
    },
    {
      value: false,
      label: t('blocked')
    }
  ];

  const mainOption = [
    {
      value: true,
      label: t('yes')
    },
    {
      value: false,
      label: t('no')
    }
  ];
  const dispatch: Dispatch<any> = useDispatch();
  const [loading, setLoading] = useState(false);
  const [countryOption, setCountryOption] = useState([]);

  const { handleSubmit, control, reset } = useForm();
  const hasFetchedData = useRef(false);
  const inputRef = useRef<HTMLInputElement | any>();

  //GET Country to options
  useQuery(['GET_COUNTRY'], async () => {
    await countryService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.countries?.map((country: any) => ({
        value: country.id,
        label: country.name?.[i18n?.language]
      }));
      setCountryOption(options);
    });
  });

  //fetch with id
  const fetchData = () => {
    if (selectedCurrencyId === '') return setLoading(false);

    legalEntityCurrencyService
      .getById(selectedCurrencyId)
      .then((res: any) => {
        const computed = {
          currency_id: res?.currency_id,

          main_currency: res?.is_main ? res?.is_main : false,
          status: res?.status ? res?.status : false
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedCurrencyId !== '') return update(data);
  };

  const update = (data: any) => {
    setLoading(true);

    const updateData = {
      currency_id: data?.currency_id,
      /* prettier-ignore */
      is_main:
      data?.main_currency === 'true' || data?.main_currency === true
          ? true
          : data?.main_currency === 'false' || data?.main_currency === false
            ? false
            : false,
      // prettier-ignore
      status:
        data?.status === 'true' || data?.status === true
          ? true
          : data?.status === 'false' || data?.status === false
            ? false
            : false,
      id: selectedCurrencyId
    };
    legalEntityCurrencyService
      .update(selectedCurrencyId, updateData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: `${t('currency')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
          refetch();
          setLoading(false);
          setOpenCurrency(false);
          setSelectedCurrencyId('');
        }
      });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (selectedCurrencyId !== '' && !hasFetchedData.current) {
      fetchData();
      hasFetchedData.current = true;
    }
  }, [selectedCurrencyId, countryOption]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-3">
          <Col>
            <div className="react-select-container">
              <Controller
                name="main_currency"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} ref={inputRef}>
                      <option className="d-none" value=""></option>
                      {mainOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('main_currency')}
                    </label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {options?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('status')}</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
        >
          {t('add')}
        </Button>
      </Form>
    </>
  );
};

export default CreateCurrency;
