import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const comingBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'returns',
    url: '/returning/outgoing'
  },
  {
    label: 'incoming',
    active: true
  }
];

export const outgoingBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'returns',
    url: '/returning/outgoing'
  },
  {
    label: 'outgoing',
    active: true
  }
];

export type Coming = {
  orders: string;
  recipient: string;
  sender: string;
  status: string;
  create_date: string;
};

export const coming: Coming[] = [
  {
    orders: '15',
    recipient: 'Dealer Free',
    sender: 'test',
    status: 'Новый',
    create_date: '21.02.2024'
  },
  {
    orders: '15',
    recipient: 'Dealer Free',
    sender: 'test',
    status: 'Новый',
    create_date: '21.02.2024'
  },
  {
    orders: '15',
    recipient: 'Dealer Free',
    sender: 'test',
    status: 'Отправлен',
    create_date: '21.02.2024'
  },
  {
    orders: '15',
    recipient: 'Dealer Free',
    sender: 'test',
    status: 'Завершен',
    create_date: '21.02.2024'
  }
];

export type ComingResult = {
  product_name: string;
  barcode: string;
  currency: string;
  units: string;
  cost: string;
  amount: string;
  history: string;
  status: string;
};

export const comingResult: ComingResult[] = [
  {
    product_name: 'Coca Cola',
    barcode: '54491472',
    currency: "O'zbek so'mi",
    units: 'Shtuka',
    cost: '12 000',
    amount: '10',
    history: '110 000',
    status: 'Otpravlen'
  }
];

export type OutgoingReturning = {
  orders: string;
  sender: string;
  recipient: string;
  currency: string;
  status: string;
  create_date: string;
  guid: string;
};

export type OutgoingReturningResult = {
  product_name: string;
  barcode: string;
  currency: string;
  units: string;
  cost: string;
  cost_change: string;
  amount: string;
  total_price: string;
  final_price: string;
  status: string;
};

export const outgoingReturningResult: OutgoingReturningResult[] = [
  {
    product_name: 'Coca Cola',
    barcode: '54491472',
    currency: "O'zbek so'mi",
    units: 'Shtuka',
    cost: '12 000',
    cost_change: '10 000',
    amount: '10',
    total_price: '100 000',
    final_price: '1 000 000',
    status: 'Otpravlen'
  }
];
