/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import productSearchService from 'service/productSearch';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';

interface SearchBonusProps {
  setOpenSearch: (show: boolean) => void;
  reset?: any;
  setSearchedValue?: any;
  setFocusToInput?: any;
}

export type SearchData = {
  product_name: string;
  variation: string;
  barcode: string;
  barcode_orignal: string;
  units: string;
  nomenclature_id: string;
};

const SearchBonus = ({
  setOpenSearch,
  reset,
  setSearchedValue,
  setFocusToInput
}: SearchBonusProps) => {
  const { t, i18n } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [isProduct, setIsProduct] = useState(false);

  const { data } = useQuery(
    ['GET_PRODUCTS', searchValue],
    async () => {
      const res = await productSearchService
        .getListProduct({ offset: 0, limit: 10, name: searchValue })
        .then((res: any) => {
          setIsProduct(res?.is_product ?? false);
          return res?.products;
        });

      return res;
    },
    {
      enabled: searchValue.length === 0 || searchValue.length > 2
    }
  );

  const searchData: SearchData[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          product_name:
            isProduct === true ? items?.name : items?.nomenclature?.name,
          variation: items?.nomenclature?.is_variation ? t('yes') : t('no'),
          barcode:
            isProduct === true ? items?.barcode : items?.nomenclature?.barcode,
          barcode_orignal: items?.nomenclature?.barcode_original,
          units: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          guid: items?.nomenclature?.id
        };
      }) ?? []
    );
  }, [data, searchValue, t, i18n?.language]);

  const searchTableColumns: ColumnDef<SearchData>[] = [
    {
      id: 'action',
      cell: rowData => {
        const createdData = {
          barcode: rowData?.row?.original?.barcode,
          product_name: rowData?.row?.original?.product_name,
          nomenclature_id: rowData?.row?.original?.nomenclature_id
        };
        return (
          <div>
            <Button
              variant="hover"
              onClick={() => {
                reset(createdData);
                setOpenSearch(false);
                setSearchedValue(`${rowData?.row?.original?.barcode}`);
                setFocusToInput(true);
              }}
            >
              <FeatherIcon icon="plus" className="cursor-pointer" size={18} />
            </Button>
          </div>
        );
      },

      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'variation',
      header: t('variation'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode_orignal',
      header: t('barcode_original'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: searchData,
    columns: searchTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });
  return (
    <AdvanceTableProvider {...table}>
      <div>
        <SearchBox
          placeholder={t('search')}
          onChange={e => setSearchValue(e?.target?.value)}
          className="mb-4"
        />
        <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
        <AdvanceTableFooter pagination />

        <div className="d-flex justify-content-end">
          <Button
            className="mt-4"
            variant="primary"
            onClick={() => setOpenSearch(false)}
          >
            {t('add')}
          </Button>
        </div>
      </div>
    </AdvanceTableProvider>
  );
};

export default SearchBonus;
