/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo, useRef } from 'react';
import { Offcanvas, Modal } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from 'react-query';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Button from 'components/base/Button';
import AddProvider from './create/AddProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import providerEntityService from 'service/providerEntity';
// import SupperModal from 'components/modals/SupperModal';
import ImportedProviderTable from './ImportedProviderTable';

export type ProviderData = {
  individual: string;
  activity: string;
  country: string;
  region: string;
  area: string;
  address: string;
  phone: string;
  guid: string;
  is_custom: boolean;
};

export const providerBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'dt_kt',
    url: '/dtkt/provider'
  },
  {
    label: 'supplier',
    active: true
  }
];

const ProviderPage = () => {
  const { t, i18n } = useTranslation();

  const [openProvider, setOpenProvider] = useState(false);
  const [selectedProviderId, setSelectedProviderId] = useState('');
  // const [openSupperModal, setOpenSupperModal] = useState(false);
  // const [confirmToDelete, setConfirmToDelete] = useState(false);
  // const [selectedId, setSelectedId] = useState<string | null>(null);

  const [openImportModal, setOpenImportModal] = useState(false);
  const [importData, setImportData] = useState<any>([]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  //Get Provider
  const { data, refetch } = useQuery(['GET_CUSTOM_PROVIDER'], () => {
    const res = providerEntityService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.legal_entities);
    return res;
  });

  // const handleDelete = (id: string) => {
  //   setSelectedId(id);
  //   setOpenSupperModal(true);
  // };

  const providerEntityData: ProviderData[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          individual: items?.name,
          activity: items?.activity_kind?.name?.[i18n?.language],
          country: items?.country?.name?.[i18n?.language],
          region: items?.region?.name?.[i18n?.language],
          area: items?.district?.name?.[i18n?.language],
          address: items?.address,
          phone: items?.phone_number,
          is_custom: items?.is_custom,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const providerDataTableColumns: ColumnDef<ProviderData>[] = [
    {
      id: 'action',
      cell: rowData => {
        return (
          <>
            {rowData?.row?.original?.is_custom ? (
              <div className="d-flex gap-2">
                <Button
                  variant="hover"
                  className="p-2"
                  onClick={() => {
                    setSelectedProviderId(rowData?.row?.original?.guid),
                      setOpenProvider(true);
                  }}
                >
                  <FeatherIcon
                    icon="edit-2"
                    className="cursor-pointer"
                    size={20}
                  />
                </Button>
                {/* <Button
                  variant="hover"
                  className="p-2"
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleDelete(rowData?.row?.original?.guid);
                  }}
                >
                  <FeatherIcon
                    icon="trash-2"
                    className="cursor-pointer text-danger"
                    size={20}
                  />
                </Button> */}
              </div>
            ) : (
              ''
            )}
          </>
        );
      },
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'individual',
      header: t('individual'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'activity',
      header: t('activity_type'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'country',
      header: t('country'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'region',
      header: t('region'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'area',
      header: t('area'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'address',
      header: t('address'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'phone',
      header: t('phone_number'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];
  const table = useAdvanceTable({
    data: providerEntityData,
    columns: providerDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleExportClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = e => {
        if (e?.target?.result) {
          const data = new Uint8Array(e.target.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          console.log('jsonData', jsonData);
          const formattedData = (jsonData as any[][])
            .slice(1)
            .filter((row: any) =>
              row.some(
                (cell: any) =>
                  cell !== null && cell !== undefined && cell !== ''
              )
            )
            .map(
              ([
                name = '',
                phone_number,
                address = '',
                credit = 0,
                currency = '',
                maturity_date,
                note = ''
              ]) => ({
                name: String(name) ?? '',
                phone_number: String(phone_number),
                address: String(address) ?? '',
                credit: String(credit) ?? 0,
                currency: String(currency) ?? '',
                /* prettier-ignore */
                maturity_date:
                  typeof maturity_date === 'string'
                    ? validateAndFormatDate(maturity_date) // Validate date
                    : maturity_date
                      ? excelDateToJSDate(maturity_date)
                      : '',
                note: String(note) ?? ''
              })
            );

          if (formattedData?.length > 0) {
            setOpenImportModal(true);
            setImportData(formattedData);
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const excelDateToJSDate = (serial: number): string => {
    const utcDays = Math.floor(serial - 25569); // Excel's serial number is days since 1900-01-01, we subtract 25569 to get the number of days since 1970-01-01 (Unix epoch)
    const date = new Date(utcDays * 86400 * 1000); // Convert days to milliseconds and create a date
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    return `${day}.${month}.${year}`;
  };

  const validateAndFormatDate = (dateString: string): string => {
    // Check if the date is in the format DD.MM.YYYY
    const datePattern = /^\d{2}\.\d{2}\.\d{4}$/;

    if (datePattern.test(dateString)) {
      return dateString; // Return the date if it is valid
    }

    // If the format is invalid (e.g., "01,01,2024"), return an empty string
    return '';
  };

  const handleGetExcample = () => {
    const url = 'https://dev.test.cdn.api.rizo.uz/docs/provider_example.xlsx';

    const link = document.createElement('a');
    link.href = url;
    link.download = 'Шаблон.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // useEffect(() => {
  //   if (selectedId && confirmToDelete) {
  //     providerEntityService.delete(selectedId).finally(() => {
  //       refetch();
  //       setSelectedProviderId('');
  //       setOpenSupperModal(false);
  //       setConfirmToDelete(false);
  //       setSelectedId(null);
  //     });
  //   }
  // }, [confirmToDelete, selectedId, refetch]);

  return (
    <div>
      <PageBreadcrumb items={providerBreadcrumbItems} />

      <div className="d-flex justify-content-between align-items-center">
        <h2>{t('supplier')}</h2>

        <div className="d-flex gap-2">
          <Button variant="outline-info" onClick={() => handleGetExcample()}>
            <FeatherIcon icon="download-cloud" className="me-2" size={20} />
            {t('sample')}
          </Button>
          <Button variant="outline-primary" onClick={handleExportClick}>
            <FeatherIcon icon="download-cloud" className="me-2" size={20} />
            {t('import')}
          </Button>
          <Button
            variant="primary"
            style={{ height: '48px' }}
            onClick={() => setOpenProvider(true)}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            {t('new')}
          </Button>

          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept=".xlsx, .xls" // Specify the file types you want to allow
          />
        </div>
      </div>
      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>

      {/* <SupperModal
        show={openSupperModal}
        setShow={setOpenSupperModal}
        setConfirm={setConfirmToDelete}
        title="Поставщик"
        bodyText="Вы действительно хотите удалить поставщик?"
      /> */}

      <Offcanvas
        show={openProvider}
        onHide={() => {
          setOpenProvider(false);
          setSelectedProviderId('');
        }}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            {t('new')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddProvider
            setOpenProvider={setOpenProvider}
            selectedProviderId={selectedProviderId}
            refetch={refetch}
            setSelectedProviderId={setSelectedProviderId}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={openImportModal}
        onHide={() => {
          setOpenImportModal(false);
          setImportData([]);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Импортированные данные
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImportedProviderTable
            setOpenImportModal={setOpenImportModal}
            setImportData={setImportData}
            importData={importData}
            refetch={refetch}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProviderPage;
