/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useState, useRef, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

import AddIndividual from './AddIndividual';
import Button from 'components/base/Button';
import accessesService from 'service/legalEntityAccess';
import customerEntityService from 'service/charterer';
import { showAlert } from 'store/alert/alert.thunk';
import './styles.scss';

interface AddCustomerProps {
  setOpenCustomer: (show: boolean) => void;
  refetch?: any;
}

const AddCustomer = ({ setOpenCustomer, refetch }: AddCustomerProps) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue
  } = useForm({});

  const { t, i18n } = useTranslation();

  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();

  const [openIndividual, setopenIndividual] = useState(false);
  const [legalEntityAccessOption, setLegalEntityAccessOption] = useState([]);
  const [loading, setLoading] = useState(false);

  //GET Access to options
  useQuery(['GET_LEGAL_ENTITY_ACCESS'], async () => {
    await accessesService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entity_accesses?.map((option: any) => ({
          value: option?.id,
          label: option?.name?.[i18n?.language]
        }));
        setLegalEntityAccessOption(options);
      });
  });

  const handleGetLegalEntity = (e: any) => {
    console.log('log', e?.key);
    if (e.key === 'Enter') {
      const data = getValues();

      console.log('data', data);

      if (data?.scan_id !== '') {
        customerEntityService
          .getById(data?.scan_id)
          .then((res: any) => {
            if (res) {
              const computed = {
                default_currency: res?.currency?.name?.[i18n?.language],
                country: res?.country?.name?.[i18n?.language],
                region: res?.region?.name?.[i18n?.language],
                area: res?.district?.name?.[i18n?.language],
                address: res?.address,
                output_activite: res?.activity_kind?.name?.[i18n?.language],
                status: res?.legal_entity_status?.name?.[i18n?.language]
              };
              setValue('default_currency', computed.default_currency);
              setValue('country', computed.country);
              setValue('region', computed.region);
              setValue('area', computed.area);
              setValue('address', computed.address);
              setValue('output_activite', computed.output_activite);
              setValue('status', computed.status);
            }
          })
          .catch((error: any) => {
            const status = error?.response?.status;
            if (status === 404) {
              setValue('default_currency', '');
              setValue('country', '');
              setValue('region', '');
              setValue('area', '');
              setValue('address', '');
              setValue('output_activite', '');
              setValue('status', '');
              dispatch(
                showAlert({
                  title: t('customer_not_found')
                })
              );
            }
          });
      }
    }
  };

  const onSubmit = async () => {
    const isValid = await handleSubmit(async () => {
      const data = getValues();

      setLoading(true);
      if (data?.scan_id !== '') {
        customerEntityService
          .createLegalEntityRelation({
            child_id: data?.scan_id,
            legal_entity_access_id: data?.interaction_type
          })
          .then((res: any) => {
            if (res) {
              dispatch(
                showAlert({
                  title: `${t('customer')} ${t('successfully_added')}`,
                  type: 'success'
                })
              );

              setOpenCustomer(false);
              setLoading(false);
              refetch();
            }
          });
      }
    })(); // Immediately invoke the handleSubmit function

    return isValid;
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);
  return (
    <>
      <Form
        noValidate
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="interaction_type"
                control={control}
                defaultValue=""
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      isInvalid={!!errors.interaction_type}
                    >
                      <option className="d-none" value=""></option>
                      {legalEntityAccessOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('interaction_type')}
                    </label>
                    {errors?.interaction_type && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {t('required_field')}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col className="modal-rows">
            <Controller
              name="scan_id"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => (
                <Form.Floating className="frist-item">
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder={t('enter_individual_ID')}
                    onKeyDown={e => handleGetLegalEntity(e)}
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.scan_id}
                    ref={inputRef}
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('enter_individual_ID')}
                  </label>
                  {errors.scan_id && (
                    <Form.Control.Feedback type="invalid">
                      {t('required_field')}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />

            {/* <Form.Group className="modal-btn-group">
              <Button
                variant="primary"
                type="button"
                onClick={() => setopenIndividual(true)}
              >
                <FontAwesomeIcon icon={faQrcode} transform={{ size: 16 }} />
              </Button>
            </Form.Group> */}
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating className="frist-item">
              <Form.Control
                disabled={true}
                {...register('default_currency')}
                type="text"
                placeholder={t('currency')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('currency')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating className="frist-item">
              <Form.Control
                disabled={true}
                {...register('country')}
                type="text"
                placeholder={t('country')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('country')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating className="frist-item">
              <Form.Control
                disabled={true}
                {...register('region')}
                type="text"
                placeholder={t('region')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('region')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating className="frist-item">
              <Form.Control
                disabled={true}
                {...register('area')}
                type="text"
                placeholder={t('area')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('area')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                disabled={true}
                {...register('address')}
                type="tel"
                placeholder={t('address')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('address')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                disabled={true}
                {...register('output_activite')}
                type="tel"
                placeholder={t('enter_activities')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">
                {t('enter_activities')}
              </label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                disabled={true}
                {...register('status')}
                type="tel"
                placeholder={t('status')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('status')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          onClick={() => onSubmit()}
          loading={loading}
        >
          {t('save')}
        </Button>
      </Form>

      <Modal
        show={openIndividual}
        onHide={() => setopenIndividual(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Дупавление
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddIndividual setopenIndividual={setopenIndividual} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCustomer;
