/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import analyticRevaluationService from 'service/analyticRevaluation';

export type Product = {
  product_name: string;
  barcode: string;
  currency: string;
  units: string;
  old_purchase_price: string;
  quantity: string;
  new_purchase_price: string;
  revaluation_amount: string;
};

interface AccordingToProductTableProps {
  generateTableData?: any;
  setGeneratedTableData?: any;
  getName?: any;
  getBarcode?: any;
}

const AccordingToProductTable = ({
  generateTableData,
  setGeneratedTableData,
  getName,
  getBarcode
}: AccordingToProductTableProps) => {
  const { t } = useTranslation();
  const { data, refetch } = useQuery(
    ['GET_REPORTS_INCOME', getName, getBarcode],
    async () => {
      const res = await analyticRevaluationService
        .getInvoiceItemInById({
          offset: 0,
          limit: 100,
          search: getName,
          barcode: getBarcode
        })
        .then((res: any) => res?.items);
      return res;
    },
    {
      enabled:
        !getName ||
        getName.length === 2 ||
        !getBarcode ||
        getBarcode.length === 2
    }
  );

  const productData: Product[] = useMemo(() => {
    const resData =
      data?.map((items: any) => {
        return {
          product_name: items?.name,
          barcode: items?.barcode,
          currency: items?.currency?.name?.ru,
          units: items?.measure_unit?.name?.ru,
          old_purchase_price: items?.old_price_in ?? 0,
          quantity: items?.quantity ?? 0,
          new_purchase_price: items?.new_price_in ?? 0,
          revaluation_amount: items?.amount ?? 0
        };
      }) ?? [];

    if (resData) {
      setGeneratedTableData(resData);
    }

    return resData;
  }, [data]);

  console.log(productData);

  const productTableColumns: ColumnDef<Product>[] = [
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'old_purchase_price',
      header: t('old_purchase_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'quantity',
      header: t('quantity'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'new_purchase_price',
      header: t('new_purchase_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'revaluation_amount',
      header: t('revaluation_amount'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: generateTableData,
    columns: productTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    refetch();
  }, [getName, getBarcode]);

  return (
    <AdvanceTableProvider {...table}>
      <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
        <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
        <AdvanceTableFooter pagination />
      </div>
    </AdvanceTableProvider>
  );
};

export default AccordingToProductTable;
