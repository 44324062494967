/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { newApplicationsBreadcrumbItems } from 'data/newapplications';
import NewApplicationsTableSearch from './NewApplicationsTableSearch';
import NewApplicationsTable from './NewApplicationTable';
import orderItemService from 'service/orderItem';
import orderService from 'service/order';
import orderOutService from 'service/orderOut';
import { showAlert } from 'store/alert/alert.thunk';
import Button from 'components/base/Button';
import usePageSearchParams from 'utils/usePageSearchParams';
import useFocusRefs from 'utils/useFocusRefs';
import legalEntitySettingsService from 'service/legalEntitySettings';

import './styles.scss';

const NewApplicatons = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = usePageSearchParams();
  const newOrderId = pathname.split('/').pop();
  const senderId = searchParams?.get('sender_id');
  const dispatch: Dispatch<any> = useDispatch();
  const { setRef, focusRef } = useFocusRefs();

  const [saveLoading, setSaveLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [orderItemData, setOrderItemData] = useState([]);
  const [isAutoTab, setIsAutoTab] = useState(false);

  useQuery(['GET_SETTINGS'], async () => {
    await legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 20
      })
      .then((res: any) => {
        setIsAutoTab(res?.legal_entity_settings[0].is_auto_tab ?? 0);
      });
  });

  const sumOfQuantity = orderItemData.reduce((acc: any, item: any) => {
    const amount = item?.amount === undefined ? 0 : +item.amount;
    return acc + amount;
  }, 0);

  const sumOfPriceWholsale = orderItemData.reduce((acc: any, item: any) => {
    const price = item?.full_cost === undefined ? 0 : +item.full_cost;
    return acc + price;
  }, 0);

  const sumOfOrderPrice = sumOfQuantity * sumOfPriceWholsale;

  const handleSave = () => {
    console.log('orderItemData', orderItemData);
    const createData = orderItemData?.map((el: any) => ({
      barcode: el?.barcode,
      name: el?.product_name,
      price_wholesale: el?.full_cost,
      quantity_asked: el?.amount,
      currency_id: el?.currency_id,
      measure_unit_id: el?.measure_unit_id,
      nomenclature_id: el?.nomenclature_id,
      product_id: el?.product_id,
      product_parent_id: el?.product_parent_id,
      price: el?.price,
      price_in: el?.price_in,
      price_whosale: el?.price_whosale,
      status_id: '1a22cd85-070e-4779-afcb-8f0be603b60a' // static status id
    }));

    const updateData = {
      order_id: newOrderId,
      order_items: createData
    };

    if (newOrderId !== '') {
      setSaveLoading(true);
      orderService.getOrderById(newOrderId).then((res: any) => {
        if (res) {
          orderOutService
            .updateOrderOut(newOrderId, {
              ...res,
              status_id: '1a22cd85-070e-4779-afcb-8f0be603b60a'
            })
            .then((outresponse: any) => {
              if (outresponse) {
                orderItemService
                  .createOrderItem(updateData)
                  .then((createres: any) => {
                    if (createres) {
                      dispatch(
                        showAlert({
                          title: t('new_application_saved'),
                          type: 'success'
                        })
                      );
                      setSaveLoading(false);
                    }
                  });
              }
            });
        }
      });
    }
  };

  const handleSend = () => {
    const createData = orderItemData?.map((el: any) => {
      return {
        barcode: el?.barcode,
        name: el?.product_name,
        price_wholesale: el?.full_cost,
        quantity_asked: el?.amount,
        currency_id: el?.currency_id,
        measure_unit_id: el?.measure_unit_id,
        status_id: '2b22cd85-070e-4779-afcb-8f0be603b60b', // static status id
        nomenclature_id: el?.nomenclature_id,
        product_id: el?.product_id,
        product_parent_id: el?.product_parent_id,
        price: el?.price,
        price_in: el?.price_in,
        price_whosale: el?.price_whosale
      };
    });

    const updateData = {
      order_id: newOrderId,
      order_items: createData
    };

    if (newOrderId !== '' && createData?.length > 0) {
      setSendLoading(true);
      orderService.getOrderById(newOrderId).then((res: any) => {
        if (res) {
          orderOutService
            .updateOrderOut(newOrderId, {
              ...res,
              status_id: '2b22cd85-070e-4779-afcb-8f0be603b60b'
            })
            .then((outresponse: any) => {
              if (outresponse) {
                orderItemService
                  .createOrderItem(updateData)
                  .then((createres: any) => {
                    if (createres) {
                      setSendLoading(false);
                      dispatch(
                        showAlert({
                          title: t('new_return_sent'),
                          type: 'success'
                        })
                      );
                      navigate('/applications/outgoing');
                    }
                  });
              }
            });
        }
      });
    } else {
      dispatch(
        showAlert({
          title: t('Unable_submit_request')
        })
      );
    }
  };

  return (
    <div>
      <PageBreadcrumb items={newApplicationsBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{t('new_application')}</h2>

        <NewApplicationsTableSearch
          senderId={senderId}
          orderItemData={orderItemData}
          setOrderItemData={setOrderItemData}
          focusRef={focusRef}
          isAutoTab={isAutoTab}
        />
        <NewApplicationsTable
          newOrderId={newOrderId}
          orderItemData={orderItemData}
          setOrderItemData={setOrderItemData}
          setRef={setRef}
        />

        <div className="footer-app-info">
          <div className="box">
            <p className="mb-0 text-bold">{t('total_sale_amount')}:</p>
            <span>{sumOfOrderPrice ?? 0}</span>
          </div>

          <div className="main-btn-group">
            <Button
              type="button"
              variant="outline-primary"
              onClick={() => handleSave()}
              loading={saveLoading}
            >
              {t('save')}
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={() => handleSend()}
              loading={sendLoading}
            >
              {t('send')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewApplicatons;
