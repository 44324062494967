/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Offcanvas } from 'react-bootstrap';
import { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBarcode } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import IncomeCreateFilter from './IncomeCreateFilter';

interface IncomeFilterProps {
  setGeneratedTableData?: any;
  savedValues?: any;
  setSavedValues?: any;
  setGetName?: any;
  setGetBarcode?: any;
  getName?: any;
  getBarcode?: any;
  setGetFilterData?: any;
  setCurrencyName?: any;
}

const IncomeFilter = ({
  setGeneratedTableData,
  savedValues,
  setSavedValues,
  setGetName,
  setGetBarcode,
  getName,
  getBarcode,
  setGetFilterData,
  setCurrencyName
}: IncomeFilterProps) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  return (
    <div>
      <Form className="d-flex gap-3">
        <div className="form-icon-container">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder={t('enter_product')}
              onFocus={e => e.target.select()}
              onChange={e => setGetName(e.target.value)}
              className="form-icon-input"
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              {t('enter_product')}
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faSearch}
            className="text-900 fs-9 form-icon"
          />
        </div>

        <div className="form-icon-container">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder={t('scan_barcode')}
              onFocus={e => e.target.select()}
              onChange={e => setGetBarcode(e.target.value)}
              className="form-icon-input"
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              {t('scan_barcode')}
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faBarcode}
            className="text-900 fs-9 form-icon"
          />
        </div>

        <Button
          style={{ height: '48px' }}
          variant="outline-primary"
          onClick={() => setOpenModal(true)}
        >
          <FeatherIcon icon="filter" className="me-2" size={20} />
          {t('filter')}
        </Button>

        <Button style={{ height: '48px' }} variant="outline-primary">
          <FeatherIcon icon="upload-cloud" className="me-2" size={20} />
          {t('export')}
        </Button>

        <Offcanvas
          show={openModal}
          onHide={() => {
            setOpenModal(false);
          }}
          placement="end"
          backdrop="static"
          keyboard={false}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
              {t('filter')}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <IncomeCreateFilter
              setGeneratedTableData={setGeneratedTableData}
              savedValues={savedValues}
              setSavedValues={setSavedValues}
              setOpenModal={setOpenModal}
              getName={getName}
              getBarcode={getBarcode}
              setGetFilterData={setGetFilterData}
              setCurrencyName={setCurrencyName}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </Form>
    </div>
  );
};

export default IncomeFilter;
