/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { UzbekLatin } from 'flatpickr/dist/l10n/uz_latn.js';

import Button from 'components/base/Button';
import legalEntityService from 'service/legalEntity';
import orderStatusService from 'service/orderStatus';
import CreateNewApplication from './CreateNewApplication';
import '../styles.scss';

interface OutgoingFilterProps {
  setDateFrom?: any;
  setDateTo?: any;
  dateFrom?: any;
  dateTo?: any;
  setOptionSenderId?: any;
  setOptionStatusId?: any;
}

const OutgoingFilter = ({
  setDateFrom,
  setDateTo,
  dateFrom,
  dateTo,
  setOptionSenderId,
  setOptionStatusId
}: OutgoingFilterProps) => {
  const { t, i18n } = useTranslation();

  const { control } = useForm();

  const [legalEntityOption, setLegalEntityOption] = useState<any>([]);
  const [orderStatusOption, setOrderStatusOption] = useState<any>([]);
  const [createOpen, setCreateOpen] = useState(false);

  //GET LEgal Entity to options
  useQuery(['GET_LEGAL_ENTITY'], async () => {
    await legalEntityService
      .getLegalEntityOption({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entities?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));
        setLegalEntityOption(options);
      });
  });

  //GET Order Status to options
  useQuery(['GET_ORDER_STATUS'], async () => {
    await orderStatusService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.order_statuses?.map((option: any) => ({
          value: option.id,
          label: option?.name?.[i18n?.language]
        }));
        setOrderStatusOption(options);
      });
  });
  const handleStartDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const startDate = new Date(date[0]);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    } else {
      const startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    }
  };

  const handleEndDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const endDateInMillis = date[0]?.getTime();
      setDateTo(endDateInMillis);
    } else {
      const endDateInMillis = date.getTime();
      setDateTo(endDateInMillis);
    }
  };

  const getFlatpickrLocale = () => {
    switch (i18n.language) {
      case 'ru':
        return Russian;
      case 'uz':
        return UzbekLatin;
      default:
        return undefined;
    }
  };

  return (
    <Form noValidate>
      <div className="app-rows">
        <Form.Group className="md-4">
          <div className="react-select-container">
            <Controller
              name="sender"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    onChange={(e: any) => {
                      const selectedOption = legalEntityOption.find(
                        (option: any) => option.value === e.target.value
                      );
                      if (selectedOption) {
                        setOptionSenderId(selectedOption?.value);
                      }
                      field.onChange(e);
                    }}
                  >
                    <option className="d-none" value=""></option>
                    {legalEntityOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">{t('sender')}</label>
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group className="md-4">
          <div className="react-select-container">
            <Controller
              name="states"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    onChange={(e: any) => {
                      const selectedOption = orderStatusOption.find(
                        (option: any) => option.value === e.target.value
                      );
                      if (selectedOption) {
                        setOptionStatusId(selectedOption?.value);
                      }
                      field.onChange(e);
                    }}
                  >
                    <option className="d-none" value=""></option>
                    {orderStatusOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">{t('status')}</label>
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group className="md-4">
          <Form.Floating>
            <Flatpickr
              className={classNames('form-control')}
              options={{
                nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                locale: getFlatpickrLocale(),
                monthSelectorType: 'static',
                onDayCreate: (...[, , , dayElem]) => {
                  if (
                    dayElem.dateObj.getDay() === 5 ||
                    dayElem.dateObj.getDay() === 6
                  ) {
                    dayElem.className += ' weekend-days';
                  }
                },
                dateFormat: 'M j, Y',
                disableMobile: true
              }}
              value={dateFrom}
              onChange={handleStartDateChange}
              placeholder={t('date_from')}
            />
            <label htmlFor="floatingInputCustom">{t('date_from')}</label>
          </Form.Floating>
        </Form.Group>

        <Form.Group className="md-4">
          <Form.Floating>
            <Flatpickr
              className={classNames('form-control')}
              options={{
                nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                locale: getFlatpickrLocale(),
                monthSelectorType: 'static',
                onDayCreate: (...[, , , dayElem]) => {
                  if (
                    dayElem.dateObj.getDay() === 5 ||
                    dayElem.dateObj.getDay() === 6
                  ) {
                    dayElem.className += ' weekend-days';
                  }
                },
                dateFormat: 'M j, Y',
                disableMobile: true
              }}
              value={dateTo}
              onChange={handleEndDateChange}
              placeholder={t('date_to')}
            />
            <label htmlFor="floatingInputCustom">{t('date_to')}</label>
          </Form.Floating>
        </Form.Group>

        <Form.Group className="app-btn ms-auto">
          <Button
            variant="primary"
            type="button"
            onClick={() => setCreateOpen(true)}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            {t('new')}
          </Button>
        </Form.Group>
      </div>

      <Offcanvas
        show={createOpen}
        onHide={() => setCreateOpen(false)}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            {t('new')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CreateNewApplication />
        </Offcanvas.Body>
      </Offcanvas>
    </Form>
  );
};

export default OutgoingFilter;
