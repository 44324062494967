/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import marriageGoodsService from 'service/marriageGoods';

interface UpdateChoousenItemProps {
  setOpenUpdateQuantity: (show: boolean) => void;
  refetch?: any;
  handledItems?: any;
}

const UpdateChoousenItem = ({
  setOpenUpdateQuantity,
  refetch,
  handledItems
}: UpdateChoousenItemProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm();

  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (+data?.quantity === 0) {
      dispatch(
        showAlert({
          title: t('quantity_cannot_thero')
        })
      );
      return;
    }

    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const createdData = {
      nomenclature_id: handledItems?.nomenclature_id,
      product_id: handledItems?.product_id,
      quantity: +data?.quantity
    };

    marriageGoodsService?.create(createdData).then(res => {
      if (res) {
        dispatch(
          showAlert({
            title: `${t('marriage_goods')} ${t('successfully_added')}`,
            type: 'success'
          })
        );
        refetch();
        setOpenUpdateQuantity(false);
      }
    });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  const handleQuantityChange = (e: any, field: any) => {
    let value = e.target.value;

    // Check if the measure_unit_id allows decimal values
    if (
      handledItems?.measure_unit_id ===
        '208baabf-b8dd-4e2e-b3a0-05c2f6744052' ||
      handledItems?.measure_unit_id === '443bfff1-61e0-4057-8583-d040dc5a0454'
    ) {
      // Replace comma with period for decimal numbers
      value = value.replace(',', '.');

      // Allow decimal numbers, including 1.2, 4.5, 0., etc.
      if (/^\d*\.?\d*$/.test(value)) {
        field.onChange(value); // Allow the user to input the value as a string

        // Only parse it as a float and enforce limits if it's a valid number
        if (value !== '' && value !== '.' && !isNaN(parseFloat(value))) {
          const numericValue = parseFloat(value);

          // If the numeric value exceeds the remainder, cap it
          if (numericValue > handledItems?.remainder) {
            setValue('quantity', handledItems.remainder);
          }
        }
      }
    } else {
      // Handle integer values for other measure_unit_id
      const numericValue = parseInt(value, 10);

      if (value === '') {
        field.onChange(value); // Allow clearing the input
      } else if (!isNaN(numericValue)) {
        // Ensure the value is between 1 and handledItems.remainder
        if (numericValue < 1) {
          setValue('quantity', 1);
        } else if (numericValue > handledItems?.remainder) {
          setValue('quantity', handledItems.remainder);
        } else {
          field.onChange(numericValue);
        }
      }
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Col>
            <Controller
              name={`quantity`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...field}
                    ref={inputRef}
                    type="text"
                    placeholder={`Количество`}
                    onFocus={e => e.target.select()}
                    onChange={e => handleQuantityChange(e, field)}
                    isInvalid={!!errors.quantity}
                  />
                  <label htmlFor="floatingInputCustom">{t('quantity')}</label>
                  {errors.quantity && (
                    <Form.Control.Feedback type="invalid">
                      {t('required_field')}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Button
          className="w-100"
          variant="primary"
          type="submit"
          loading={loading}
        >
          {t('add')}
        </Button>
      </Form>
    </div>
  );
};

export default UpdateChoousenItem;
