import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { comingBreadcrumbItems } from 'data/returning';
import ComingFilterReturning from './ComingFilter';
import ComingFilterReturningTable from './ComingFilterReturningTable';

const ComingOutReturning = () => {
  const { t } = useTranslation();

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [optionSenderId, setOptionSenderId] = useState('');
  const [optionStatusId, setOptionStatusId] = useState('');
  return (
    <div>
      <PageBreadcrumb items={comingBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{t('incoming')}</h2>

        <ComingFilterReturning
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          setOptionSenderId={setOptionSenderId}
          setOptionStatusId={setOptionStatusId}
        />
        <ComingFilterReturningTable
          dateFrom={dateFrom}
          dateTo={dateTo}
          optionSenderId={optionSenderId}
          optionStatusId={optionStatusId}
        />
      </div>
    </div>
  );
};

export default ComingOutReturning;
