/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useState, useMemo, useCallback } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Form, TabContainer, Nav, Tab } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

import { showAlert } from 'store/alert/alert.thunk';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import roleService from 'service/role';
import AccordionAdvancedTable from 'components/base/AccordionAdvancedTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import Button from 'components/base/Button';
import './styles.scss';

interface SimplifiedRoleType {
  name: string;
  path: string;
  check: boolean;
  children?: SimplifiedRoleType[];
}

interface SimplifiedPosRoleType {
  name: string;
  path: string;
  check: boolean;
}

const defaultPermissions = {
  web_legal_entity: false,
  web_management: false,
  web_user: false,
  web_role: false,
  web_guidebook: false,
  web_warehouse: false,
  web_currency: false,
  web_currency_rate: false,
  web_payment_method: false,
  web_vat: false,
  web_wallet: false,
  web_finance_article: false,
  web_cash: false,
  web_product: false,
  web_my_product: false,
  web_weight_product: false,
  web_group_product: false,
  web_invoice: false,
  web_bonus: false,
  web_order: false,
  web_order_in: false,
  web_order_out: false,
  web_moving: false,
  web_moving_in: false,
  web_moving_out: false,
  web_return: false,
  web_return_in: false,
  web_return_out: false,
  web_debit_credit: false,
  web_provider: false,
  web_provider_debit_credit: false,
  web_provider_maturity: false,
  web_charterer: false,
  web_charterer_debit_credit: false,
  web_charterer_maturity: false,
  web_inventory: false,
  web_inventory_create: false,
  web_inventory_info: false,
  web_expense: false,
  web_report: false,
  web_report_invoice: false,
  web_report_invoice_invoice: false,
  web_report_invoice_product: false,
  web_report_sale: false,
  web_report_remaining: false,
  web_report_cashbox: false,
  web_report_gross_income: false,
  web_analytic: false,
  web_revaluation: false,
  web_revaluation_invoice: false,
  web_revaluation_product: false,
  web_abc: false,
  web_auto_order: false,
  web_frozen_product: false,
  web_missed_arrival: false,
  web_finance: false,
  web_cashflow: false,
  web_pl: false
};

const defaultPosPermissions = {
  pos_cashbox: false,
  pos_debit_credit: false,
  pos_expense: false,
  pos_price_tag: false,
  pos_receipt: false,
  pos_return: false,
  pos_sale: false,
  pos_settings: false,
  pos_x_report: false,
  pos_rollback_settlement: false
};

const roleTranslationMap: Record<string, string> = {
  Admin: 'admin',
  Accountant: 'accountant',
  Merchandiser: 'merchandiser',
  'Main Cashier': 'main_cashier',
  Cachier: 'cashier',
  Customer: 'Mijoz'
};

const RolePermisions = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const roleId = useMemo(() => pathname.split('/').pop(), [pathname]);

  const dispatch: Dispatch<any> = useDispatch();

  const [permissionData, setPermissionData] = useState<any>(defaultPermissions);
  const [posPermissionData, setPosPermissionData] = useState<any>();
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllPos, setSelectAllPos] = useState(false);
  const [key, setKey] = useState('web');

  const { refetch } = useQuery(
    ['GET_ROLES_PERMISSION', roleId],
    async () => {
      if (roleId) {
        await roleService
          .getRolePermisionById(roleId, {
            offset: 0,
            limit: 100
          })
          .then(res => {
            if (res && res.data) {
              setPermissionData((prevData: any) => ({
                ...prevData,
                ...res.data
              }));
            } else {
              setPermissionData(defaultPermissions);
            }
          });
      }
    },
    {
      enabled: !!roleId
    }
  );

  const { refetch: refetchPos } = useQuery(
    ['GET_ROLES_PERMISSION_POS', roleId],
    async () => {
      if (roleId) {
        await roleService
          .getPosRolePermisionById(roleId, {
            offset: 0,
            limit: 100
          })
          .then(res => {
            if (res && res.data) {
              setPosPermissionData((prevData: any) => ({
                ...prevData,
                ...res.data
              }));
            } else {
              setPosPermissionData(defaultPosPermissions);
            }
          });
      }
    },
    {
      enabled: !!roleId
    }
  );

  const handleToggle = useCallback((path: string) => {
    setPermissionData((prevData: any) => ({
      ...prevData,
      [path]: !prevData[path]
    }));
  }, []);

  const handleTogglePos = useCallback((path: string) => {
    setPosPermissionData((prevData: any) => ({
      ...prevData,
      [path]: !prevData[path]
    }));
  }, []);

  const handleSelectAll = () => {
    setSelectAll(prevSelectAll => {
      const newSelectAll = !prevSelectAll;
      const updatedPermissions = Object.keys(defaultPermissions).reduce(
        (acc, key) => {
          acc[key] = newSelectAll;
          return acc;
        },
        {} as any
      );

      setPermissionData((prevData: any) => ({
        ...prevData,
        ...updatedPermissions
      }));
      return newSelectAll;
    });
  };

  const handleSelectAllPos = () => {
    setSelectAllPos(prevSelectAll => {
      const newSelectAll = !prevSelectAll;
      const updatedPermissions = Object.keys(defaultPosPermissions).reduce(
        (acc, key) => {
          acc[key] = newSelectAll;
          return acc;
        },
        {} as any
      );

      setPosPermissionData((prevData: any) => ({
        ...prevData,
        ...updatedPermissions
      }));
      return newSelectAll;
    });
  };

  const CreatedData: SimplifiedRoleType[] = [
    {
      name: t('legal_entities'),
      path: 'web_legal_entity',
      check: permissionData?.web_legal_entity
    },
    {
      name: t('control'),
      path: 'web_management',
      check: permissionData?.web_management,
      children: [
        {
          name: t('users'),
          path: 'web_user',
          check: permissionData?.web_user
        },
        {
          name: t('roles'),
          path: 'web_role',
          check: permissionData?.web_role
        }
      ]
    },
    {
      name: t('directories'),
      path: 'web_guidebook',
      check: permissionData?.web_guidebook,
      children: [
        {
          name: t('warehouse'),
          path: 'web_warehouse',
          check: permissionData?.web_warehouse
        },
        {
          name: t('currency'),
          path: 'web_currency',
          check: permissionData?.web_currency
        },
        {
          name: t('exchange_rates'),
          path: 'web_currency_rate',
          check: permissionData?.web_currency_rate
        },
        {
          name: t('payment_type'),
          path: 'web_payment_method',
          check: permissionData?.web_payment_method
        },
        {
          name: t('vat'),
          path: 'web_vat',
          check: permissionData?.web_vat
        },
        {
          name: t('wallet'),
          path: 'web_wallet',
          check: permissionData?.web_wallet
        },
        {
          name: t('financial_account'),
          path: 'web_finance_article',
          check: permissionData?.web_finance_article
        },
        {
          name: t('cash'),
          path: 'web_cash',
          check: permissionData?.web_cash
        }
      ]
    },
    {
      name: t('products'),
      path: 'web_product',
      check: permissionData?.web_product,
      children: [
        {
          name: t('my_products'),
          path: 'web_my_product',
          check: permissionData?.web_my_product
        },
        {
          name: t('scale_weight_product'),
          path: 'web_weight_product',
          check: permissionData?.web_weight_product
        },
        {
          name: t('group_product'),
          path: 'web_group_product',
          check: permissionData?.web_group_product
        }
      ]
    },
    {
      name: t('purchase'),
      path: 'web_invoice',
      check: permissionData?.web_invoice
    },
    {
      name: t('bonuses'),
      path: 'web_bonus',
      check: permissionData?.web_bonus
    },
    {
      name: t('applications'),
      path: 'web_order',
      check: permissionData?.web_order,
      children: [
        {
          name: t('outgoing'),
          path: 'web_order_out',
          check: permissionData?.web_order_out
        },
        {
          name: t('incoming'),
          path: 'web_order_in',
          check: permissionData?.web_order_in
        }
      ]
    },
    {
      name: t('movements'),
      path: 'web_moving',
      check: permissionData?.web_moving,
      children: [
        {
          name: t('outgoing'),
          path: 'web_moving_out',
          check: permissionData?.web_moving_out
        },
        {
          name: t('incoming'),
          path: 'web_moving_in',
          check: permissionData?.web_moving_in
        }
      ]
    },
    {
      name: t('returns'),
      path: 'web_return',
      check: permissionData?.web_return,
      children: [
        {
          name: t('outgoing'),
          path: 'web_return_out',
          check: permissionData?.web_return_out
        },
        {
          name: t('incoming'),
          path: 'web_return_in',
          check: permissionData?.web_return_in
        }
      ]
    },
    {
      name: t('dt_kt'),
      path: 'web_debit_credit',
      check: permissionData?.web_debit_credit,
      children: [
        {
          name: t('supplier'),
          path: 'web_provider',
          check: permissionData?.web_provider
        },
        {
          name: t('settlement_supplier'),
          path: 'web_provider_debit_credit',
          check: permissionData?.web_provider_debit_credit
        },
        {
          name: t('supplier_repayment_terms'),
          path: 'web_provider_maturity',
          check: permissionData?.web_provider_maturity
        },
        {
          name: t('customer'),
          path: 'web_charterer',
          check: permissionData?.web_charterer
        },
        {
          name: t('settlement_customer'),
          path: 'web_charterer_debit_credit',
          check: permissionData?.web_charterer_debit_credit
        },
        {
          name: t('customer_repayment_terms'),
          path: 'web_charterer_maturity',
          check: permissionData?.web_charterer_maturity
        }
      ]
    },
    {
      name: t('inventory'),
      path: 'web_inventory',
      check: permissionData?.web_inventory,
      children: [
        {
          name: t('create_inventory'),
          path: 'web_inventory_create',
          check: permissionData?.web_inventory_create
        },
        {
          name: t('inventory_information'),
          path: 'web_inventory_info',
          check: permissionData?.web_inventory_info
        }
      ]
    },
    {
      name: t('cost'),
      path: 'web_expense',
      check: permissionData?.web_expense
    },
    {
      name: t('reports'),
      path: 'web_report',
      check: permissionData?.web_report,
      children: [
        {
          name: t('purchase'),
          path: 'web_report_invoice',
          check: permissionData?.web_report_invoice,
          children: [
            {
              name: t('purchase_invoice'),
              path: 'web_report_invoice_invoice',
              check: permissionData?.web_report_invoice_invoice
            },
            {
              name: t('purchase_products'),
              path: 'web_report_invoice_product',
              check: permissionData?.web_report_invoice_product
            }
          ]
        },
        {
          name: t('sales_product'),
          path: 'web_report_sale',
          check: permissionData?.web_report_sale
        },
        {
          name: t('remainder'),
          path: 'web_report_remaining',
          check: permissionData?.web_report_remaining
        },
        {
          name: t('gross_income'),
          path: 'web_report_gross_income',
          check: permissionData?.web_report_gross_income
        },
        {
          name: t('cash'),
          path: 'web_report_cashbox',
          check: permissionData?.web_report_cashbox
        }
      ]
    },
    {
      name: t('analytics'),
      path: 'web_analytic',
      check: permissionData?.web_analytic,
      children: [
        {
          name: t('revaluation_products'),
          path: 'web_revaluation',
          check: permissionData?.web_revaluation,
          children: [
            {
              name: t('revaluation_invoice'),
              path: 'web_revaluation_invoice',
              check: permissionData?.web_revaluation_invoice
            },
            {
              name: t('revaluation_products'),
              path: 'web_revaluation_product',
              check: permissionData?.web_revaluation_product
            }
          ]
        },
        {
          name: t('abc_analysis'),
          path: 'web_abc',
          check: permissionData?.web_abc
        },
        {
          name: t('auto_order'),
          path: 'web_auto_order',
          check: permissionData?.web_auto_order
        },
        {
          name: t('frozen_remedy'),
          path: 'web_frozen_product',
          check: permissionData?.web_frozen_product
        },
        {
          name: t('lost_profit'),
          path: 'web_missed_arrival',
          check: permissionData?.web_missed_arrival
        }
      ]
    },
    {
      name: t('finance'),
      path: 'web_finance',
      check: permissionData?.web_finance,
      children: [
        {
          name: t('dds'),
          path: 'web_cashflow',
          check: permissionData?.web_cashflow
        },
        {
          name: t('p_and_l'),
          path: 'web_pl',
          check: permissionData?.web_pl
        }
      ]
    }
  ];

  const CreateDataPos: SimplifiedPosRoleType[] = [
    {
      name: t('sale'),
      path: 'pos_sale',
      check: posPermissionData?.pos_sale
    },
    {
      name: t('mutual_settlement'),
      path: 'pos_debit_credit',
      check: posPermissionData?.pos_debit_credit
    },
    {
      name: t('check'),
      path: 'pos_receipt',
      check: posPermissionData?.pos_receipt
    },
    {
      name: t('return'),
      path: 'pos_return',
      check: posPermissionData?.pos_return
    },
    {
      name: t('expense'),
      path: 'pos_expense',
      check: posPermissionData?.pos_expense
    },
    {
      name: t('price_tag'),
      path: 'pos_price_tag',
      check: posPermissionData?.pos_price_tag
    },
    {
      name: t('setting'),
      path: 'pos_settings',
      check: posPermissionData?.pos_settings
    },
    {
      name: t('x_report'),
      path: 'pos_x_report',
      check: posPermissionData?.pos_x_report
    },
    {
      name: t('change'),
      path: 'pos_cashbox',
      check: posPermissionData?.pos_cashbox
    },
    {
      name: t('cansel_settlement'),
      path: 'pos_rollback_settlement',
      check: posPermissionData?.pos_rollback_settlement
    }
  ];

  const rolesTableColumns: ColumnDef<SimplifiedRoleType>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('role_name'),
        cell: (rowData: any) => {
          return (
            <div>
              {rowData?.row?.children?.length > 0 ? (
                <span style={{ fontWeight: 'bold' }}>{rowData?.row?.name}</span>
              ) : (
                <span>{rowData?.row?.name}</span>
              )}
            </div>
          );
        },

        meta: {
          headerProps: { style: { width: '50%' } },
          cellProps: { className: 'text-900' }
        }
      },
      {
        accessorKey: 'check',
        header: () => (
          <div className="d-flex gap-2 align-items-center">
            <Form.Check
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
            <p className="mb-0">{t('status')}</p>
          </div>
        ),
        cell: (rowData: any) => {
          return (
            <div>
              <Form.Check
                type="checkbox"
                checked={rowData.row?.check} // Ensure this is correctly bound
                onChange={() => handleToggle(rowData.row?.path)}
                style={{
                  marginRight: '10px'
                }}
              />
            </div>
          );
        },
        meta: { cellProps: { className: 'text-900' } }
      }
    ],
    [handleToggle, selectAll, handleSelectAll]
  );

  const posRolesTableColumns: ColumnDef<SimplifiedPosRoleType>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('role_name'),
        cell: (rowData: any) => {
          return (
            <div>
              {rowData?.row?.children?.length > 0 ? (
                <span style={{ fontWeight: 'bold' }}>{rowData?.row?.name}</span>
              ) : (
                <span>{rowData?.row?.name}</span>
              )}
            </div>
          );
        },

        meta: {
          headerProps: { style: { width: '50%' } },
          cellProps: { className: 'text-900' }
        }
      },
      {
        accessorKey: 'check',
        header: () => (
          <div className="d-flex gap-2 align-items-center">
            <Form.Check
              type="checkbox"
              checked={selectAllPos}
              onChange={handleSelectAllPos}
            />
            <p className="mb-0">{t('status')}</p>
          </div>
        ),
        cell: (rowData: any) => {
          return (
            <div>
              <Form.Check
                type="checkbox"
                checked={rowData.row?.check} // Ensure this is correctly bound
                onChange={() => handleTogglePos(rowData.row?.path)}
                style={{
                  marginRight: '10px'
                }}
              />
            </div>
          );
        },
        meta: { cellProps: { className: 'text-900' } }
      }
    ],
    [handleTogglePos, selectAllPos, handleSelectAll]
  );

  const table = useAdvanceTable({
    data: CreatedData,
    columns: rolesTableColumns,
    pageSize: CreatedData ? CreatedData?.length : 50,
    pagination: true,
    sortable: true,
    selection: false
  });

  const tablePos = useAdvanceTable({
    data: CreateDataPos,
    columns: posRolesTableColumns,
    pageSize: CreateDataPos ? CreateDataPos?.length : 50,
    pagination: true,
    sortable: true,
    selection: false
  });
  const translateRoleName = (name: string): string =>
    roleTranslationMap[name] || name;

  const rolesBreadcrumbItems: PageBreadcrumbItem[] = useMemo(() => {
    const roleName = permissionData?.role?.name;
    return [
      {
        label: t('roles'),
        url: '/roles'
      },
      {
        label: roleName ? t(translateRoleName(roleName)) : `${t('loading')}...`,
        active: true
      }
    ];
  }, [permissionData, t]);

  const onSubmit = () => {
    if (roleId) {
      if (key === 'web') {
        roleService?.update(roleId, permissionData).then(res => {
          if (res) {
            refetch();
            dispatch(
              showAlert({
                title: `${t('roles')} ${t('successfully_added')}`,
                type: 'success'
              })
            );
          }
        });
      } else {
        roleService?.updatePos(roleId, posPermissionData).then(res => {
          if (res) {
            refetchPos();
            dispatch(
              showAlert({
                title: `${t('roles')} ${t('successfully_added')}`,
                type: 'success'
              })
            );
          }
        });
      }
    }
  };

  return (
    <div>
      <PageBreadcrumb items={rolesBreadcrumbItems} />

      <div className="d-flex justify-content-between align-items-center mb-2">
        <h2>
          {permissionData && t(translateRoleName(permissionData?.role?.name))}
        </h2>

        <Button variant="primary" onClick={onSubmit} style={{ height: '48px' }}>
          {t('save')}
        </Button>
      </div>

      <TabContainer activeKey={key} onSelect={(k: any) => setKey(k)}>
        <Nav variant="underline" className="mb-3">
          <Nav.Item>
            <Nav.Link eventKey="web">WEB</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="pos">POS</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="web">
            <AdvanceTableProvider {...table}>
              <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
                <AccordionAdvancedTable
                  tableProps={{ className: 'phoenix-table fs-9' }}
                  data={CreatedData}
                  columns={rolesTableColumns}
                />
              </div>
            </AdvanceTableProvider>
          </Tab.Pane>
          <Tab.Pane eventKey="pos">
            <AdvanceTableProvider {...tablePos}>
              <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
                <AccordionAdvancedTable
                  tableProps={{ className: 'phoenix-table fs-9' }}
                  data={CreateDataPos}
                  columns={posRolesTableColumns}
                />
              </div>
            </AdvanceTableProvider>
          </Tab.Pane>
        </Tab.Content>
      </TabContainer>
    </div>
  );
};

export default RolePermisions;
