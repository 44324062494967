import classNames from 'classnames';
import { useAppContext } from 'providers/AppProvider';
import { Navbar } from 'react-bootstrap';
import logo from '../../../assets/img/icons/rizoLogo.svg';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import NavbarToggleButton from './NavbarToggleButton';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const NavbarBrand = () => {
  const {
    config: { navbarTopShape, navbarPosition }
  } = useAppContext();
  const { breakpoints } = useBreakpoints();

  const { t } = useTranslation();

  return (
    <>
      <div className="navbar-logo">
        {breakpoints.down('lg') && <NavbarToggleButton />}
        <Navbar.Brand
          as={Link}
          to="/"
          className={classNames({
            'me-1 me-sm-3':
              navbarTopShape === 'slim' || navbarPosition === 'horizontal'
          })}
        >
          {navbarTopShape === 'slim' ? (
            <>
              phoenix <span className="text-1000 d-none d-sm-inline">slim</span>
            </>
          ) : (
            <div className="d-flex align-items-center">
              <img src={logo} alt="logo" />
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-disabled">{t('contact_number')}</Tooltip>
                }
              >
                <div
                  onClick={e => {
                    e.preventDefault();
                  }}
                  className="d-flex gap-2"
                >
                  <p className="logo-text ms-2 d-none d-sm-block">
                    <FeatherIcon icon="phone-call" className="me-2" />
                    +998 (95) 328-05-05
                  </p>
                </div>
              </OverlayTrigger>
            </div>
          )}
        </Navbar.Brand>
      </div>
    </>
  );
};

export default NavbarBrand;
