import axios from 'axios';
import i18n from 'i18next';

import { store } from 'store';
import { showAlert } from 'store/alert/alert.thunk';

const requestAuth = axios.create({
  baseURL: process.env.REACT_APP_URL_AUTH,
  timeout: 100000
});

const errorHandler = error => {
  const currentLanguage = i18n.language;
  console.log('error', error);
  if (error.response?.data) {
    store.dispatch(
      showAlert({
        title: error.response?.data?.data?.info?.[currentLanguage]
      })
    );
  } else {
    store.dispatch(showAlert({ title: '___ERROR___' }));
  }

  return Promise.reject(error.response);
};

requestAuth.interceptors.request.use(
  config => {
    const token = store.getState().auth.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },

  error => errorHandler(error)
);

requestAuth.interceptors.response.use(response => response.data, errorHandler);

export default requestAuth;
