/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import returnItemService from 'service/returnItemService';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Button from 'components/base/Button';

export type SearchDataType = {
  id: string;
  product_name: string;
  variation: string;
  barcode: string;
  units: string;
  total_price: string;
  remainder: string;
  date_order: string;
};

interface OpenSearchReturningTableProps {
  setOpenSearch: (show: boolean) => void;
  returnItemData?: any;
  setReturnItemData?: any;
  senderId?: any;
  focusRef?: any;
  isAutoTab?: any;
}
const OpenSearchReturningTable = ({
  setOpenSearch,
  returnItemData,
  setReturnItemData,
  senderId,
  isAutoTab,
  focusRef
}: OpenSearchReturningTableProps) => {
  const { t, i18n } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [getBarcode, setGetBarcode] = useState('');

  const { data } = useQuery(
    ['GET_PRODUCT', searchValue, senderId, getBarcode],
    () => {
      if (senderId !== '') {
        const res = returnItemService
          .getBySenderId(senderId, {
            offset: 0,
            limit: 100,
            name: searchValue,
            barcode: getBarcode
          })
          .then((res: any) => {
            return res;
          });

        return res;
      }
    },
    {
      enabled:
        !!senderId && (!searchValue || searchValue.length > 2 || !getBarcode)
    }
  );

  const searchData: SearchDataType[] = useMemo(() => {
    return (
      data?.products?.map((items: any) => {
        return {
          product_name:
            data?.is_product === true ? items?.name : items?.nomenclature?.name,
          barcode:
            data?.is_product === true
              ? items?.barcode
              : items?.nomenclature?.barcode,
          units: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          total_price: items?.price_in
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.price_in)
                .replace(/,/g, ' ')
            : 0,
          remainder: items?.quantity ?? 0,
          date_order: new Date(items?.date_created)
            .toLocaleDateString('en-GB')
            .replace(/\//g, '-'),

          // this dataes need for static rendering dataes
          unit: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          measure_unit_id: items?.nomenclature?.measure_unit?.id,
          amount: items?.quantity ? items?.quantity : 1,
          current_balance: items?.quantity ? items?.quantity : 1,
          currency_id: items?.currency_id,
          package_quantity: items?.package_quantity ?? 0,
          nomenclature_id: items?.nomenclature_id,
          parent_product_id: items?.parent_product_id,
          product_id: items?.id,
          price: items?.price_in,
          is_vat: items?.is_vat ? items?.is_vat : false
        };
      }) ?? []
    );
  }, [data, searchValue, getBarcode, t, i18n?.language]);

  const handleSelections = () => {
    const selectedDates = table
      .getSelectedRowModel()
      .rows.map(row => row.original);

    const updatedProductInvoiceData = [...returnItemData]; // Make a copy of the current state

    selectedDates.forEach(selectedDate => {
      const foundProductIndex = updatedProductInvoiceData.findIndex(
        item => item?.barcode === selectedDate.barcode
      );
      if (foundProductIndex !== -1) {
        updatedProductInvoiceData[foundProductIndex].amount += 1;
      } else {
        updatedProductInvoiceData.push(selectedDate);
      }
    });

    setReturnItemData(updatedProductInvoiceData);
    if (isAutoTab) {
      // when auto tab true focus to quantity
      setTimeout(() => {
        const firstProductIndex = 0;
        focusRef(`amount_${firstProductIndex}`);
      }, 500);
    }
    setOpenSearch(false);
  };

  const searchTableColumns: ColumnDef<SearchDataType>[] = [
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'variation',
      header: t('variation'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'units',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'total_price',
      header: t('purchase_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'remainder',
      header: t('remainder'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'date_order',
      header: t('purchase_date'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: searchData,
    columns: searchTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div>
          <div className="mb-4 d-flex gap-2">
            <div className="form-icon-container">
              <Form.Floating>
                <Form.Control
                  type="text"
                  placeholder={t('scan_barcode')}
                  onFocus={e => e.target.select()}
                  onChange={e => setGetBarcode(e.target.value)}
                  className="form-icon-input"
                  autoComplete="off"
                />
                <label
                  htmlFor="floatingInputCustom"
                  className="form-icon-label text-700"
                >
                  {t('scan_barcode')}
                </label>
              </Form.Floating>
              <FontAwesomeIcon
                icon={faBarcode}
                className="text-900 fs-9 form-icon"
              />
            </div>
            <SearchBox
              placeholder={t('search')}
              onChange={e => setSearchValue(e?.target?.value)}
            />
          </div>
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
          <div className="d-flex justify-content-end">
            <Button
              className="mt-4"
              variant="primary"
              onClick={() => handleSelections()}
            >
              {t('add')}
            </Button>
          </div>
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default OpenSearchReturningTable;
