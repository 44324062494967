/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import customerEntityService from 'service/charterer';

export type HistoryType = {
  product_provider: string;
  currency: string;
  debt: string;
  credit: string;
  remainder: string;
  status: string;
  bg_color: string;
  text_color: string;
};

interface CustomersMaturityDateHistoryProps {
  selectedCustomersId?: any;
}

const CustomersMaturityDateHistory = ({
  selectedCustomersId
}: CustomersMaturityDateHistoryProps) => {
  const { t, i18n } = useTranslation();

  const { data } = useQuery(
    ['GET_HISTORY_ID'],
    () => {
      if (selectedCustomersId) {
        const res = customerEntityService
          .getListHistorySettlement({
            offset: 0,
            limit: 100,
            debit_credit_id: selectedCustomersId
          })
          .then((res: any) => res?.debit_credit_settlements);
        return res;
      }
    },
    { enabled: !!selectedCustomersId }
  );

  const historyData: HistoryType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          product_provider: items?.creditor?.name,
          currency: items?.currency?.name?.[i18n?.language],
          debt: items?.amount_debit ?? 0,
          credit: items?.amount_credit ?? 0,
          remainder:
            (parseFloat(items?.amount_debit) || 0) -
            (parseFloat(items?.amount_credit) || 0),
          status: items?.status?.name?.[i18n?.language],
          creditor_id: items?.creditor_id,
          debitor_id: items?.debitor_id,
          bg_color: items?.status?.background_color,
          text_color: items?.status?.text_color,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const settelementDataTableColumns: ColumnDef<HistoryType>[] = [
    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'debt',
      header: t('debit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'credit',
      header: t('credit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'remainder',
      header: t('remainder'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        return (
          /* prettier-ignore */
          <div
            style={{
              background: `${rowData?.row?.original?.bg_color}`,
              padding: '8px 10px',
              textAlign: 'center',
              borderRadius: '8px',
              color: `${rowData?.row?.original?.text_color}`,
              fontWeight: "600"
            }}
          >
            {rowData?.row?.original?.status}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: historyData,
    columns: settelementDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div>
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default CustomersMaturityDateHistory;
