/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const activityService = {
  getList: (params: any) => request.get('/guidebook/activity-kind', { params }),
  getListForRegister: (params: any) =>
    request.get('/activity-kind', { params }),
  getActivityById: (id: any) => request.get(`/activity-kind/${id}`),
  createActivity: (data: any) => request.post('/guidebook/activity-kind', data),
  deleteActivity: (id: any) => request.delete(`/guidebook/activity-kind/${id}`),
  updateActivity: (id: any, data: any) =>
    request.put(`/guidebook/activity-kind/${id}`, data)
};

export default activityService;
