/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { ColumnDef } from '@tanstack/react-table';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import warehouseService from 'service/warehouse';
import Button from 'components/base/Button';
import legalEntitySettingsService from 'service/legalEntitySettings';
import ndsService from 'service/nds';
import { showAlert } from 'store/alert/alert.thunk';
import productSearchService from 'service/productSearch';
import './styles.scss';

export type AcceptanceType = {
  product_name: string;
  barcode: string;
  currency: string;
  units: string;
  status: string;
  create_date: string;
  guid: string;
  quantity_fact: string;
  quantity: string;
  cost: string;
  discrepancies: string;
};

const SwitchControl = ({ field, label }: { field: any; label: any }) => {
  return (
    <Form.Check
      type="switch"
      label={label}
      className="cursor-pointer"
      checked={field.value}
      onChange={e => field.onChange(e.target.checked)}
    />
  );
};

interface AcceptanceOfApplication {
  changedOrderItems?: any;
  setChangedOrderItems?: any;
  orderId?: any;
  setOpenModal: (show: boolean) => void;
}

const AcceptanceOfApplication = ({
  changedOrderItems,
  setChangedOrderItems,
  orderId,
  setOpenModal
}: AcceptanceOfApplication) => {
  const { t } = useTranslation();
  const { control, register, getValues, setValue } = useForm();
  const cashboxId = useSelector((state: any) => state?.shiftId?.shiftId);
  const dispatch: Dispatch<any> = useDispatch();

  const [warehouseOption, setWarehouseOption] = useState<any>([]);
  const [ndsCost, setNdsCost] = useState('');
  const [ndsWholsale, setNdsWholsale] = useState('');
  const [nds, setNds] = useState('');

  //GET Warehouse Entity to options
  useQuery(['GET_WAREHOUSE'], async () => {
    await warehouseService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.ware_houses?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));
        setWarehouseOption(options);
      });
  });

  //GET Warehouse Entity to options
  useQuery(['GET_SETTING'], async () => {
    await legalEntitySettingsService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        setNdsCost(res?.legal_entity_settings[0].extra_charge);
        setNdsWholsale(res?.legal_entity_settings[0].extra_charge_wholesale);
      });
  });

  //GET Warehouse Entity to options
  useQuery(['GET_NDS'], async () => {
    await ndsService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      setNds(res?.vats[0].value);
    });
  });

  const acceptanceTableColumns: ColumnDef<AcceptanceType>[] = [
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'price_in',
      header: t('purchase_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'cost',
      header: t('wholesale_price'),
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '0';

        const [value, setValue] = useState(amount);

        const updateProductInvoiceData = (
          name: string,
          value: string,
          index: any
        ) => {
          const updatedItems = changedOrderItems?.map((item: any, i: any) => {
            if (i === +index) {
              return {
                ...item,
                [name]: +value,
                ['amount_discrepancies']: +item?.quantity * +value
              };
            }
            return item;
          });
          setChangedOrderItems(updatedItems);
        };

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name, value } = e.target;
          updateProductInvoiceData(name, value, index);
        };

        return (
          <div>
            <Form.Group>
              <Form.Control
                type="number"
                name="cost"
                value={value}
                onChange={e => setValue(e.target.value)}
                onBlur={(e: any) => onBlur(e, rowData.row?.id)}
                onFocus={e => e.target.select()}
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'price',
      header: t('price_difference'),
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '0';

        const [value, setValue] = useState(amount);

        const updateProductInvoiceData = (
          name: string,
          value: string,
          index: any
        ) => {
          const updatedItems = changedOrderItems?.map((item: any, i: any) => {
            if (i === +index) {
              return { ...item, [name]: +value };
            }
            return item;
          });
          setChangedOrderItems(updatedItems);
        };

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name, value } = e.target;
          updateProductInvoiceData(name, value, index);
        };

        return (
          <div>
            <Form.Group>
              <Form.Control
                type="number"
                name="price"
                value={value}
                onChange={e => setValue(e.target.value)}
                onBlur={(e: any) => onBlur(e, rowData.row?.id)}
                onFocus={e => e.target.select()}
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'amount',
      header: t('application_quantity'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'quantity_fact',
      header: t('short_quantity_shipment'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'quantity',
      header: t('short_quantity_fact'),
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '0';

        const [value, setValue] = useState(amount);

        const updateProductInvoiceData = (
          name: string,
          value: string,
          index: any
        ) => {
          const updatedItems = changedOrderItems?.map((item: any, i: any) => {
            if (i === +index) {
              return {
                ...item,
                [name]: +value,
                ['discrepancies']: +item?.quantity_fact - +value
              };
            }
            return item;
          });
          setChangedOrderItems(updatedItems);
        };

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name, value } = e.target;
          updateProductInvoiceData(name, value, index);
        };

        return (
          <div>
            <Form.Group>
              <Form.Control
                type="number"
                name="quantity"
                value={value}
                onChange={e => setValue(e.target.value)}
                onBlur={(e: any) => onBlur(e, rowData.row?.id)}
                onFocus={e => e.target.select()}
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'discrepancies',
      header: t('short_no_expoenses'),
      cell: rowData => {
        const quantityFact = +rowData?.row?.original?.quantity_fact;
        const quantity = +rowData?.row?.original?.quantity;

        return <div>{quantityFact - quantity}</div>;
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'amount_discrepancies',
      header: t('asmount_discrepancy'),
      cell: rowData => {
        const cost = +rowData?.row?.original?.cost;
        const discrepancies = +rowData?.row?.original?.discrepancies;

        return <div>{cost * discrepancies}</div>;
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'total_price',
      header: t('final_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: changedOrderItems,
    columns: acceptanceTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const handleAccept = () => {
    const { warehouse } = getValues();

    const selectedDates = table
      .getSelectedRowModel()
      .rows?.map(row => row.original);

    const createdData = selectedDates?.map((items: any) => ({
      name: items?.product_name,
      barcode: items?.barcode,
      quantity_fact: items?.quantity_fact,
      quantity: +items?.quantity ?? 0,
      price_in: +items?.price_in ?? 0,
      price_wholesale: items?.price_wholesale ?? 0,
      price: +items?.price ?? 0,
      nomenclature_id: items?.nomenclature_id,
      parent_product_id: items?.product_id,
      product_id: items?.product_id,
      order_item_id: items?.guid,
      vat: +nds ?? 0

      //   package_quantity: +item?.amount ?? 0,
      //  is_vat: isVatPayerValue,
    }));

    const newSavedData = {
      invoice: {
        cashbox_id: cashboxId,
        warehouse_id: warehouse
      },
      order_id: orderId,
      product: createdData
    };

    console.log('newSavedData', newSavedData);

    if (
      cashboxId !== '' &&
      createdData?.length > 0 &&
      warehouse !== undefined
    ) {
      productSearchService.createProductOrder(newSavedData).then((res: any) => {
        if (res) {
          setOpenModal(false);
          setChangedOrderItems([]);
          dispatch(
            showAlert({
              title: t('application_successfully_accepted'),
              type: 'success'
            })
          );
        }
      });
    } else {
      if (cashboxId === '') {
        dispatch(showAlert({ title: t('shift_not_open') }));
      }
      if (warehouse === undefined) {
        dispatch(showAlert({ title: t('warehouse_must_selected') }));
      }

      if (createdData?.length === 0) {
        dispatch(showAlert({ title: t('no_item_selected') }));
      }
    }
  };

  const sum = changedOrderItems.reduce((acc: any, item: any) => {
    const allCost = item?.total_price === undefined ? 0 : +item.total_price;
    return acc + allCost;
  }, 0);

  useEffect(() => {
    if (warehouseOption?.length === 1) {
      setValue('warehouse', warehouseOption[0].value);
    }
  }, [warehouseOption, setValue]);

  return (
    <div>
      <Form noValidate className="app-rows mb-4">
        <Form.Floating>
          <Form.Control
            {...register('markup')}
            type="number"
            placeholder={t('markup_in')}
            value={ndsCost}
            onChange={e => setNdsCost(e?.target?.value)}
            onFocus={e => e.target.select()}
          />
          <label htmlFor="floatingInputCustom">{t('markup_in')}</label>
        </Form.Floating>

        <Form.Floating>
          <Form.Control
            {...register('markup_wholesale')}
            type="number"
            placeholder={t('markup_wholesale')}
            value={ndsWholsale}
            onChange={e => setNdsWholsale(e?.target?.value)}
            onFocus={e => e.target.select()}
          />
          <label htmlFor="floatingInputCustom">{t('markup_wholesale')}</label>
        </Form.Floating>

        <Form.Group>
          <div className="react-select-container">
            <Controller
              name="warehouse"
              control={control}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select {...field}>
                    <option className="d-none" value=""></option>
                    {warehouseOption?.map((option: any) => (
                      <option
                        key={option.value}
                        value={option.value}
                        className="option"
                      >
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">{t('warehouse')}</label>
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group className="d-flex align-items-center my-auto">
          <Controller
            name="update_sell"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <SwitchControl field={field} label={t('update_sale_price')} />
            )}
          />
        </Form.Group>

        <Form.Group className="d-flex align-items-center my-auto">
          <Controller
            name="is_vat"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <SwitchControl field={field} label={t('variable_product')} />
            )}
          />
        </Form.Group>
      </Form>

      <AdvanceTableProvider {...table}>
        <div>
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>

      <div className="d-flex justify-content-end align-items-center gap-3 mt-3">
        <div
          style={{
            border: '1px solid #8200bf',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'space-between',
            gap: '20px',
            alignItems: 'center',
            padding: '8px'
          }}
        >
          <p className="mb-0 text-bold">{t('total_amount')}:</p>
          <span>{sum ?? 0}</span>
        </div>

        <Button variant="primary" onClick={() => handleAccept()}>
          {t('accept')}
        </Button>
      </div>
    </div>
  );
};

export default AcceptanceOfApplication;
