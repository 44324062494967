/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';

export type SettelementData = {
  product_provider: string;
  currency: string;
  debt: string;
  credit: string;
  remainder: string;
  creditor_id: string;
  currency_id: string;
};

interface SettelementTableProps {
  suppliersData?: any;
}

const SettelementTable = ({ suppliersData }: SettelementTableProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const navigateTo = (row: any) => {
    navigate(
      `/dtkt/suppliers/suppliers-result/${row?.original?.creditor_id}?currency_id=${row?.original?.currency_id}`
    );
  };

  const settelementData: SettelementData[] = useMemo(() => {
    const resultData =
      suppliersData?.map((items: any) => {
        const remainder =
          (parseFloat(items?.amount_debit) || 0) -
          (parseFloat(items?.amount_credit) || 0);
        return {
          product_provider: items?.creditor?.name,
          creditor_id: items?.creditor_id,
          currency_id: items?.currency_id,
          currency: items?.currency?.name?.[i18n?.language],
          debt: items?.amount_debit
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.amount_debit)
                .replace(/,/g, ' ')
            : 0,
          credit: items?.amount_credit
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.amount_credit)
                .replace(/,/g, ' ')
            : 0,
          remainder: remainder
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(remainder)
                .replace(/,/g, ' ')
            : 0
        };
      }) ?? [];

    return resultData;
  }, [suppliersData, t, i18n]);

  const settelementDataTableColumns: ColumnDef<SettelementData>[] = [
    {
      accessorKey: 'product_provider',
      header: t('supplier_name'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'debt',
      header: t('debit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'credit',
      header: t('credit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'remainder',
      header: t('remainder'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: settelementData,
    columns: settelementDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <AdvanceTableProvider {...table}>
      <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          navigateTo={navigateTo}
        />
        <AdvanceTableFooter pagination />
      </div>
    </AdvanceTableProvider>
  );
};

export default SettelementTable;
