/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useMemo } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Button from 'components/base/Button';
import cashService from 'service/cash';
import useAdvanceTable from 'hooks/useAdvanceTable';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import SearchBox from 'components/common/SearchBox';
import CreateCashRegister from './CreateCashRegister';

export const cashBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'directories',
    url: '/directories/stock'
  },
  {
    label: 'cash',
    active: true
  }
];

export type cashType = {
  name: string;
  status: boolean;
  is_open: boolean;
  guid: string;
};

const CashRegister = () => {
  const { t } = useTranslation();

  const [openCash, setOpenCash] = useState(false);
  const [selectedCashId, setSelectedCashId] = useState('');

  //Get Cash
  const { data, refetch } = useQuery(['GET_CASH'], () => {
    const res = cashService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.legal_entity_cashes);
    return res;
  });

  const bonusData: cashType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.name,
          status: items?.status === true ? t('active') : t('blocked'),
          is_open: items?.is_open === true ? t('open') : t('close'),
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t]);

  const cashTableColumns: ColumnDef<cashType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-center gap-2">
          <Button
            variant="hover"
            onClick={() => {
              setOpenCash(true);
              setSelectedCashId(rowData?.row?.original?.guid);
            }}
          >
            <FeatherIcon icon="edit-2" className="cursor-pointer" size={20} />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'name',
      header: t('name_cash_desk'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'is_open',
      header: t('cash_states'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: bonusData,
    columns: cashTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={cashBreadcrumbItems} />

      <div className="mb-9">
        <h2 className="mb-5">{t('cash')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex justify-content-between">
              <SearchBox
                placeholder={t('search')}
                onChange={handleSearchInputChange}
              />

              <div className="d-flex justify-content-between gap-2">
                <Button variant="primary" onClick={() => setOpenCash(true)}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  {t('add')}
                </Button>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>

          <Offcanvas
            show={openCash}
            onHide={() => {
              setOpenCash(false);
              setSelectedCashId('');
            }}
            placement="end"
            backdrop="static"
            keyboard={false}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
                {t('new')}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <CreateCashRegister
                setOpenCash={setOpenCash}
                selectedCashId={selectedCashId}
                refetch={refetch}
                setSelectedCashId={setSelectedCashId}
              />
            </Offcanvas.Body>
          </Offcanvas>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default CashRegister;
