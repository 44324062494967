/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import moneyIcon from 'assets/img/icons/money-icon.svg';
import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import customerEntityService from 'service/charterer';

interface ReturnHistorySettlementProps {
  setOpenReturn: (show: boolean) => void;
  selectedItems?: any;
  refetch?: any;
}

const ReturnHistorySettlement = ({
  selectedItems,
  setOpenReturn,
  refetch
}: ReturnHistorySettlementProps) => {
  const { t } = useTranslation();
  const cashboxId = useSelector((state: any) => state?.shiftId?.shiftId);
  const dispatch: Dispatch<any> = useDispatch();

  const onSubmit = () => {
    if (cashboxId === '') {
      dispatch(showAlert({ title: t('shift_not_open') }));
      return;
    }

    const createdData = {
      id: selectedItems?.guid,
      cashbox_id: cashboxId
    };

    if (cashboxId !== '') {
      customerEntityService.createRoleBackSettlement(createdData)?.then(res => {
        if (res) {
          dispatch(
            showAlert({ title: t('successfully_added'), type: 'success' })
          );
          refetch();
          setOpenReturn(false);
        }
      });
    }
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <img src={moneyIcon} alt="icon" />
      <p
        style={{
          marginTop: '8px',
          marginBottom: '0',
          fontSize: '20px',
          fontWeight: '700',
          padding: '0'
        }}
      >
        {selectedItems?.amount} {selectedItems?.currency}
      </p>
      <p
        style={{
          textAlign: 'center',
          fontSize: '13px',
          padding: '0',
          marginBottom: '20px',
          marginTop: '5px'
        }}
      >
        {t('want_return_amount')}
      </p>

      <div className="w-100 d-flex gap-2 justify-content-between">
        <Button
          className="w-100"
          variant="phoenix-secondary"
          onClick={() => setOpenReturn(false)}
        >
          {t('no')}
        </Button>
        <Button
          className="w-100"
          variant="primary"
          type="button"
          onClick={() => onSubmit()}
        >
          {t('yes')}
        </Button>
      </div>
    </div>
  );
};

export default ReturnHistorySettlement;
