/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SearchBonus from './SearchBonus';
import Button from 'components/base/Button';
import applicationBonusService from 'service/applicationBonus';
import paymentBonusService from 'service/paymentBonus';
import activationBonusService from 'service/activationBonus';
import legalEntityCurrencyService from 'service/legalEntityCurrency';
import productSearchService from 'service/productSearch';
import bonusItemService from 'service/bonusItem';
import { showAlert } from 'store/alert/alert.thunk';

interface AddBonusProduct {
  setOpenAddBonus: (show: boolean) => void;
  refetch?: any;
  setSelectedResultId?: any;
  selectedResultId?: any;
}

const AddBonusProduct = ({
  setOpenAddBonus,
  selectedResultId,
  refetch,
  setSelectedResultId
}: AddBonusProduct) => {
  const { t, i18n } = useTranslation();

  const statusOption = [
    {
      value: true,
      label: t('active')
    },
    {
      value: false,
      label: t('blocked')
    }
  ];
  const { pathname } = useLocation();
  const bonusId = pathname.split('/').pop();
  const { register, control, reset, getValues } = useForm();
  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();
  const inputBarcodeRef = useRef<HTMLInputElement | any>();
  const hasFetchedData = useRef(false);

  const [openSearch, setOpenSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bonusApplicationOption, setBonusApplicationOption] = useState([]);
  const [paymentBonusOption, setPaymentBonusOption] = useState<any>([]);
  const [bonusActivationOption, setBonusActivationOption] = useState([]);
  const [currencyOption, setCurrencyOption] = useState([]);
  const [searchedValue, setSearchedValue] = useState<any>('');
  const [selectedCurrencyId, setSelectedCurrencyId] = useState<any>('');
  const [focusToInput, setFocusToInput] = useState(false);

  console.log('selectedCurrencyId', selectedCurrencyId);

  useQuery(['GET_BONUS_APPLICATION'], async () => {
    await applicationBonusService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.bonus_applications?.map((option: any) => ({
          value: option.id,
          label: option.name?.[i18n?.language]
        }));
        setBonusApplicationOption(options);
      });
  });

  useQuery(['GET_BONUS_PAYMENT'], async () => {
    await paymentBonusService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.bonus_payments?.map((option: any) => ({
          value: option.id,
          label: option.name?.[i18n?.language]
        }));
        setPaymentBonusOption(options);
      });
  });

  useQuery(['GET_BONUS_ACTIVATION'], async () => {
    await activationBonusService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.bonus_activations?.map((option: any) => ({
          value: option.id,
          label: option.name?.[i18n?.language]
        }));
        setBonusActivationOption(options);
      });
  });

  useQuery(['GET_CURRENCY'], async () => {
    await legalEntityCurrencyService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entity_currencies?.map((option: any) => ({
          value: option?.currency_id,
          label: option?.currency?.name?.[i18n?.language]
        }));
        setCurrencyOption(options);
      });
  });

  //Fetch Users By ID
  const fetchData = () => {
    if (selectedResultId === '') return setLoading(false);

    bonusItemService
      .getBonusItemById(selectedResultId)
      .then((res: any) => {
        const computed = {
          barcode: res?.barcode,
          product_name: res?.product?.name,
          scope_bonus: res?.bonus_application_id,
          calculation_method: res?.bonus_activity_id,
          payment_method: res?.bonus_payment_id,
          currency: res?.currency_id,
          status: res?.status ?? false,
          nomenclature_id: res?.nomenclature_id,
          quantity: res?.buy_quantity ?? 0,
          product_sold: res?.quantity ?? 0,
          sum: res?.amount ?? 0
        };
        reset(computed);
        setSearchedValue(res?.barcode);
        setSelectedCurrencyId(res?.bonus_payment_id);
      })
      .finally(() => setLoading(false));
  };

  const handleGetProduct = (e: any) => {
    if (e.key === 'Enter') {
      if (searchedValue !== '') {
        productSearchService
          .getListProduct({
            offset: 0,
            limit: 20,
            barcode: searchedValue ? searchedValue : undefined
          })
          .then((res: any) => {
            if (res) {
              const computed = {
                product_name: res?.products?.[0]?.name,
                nomenclature_id: res?.products?.[0]?.nomenclature_id,
                scope_bonus: '',
                calculation_method: '',
                payment_method: '',
                quantity: '',
                sum: '',
                currency: '',
                product_sold: '',
                status: ''
              };

              if (inputRef.current) {
                inputRef?.current.focus();
              }
              reset(computed);
            }
          });
      }
    }
  };

  const onSubmit = () => {
    const data = getValues();

    if (selectedResultId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    const createdData = {
      barcode: data?.barcode === undefined ? searchedValue : data?.barcode,
      barcode_original:
        data?.barcode === undefined ? searchedValue : data?.barcode,
      bonus_activity_id: data?.calculation_method,
      bonus_application_id: data?.scope_bonus,
      bonus_id: bonusId,
      bonus_payment_id: data?.payment_method,
      currency_id: data?.currency,
      status: data.status === 'true' ? true : false,
      nomenclature_id: data.nomenclature_id,
      buy_quantity: +data?.quantity ?? 0,
      quantity: +data?.product_sold ?? 0,
      amount: +data?.sum ?? 0
    };

    if (createdData?.barcode !== '') {
      setLoading(true);

      bonusItemService.createBonusItem(createdData).then(res => {
        if (res) {
          setLoading(false);
          setOpenAddBonus(false);
          refetch();
          setSelectedResultId('');
          dispatch(
            showAlert({
              title: `${t('product')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
        }
      });
    }
  };

  const update = (data: any) => {
    const updateData = {
      barcode: data?.barcode === undefined ? searchedValue : data?.barcode,
      barcode_original:
        data?.barcode === undefined ? searchedValue : data?.barcode,
      bonus_activity_id: data?.calculation_method,
      bonus_application_id: data?.scope_bonus,
      bonus_id: bonusId,
      bonus_payment_id: data?.payment_method,
      currency_id: data?.currency,
      status: data.status === 'true' ? true : false,
      nomenclature_id: data.nomenclature_id,
      buy_quantity: +data?.quantity ?? 0,
      quantity: +data?.product_sold ?? 0,
      amount: +data?.sum ?? 0,
      id: selectedResultId
    };

    bonusItemService.updateBonusItem(selectedResultId, updateData).then(res => {
      if (res) {
        setLoading(false);
        setOpenAddBonus(false);
        refetch();
        setSelectedResultId('');
        dispatch(
          showAlert({
            title: t('product_successfully_updated'),
            type: 'success'
          })
        );
      }
    });
  };

  useEffect(() => {
    if (selectedResultId !== '' && !hasFetchedData.current) {
      fetchData();
      hasFetchedData.current = true;
    }
  }, [selectedResultId]);

  useEffect(() => {
    if (inputBarcodeRef.current && searchedValue === '') {
      inputBarcodeRef.current.focus();
    }
  }, [inputBarcodeRef, searchedValue]);

  useEffect(() => {
    if (inputRef.current && focusToInput) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 500);
    }
  }, [focusToInput]);

  return (
    <>
      <Form
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-2">
          <Col className="d-flex gap-2">
            <Controller
              name="barcode"
              control={control}
              render={({ field }) => (
                <Form.Floating className="md-4 flex-grow-1 bd-highlight">
                  <Form.Control
                    {...field}
                    ref={inputBarcodeRef}
                    type="text"
                    value={searchedValue}
                    placeholder={t('scan_barcode')}
                    onChange={e => setSearchedValue(e?.target?.value)}
                    onKeyDown={e => handleGetProduct(e)}
                    onFocus={e => {
                      e.target.select();
                    }}
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('scan_barcode')}
                  </label>
                </Form.Floating>
              )}
            />

            <Button
              className="bd-highlight"
              variant="primary"
              type="button"
              onClick={() => {
                setOpenSearch(true);
                setFocusToInput(false);
              }}
            >
              {t('search')}
            </Button>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('product_name')}
                type="text"
                placeholder={t('product_names')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('product_names')}</label>
            </Form.Floating>
          </Col>
        </Row>

        {/* <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="variation"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {variationOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">Вариации</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row> */}

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="scope_bonus"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} ref={inputRef}>
                      <option className="d-none" value=""></option>
                      {bonusApplicationOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('scope_application')}
                    </label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="calculation_method"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {bonusActivationOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('bonus_calculation_method')}
                    </label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="payment_method"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = paymentBonusOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setSelectedCurrencyId(selectedOption?.value);
                        }
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {paymentBonusOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('bonus_payment')}
                    </label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('quantity')}
                type="text"
                placeholder="За количество приобретенного товара"
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">
                {t('quantity_goods_pruchased')}
              </label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('sum')}
                type="text"
                placeholder={t('amounts')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('amounts')}</label>
            </Form.Floating>
          </Col>
        </Row>

        {selectedCurrencyId === '2d98fabd-4597-4681-a7ad-ac47cfa652fd' && (
          <Row className="mb-2">
            <Col>
              <div className="react-select-container">
                <Controller
                  name="currency"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Floating>
                      <Form.Select {...field}>
                        <option className="d-none" value=""></option>
                        {currencyOption?.map((option: any) => (
                          <option
                            key={option.value}
                            value={option.value}
                            className="option"
                          >
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>

                      <label htmlFor="floatingInputCustom">
                        {t('currency')}
                      </label>
                    </Form.Floating>
                  )}
                />
              </div>
            </Col>
          </Row>
        )}

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('product_sold')}
                type="text"
                placeholder={t('number_items_bonus')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">
                {t('number_items_bonus')}
              </label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {statusOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('status')}</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="button"
          onClick={() => onSubmit()}
          loading={loading}
          loadingPosition="start"
        >
          {t('add')}
        </Button>
      </Form>

      <Modal
        show={openSearch}
        onHide={() => setOpenSearch(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('search')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchBonus
            setOpenSearch={setOpenSearch}
            reset={reset}
            setSearchedValue={setSearchedValue}
            setFocusToInput={setFocusToInput}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddBonusProduct;
