/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import { ColumnDef } from '@tanstack/react-table';
import { Modal } from 'react-bootstrap';
import { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { Form } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { UzbekLatin } from 'flatpickr/dist/l10n/uz_latn.js';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import { ComingResult } from 'data/coming';
import UpdateSelectionAmount from './UpdateSelectionAmount';
import orderItemService from 'service/orderItem';
import orderInService from 'service/orderIn';
import Button from 'components/base/Button';
import orderService from 'service/order';
import { showAlert } from 'store/alert/alert.thunk';

const ComingResultTable = () => {
  const { t, i18n } = useTranslation();

  const { pathname } = useLocation();
  const orderId = pathname.split('/').pop();
  const dispatch: Dispatch<any> = useDispatch();

  const [openAmount, setOpenAmount] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [finishLoading, setFinishLoading] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<any>({
    rowId: '',
    nomenclature_id: '',
    recipient_id: '',
    quantity_asked: ''
  });
  const [changedOrderItems, setChangedOrderItems] = useState<any>([]);
  const [showError, setShowError] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const [getRowGuid, setGetRowGuid] = useState('');

  const { refetch: refetchIn } = useQuery(
    ['GET_RETURN_IN_CAN_UPDATE', orderId],
    async () => {
      if (orderId) {
        await orderInService
          .getList({
            offset: 0,
            limit: 10
          })
          .then((res: any) => {
            if (res) {
              const updatedRes = res?.orders?.find(
                (el: any) => el?.id === orderId
              );
              setCanUpdate(updatedRes?.can_update ?? false);
            }
          });
      }
    },
    { enabled: !!orderId }
  );

  const { data, refetch } = useQuery(
    ['GET_ORDER_ITEM', orderId],
    () => {
      if (orderId) {
        const res = orderItemService
          .getOrderItemInById(orderId, {
            offset: 0,
            limit: 10
          })
          .then((res: any) => {
            return res?.order_items;
          });
        return res;
      }
    },
    { enabled: !!orderId }
  );

  const handleStartDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const startDateInMillis = date[0].getTime();
      setStartDate(startDateInMillis);
    } else {
      const startDateInMillis = date.getTime();
      setStartDate(startDateInMillis);
    }
  };

  const handleEndDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const endDateInMillis = date[0].getTime();
      setEndDate(endDateInMillis);
    } else {
      const endDateInMillis = date.getTime();
      setEndDate(endDateInMillis);
    }
  };

  const handleAcceptProduct = (id: any) => {
    if (id) {
      orderItemService
        .updateOrderItemIn(id, {
          status_id: '6e22cd85-070e-4779-afcb-8f0be603b60e'
        })
        .finally(() => {
          refetch();
        });
    }
  };

  const item = useMemo(() => {
    return changedOrderItems.find((item: any) => item.guid === getRowGuid);
  }, [changedOrderItems, getRowGuid]);

  const comingResultData: ComingResult[] = useMemo(() => {
    const resultData =
      data?.map((items: any) => {
        return {
          guid: items?.id,
          can_update_order_item: items?.can_update,
          nomenclature_id: items?.nomenclature_id,
          recipient_id: items?.recipient_id,
          product_name: items?.nomenclature?.name,
          product_id: items?.product_id,
          product_parent_id: items?.product_parent_id,
          barcode: items?.nomenclature?.barcode,
          units: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          measure_unit_id: items?.nomenclature?.measure_unit?.id,
          amount: items?.quantity_asked,
          status: items?.status?.name?.[i18n?.language],
          // currency: inOrderCurrency?.name ?? '',
          // currency_id: inOrderCurrency?.id ?? '',
          cost: items?.price_wholesale ?? 0,
          history: items?.history ?? 0,
          quantity_asked: items?.quantity_asked ?? 0,
          remainder: items?.total_quantity ?? 0,
          quantity_fact: items?.quantity_send ?? 0,
          quantity_accept: items?.quantity_fact ?? 0,
          discrepancies: items?.quantity_difference ?? 0,
          /* prettier-ignore */
          amount_discrepancies:
            items?.quantity_difference && items?.price_wholesale
              ? +items.quantity_difference * +items.price_wholesale
              : 0,
          /* prettier-ignore */
          final_price:
            items?.quantity_fact && items?.price_in_org
              ? +items.quantity_fact * +items.price_in_org
              : items?.quantity_asked && items?.price_in_org
                ? +items.quantity_asked * +items.price_in_org
                : 0,
          /* prettier-ignore */
          total_price:
            items?.quantity_fact && items?.price_wholesale_org
              ? +items.quantity_fact * +items.price_wholesale_org
              : items?.price_wholesale_org && items?.quantity_asked
                ? +items.price_wholesale_org * +items.quantity_asked
                : 0
        };
      }) ?? [];

    if (orderId) {
      setChangedOrderItems(resultData);
    }

    return resultData;
  }, [data, t, i18n?.language]);

  console.log('comingResultData', comingResultData);

  const comingResultTableColumns: ColumnDef<ComingResult>[] = [
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'cost',
      header: t('wholesale_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'remainder',
      header: t('remainder'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'quantity_asked',
      header: t('application_quantity'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'quantity_fact',
      header: t('short_quantity_fact'),
      cell: rowData => {
        const amount = String(rowData?.getValue());

        return (
          <div>
            {canUpdate ? (
              <Button
                variant="primary"
                onClick={() => {
                  setOpenAmount(true);
                  setSelectedRowId({
                    rowId: rowData?.row?.id,
                    nomenclature_id: rowData?.row?.original?.nomenclature_id,
                    recipient_id: rowData?.row?.original?.recipient_id,
                    quantity_asked: rowData?.row?.original?.quantity_asked
                  });
                }}
              >
                {amount}
              </Button>
            ) : (
              amount
            )}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'quantity_accept',
      header: t('short_qunatity_accept'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'discrepancies',
      header: t('short_no_expoenses'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'amount_discrepancies',
      header: t('asmount_discrepancy'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'final_price',
      header: t('final_purchase_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'total_price',
      header: t('final_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'accept_remainder',
      header: t('accept_remainder'),
      cell: rowData => {
        setGetRowGuid(rowData.row.original.guid);
        return (
          <div>
            <Button
              className="p-2"
              variant="primary"
              onClick={() => handleAcceptProduct(rowData?.row?.original?.guid)}
            >
              {t('accept_remainder')}
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: {
          style: { display: item?.can_update_order_item ? 'block' : 'none' }
        },
        cellProps: {
          className: 'text-900',
          style: { display: item?.can_update_order_item ? 'block' : 'none' }
        }
      }
    }
  ];

  const buyyersBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'applications',
      url: '/applications/outgoing'
    },
    {
      label: 'incoming',
      url: '/applications/coming'
    },
    {
      label: 'application_number',
      active: true
    }
  ];

  const table = useAdvanceTable({
    data: changedOrderItems,
    columns: comingResultTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const sum = changedOrderItems.reduce((acc: any, item: any) => {
    const allCost = item?.total_price === undefined ? 0 : +item.total_price;
    return acc + allCost;
  }, 0);

  const handleSave = () => {
    if (startDate === null || endDate === null) {
      setShowError(true);

      dispatch(
        showAlert({
          title: t('dates_are_no_choosen')
        })
      );
    }

    const createData = changedOrderItems?.map((el: any) => ({
      barcode: el?.barcode,
      name: el?.product_name,
      quantity_asked: el?.quantity_asked,
      quantity_send: el?.quantity_fact,
      currency_id: el?.currency_id,
      measure_unit_id: el?.measure_unit_id,
      nomenclature_id: el?.nomenclature_id,
      product_id: el?.product_id,
      product_parent_id: el?.product_parent_id,
      price: el?.price,
      price_in: el?.price_in,
      price_wholesale: el?.price_whosale,
      status_id: '3c22cd85-070e-4779-afcb-8f0be603b60c'
    }));

    const updateData = {
      order_id: orderId,
      order_items: createData
    };

    console.log('createData', createData);
    if (orderId !== '' && startDate !== null && endDate !== null) {
      setSaveLoading(true);

      orderService.getOrderById(orderId).then((res: any) => {
        if (res) {
          orderInService
            ?.updateOrderOut(orderId, {
              ...res,
              maturity_date: startDate ?? 0,
              date_auto_cancel: endDate ?? 0,
              status_id: '3c22cd85-070e-4779-afcb-8f0be603b60c'
            })
            .then((inresponse: any) => {
              if (inresponse) {
                orderItemService
                  .createOrderItem(updateData)
                  .then((createres: any) => {
                    if (createres) {
                      dispatch(
                        showAlert({
                          title: t('answered_successfully'),
                          type: 'success'
                        })
                      );
                    }
                  })
                  .finally(() => {
                    setShowError(false);
                    refetch();
                    refetchIn();
                  });
              }
            });
        }
      });
    }
  };

  const handleFinished = () => {
    const createData = changedOrderItems?.map((el: any) => ({
      barcode: el?.barcode,
      name: el?.product_name,
      quantity_asked: el?.quantity_asked,
      quantity_send: el?.quantity_fact,
      currency_id: el?.currency_id,
      measure_unit_id: el?.measure_unit_id,
      nomenclature_id: el?.nomenclature_id,
      product_id: el?.product_id,
      product_parent_id: el?.product_parent_id,
      price: el?.price,
      price_in: el?.price_in,
      price_wholesale: el?.price_whosale,
      status_id: '4d22cd85-070e-4779-afcb-8f0be603b60d'
    }));

    const updateData = {
      order_id: orderId,
      order_items: createData
    };
    if (orderId !== '') {
      setFinishLoading(true);
      orderService.getOrderById(orderId).then((res: any) => {
        if (res) {
          orderInService
            ?.updateOrderOut(orderId, {
              ...res,
              maturity_date: startDate ?? 0,
              date_auto_cancel: endDate ?? 0,
              status_id: '4d22cd85-070e-4779-afcb-8f0be603b60d'
            })
            .then((inresponse: any) => {
              if (inresponse) {
                orderItemService
                  .createOrderItem(updateData)
                  .then((createres: any) => {
                    if (createres) {
                      window.location.reload();
                    }
                  })
                  .finally(() => {
                    setShowError(false);
                    refetch();
                    refetchIn();
                  });
              }
            });
        }
      });
    }
  };

  const getFlatpickrLocale = () => {
    switch (i18n.language) {
      case 'ru':
        return Russian;
      case 'uz':
        return UzbekLatin;
      default:
        return undefined;
    }
  };

  return (
    <div>
      <PageBreadcrumb items={buyyersBreadcrumbItems} />

      <div>
        <h2 className="mb-5">{t('application_number')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          </div>

          <Modal
            show={openAmount}
            onHide={() => setOpenAmount(false)}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {t('selection_purchase')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <UpdateSelectionAmount
                setOpenAmount={setOpenAmount}
                changedOrderItems={changedOrderItems}
                setChangedOrderItems={setChangedOrderItems}
                selectedRowId={selectedRowId}
              />
            </Modal.Body>
          </Modal>
        </AdvanceTableProvider>
      </div>

      <div className="d-flex justify-content-end text-center gap-3 p-3 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300">
        <div
          className="box"
          style={{
            border: '1px solid #f5f5f5',
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'space-between',
            gap: '10px',
            alignItems: 'center',
            padding: '8px'
          }}
        >
          <p className="mb-0 text-bold">{t('total_amount')}:</p>
          <span>{sum ?? 0}</span>
        </div>

        <Form.Group className="md-4">
          <Form.Floating>
            <Flatpickr
              className={classNames('form-control', {
                error: showError === true ? true : false
              })}
              options={{
                nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                locale: getFlatpickrLocale(),
                monthSelectorType: 'static',
                onDayCreate: (...[, , , dayElem]) => {
                  if (
                    dayElem.dateObj.getDay() === 5 ||
                    dayElem.dateObj.getDay() === 6
                  ) {
                    dayElem.className += ' weekend-days';
                  }
                },
                dateFormat: 'M j, Y',
                disableMobile: true
              }}
              value={startDate}
              onChange={handleStartDateChange}
              placeholder={t('maturity_date')}
            />
            <label htmlFor="floatingInputCustom">{t('maturity_date')}</label>
          </Form.Floating>
        </Form.Group>

        <Form.Group className="md-4">
          <Form.Floating>
            <Flatpickr
              className={classNames('form-control', {
                error: showError === true ? true : false
              })}
              options={{
                nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                locale: getFlatpickrLocale(),
                monthSelectorType: 'static',
                onDayCreate: (...[, , , dayElem]) => {
                  if (
                    dayElem.dateObj.getDay() === 5 ||
                    dayElem.dateObj.getDay() === 6
                  ) {
                    dayElem.className += ' weekend-days';
                  }
                },
                dateFormat: 'M j, Y',
                disableMobile: true
              }}
              value={endDate}
              onChange={handleEndDateChange}
              placeholder={t('automatic_repayment_date')}
            />
            <label htmlFor="floatingInputCustom">
              {t('automatic_repayment_date')}
            </label>
          </Form.Floating>
        </Form.Group>

        <Button
          disabled={orderId === '' || !canUpdate}
          variant="primary"
          onClick={() => handleSave()}
          loading={saveLoading}
        >
          {t('answer')}
        </Button>
        <Button
          disabled={orderId === '' || !canUpdate}
          variant="danger"
          onClick={() => handleFinished()}
          loading={finishLoading}
        >
          {t('reject')}
        </Button>
      </div>
    </div>
  );
};

export default ComingResultTable;
