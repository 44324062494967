import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Filter from './Filter';
import SettelementCutomerTable from './SettelementCutomerTable';

export const customersBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'dt_kt',
    url: '/dtkt/provider'
  },
  {
    label: 'settlement_customer',
    active: true
  }
];

const SettelementWithCustomers = () => {
  const { t } = useTranslation();
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [customersData, setCustomersData] = useState([]);

  return (
    <div>
      <PageBreadcrumb items={customersBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{t('settlement_customer')}</h2>

        <Filter
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          setCustomersData={setCustomersData}
        />
        <SettelementCutomerTable customersData={customersData} />
      </div>
    </div>
  );
};

export default SettelementWithCustomers;
