/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import countryService from 'service/country';
import areaService from 'service/district';
import regionService from 'service/region';
import usersService from 'service/users';
import { showAlert } from 'store/alert/alert.thunk';
import customersService from 'service/customer';

interface CreateBuyersProps {
  setOpenCreate: (show: boolean) => void;
  setSelectedContactId?: any;
}

const CreateBuyers = ({
  setOpenCreate,
  setSelectedContactId
}: CreateBuyersProps) => {
  const { t, i18n } = useTranslation();

  const genderOption = [
    {
      value: 'MALE',
      label: t('male')
    },
    {
      value: 'FEMALE',
      label: t('female')
    }
  ];
  const { register, handleSubmit, control } = useForm();
  const dispatch: Dispatch<any> = useDispatch();

  const [areaOption, setAreaOption] = useState([]);
  const [regionOption, setRegionOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  const [usersStatusOption, setUsersStatusOption] = useState([]);

  //GET Country to options
  useQuery(['GET_COUNTRY'], async () => {
    await countryService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.countries?.map((country: any) => ({
        value: country.id,
        label: country.name?.[i18n?.language]
      }));
      setCountryOption(options);
    });
  });

  //GET Area to options
  useQuery(['GET_AREA'], async () => {
    await areaService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.districts?.map((currency: any) => ({
        value: currency.id,
        label: currency.name?.[i18n?.language]
      }));
      setAreaOption(options);
    });
  });

  //GET Region to options
  useQuery(['GET_REGION'], async () => {
    await regionService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.regions?.map((region: any) => ({
        value: region.id,
        label: region.name?.[i18n?.language]
      }));
      setRegionOption(options);
    });
  });

  //GET Region to options
  useQuery(['GET_USERS_STATUS'], async () => {
    await usersService
      .getUsersStatus({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.data?.user_statuses?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));
        setUsersStatusOption(options);
      });
  });

  const onSubmit: SubmitHandler<FieldValues> = data => {
    const createdData = {
      birth_date: Date.parse(data?.date_birth),
      first_name: data?.first_name,
      gender: data?.gender,
      is_master: false,
      last_name: data?.last_name,
      password: data?.password,
      phone_number: data?.phone_number,
      surname: data?.surname,
      country_id: data?.country,
      district_id: data?.area,
      region_id: data?.region,
      user_status_id: data?.status
    };

    customersService.create(createdData).then((res: any) => {
      if (res) {
        setSelectedContactId({
          id: res?.data?.id,
          first_name: data?.first_name,
          last_name: data?.last_name
        });
        dispatch(
          showAlert({
            title: `${t('users')} ${t('successfully_added')}`,
            type: 'success'
          })
        );
        setOpenCreate(false);
      }
    });
  };

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('last_name')}
                type="text"
                placeholder={t('second_name')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('second_name')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('first_name')}
                type="text"
                placeholder={t('name')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('name')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('surname')}
                type="text"
                placeholder={t('surname')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('surname')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="country"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {countryOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('country')}</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="region"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {regionOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('region')}</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="area"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {areaOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('area')}</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('phone_number')}
                type="tel"
                placeholder="Номер телефона"
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('phone_number')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('password')}
                type="password"
                placeholder={t('password')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('password')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('reset_password')}
                type="password"
                placeholder={t('repeat_password')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">
                {t('repeat_password')}
              </label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Group>
              <Controller
                name="date_birth"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Flatpickr
                      className={classNames('form-control')}
                      options={{
                        nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                        prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                        locale: {
                          firstDayOfWeek: 1
                        },
                        monthSelectorType: 'static',
                        onDayCreate: (...[, , , dayElem]) => {
                          if (
                            dayElem.dateObj.getDay() === 5 ||
                            dayElem.dateObj.getDay() === 6
                          ) {
                            dayElem.className += ' weekend-days';
                          }
                        },
                        dateFormat: 'M j, Y',
                        disableMobile: true
                      }}
                      placeholder={t('date_birth')}
                      {...field}
                    />
                    <label htmlFor="floatingInputCustom">
                      {t('date_birth')}
                    </label>
                  </Form.Floating>
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="gender"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {genderOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('gender')}</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {usersStatusOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('status')}</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          className="w-100"
          variant="primary"
          type="submit"
        >
          {t('save')}
        </Button>
      </Form>
    </>
  );
};

export default CreateBuyers;
