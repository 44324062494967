/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const languageService = {
  getList: (params: any) => request.get('/guidebook/language', { params }),
  getListLangRegister: (params: any) => request.get('/language', { params }),
  getLangById: (id: any) => request.get(`/guidebook/language/${id}`),
  createLang: (data: any) => request.post('/guidebook/language', data),
  deleteLang: (id: any) => request.delete(`/guidebook/language/${id}`),
  updateLang: (id: any, data: any) =>
    request.put(`/guidebook/language/${id}`, data)
};

export default languageService;
