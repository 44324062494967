/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import RevenueTargetChart from 'components/charts/e-charts/RevenueTargetChart';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import analyticAbcService from 'service/analyticAbc';
import styles from './styles.module.scss';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';

interface ABCAnalysisTableProps {
  selectedAnalysis: string;
  dateFrom?: any;
  dateTo?: any;
  optionSenderId?: any;
  warehouseId?: any;
  currencyId?: any;
}

export type SalesData = {
  product_name: string;
  barcode: string;
  currency?: string;
  cost?: string;
  percentage: string;
};

export type QuantityData = {
  product_name: string;
  barcode: string;
  quantity?: string;
  percentage: string;
};

const ABCAnalysisTable = ({
  selectedAnalysis,
  dateFrom,
  dateTo,
  optionSenderId,
  currencyId,
  warehouseId
}: ABCAnalysisTableProps) => {
  const { t, i18n } = useTranslation();
  /* prettier-ignore */
  const adjustedDateTo = !dateFrom && !dateTo ? undefined : dateTo === dateFrom ? dateFrom + 86400000 : dateTo ? dateTo + 86400000 : undefined;

  const { data, refetch } = useQuery(
    ['GET_ANALYTIC_ABC'],
    async () => {
      const res = await analyticAbcService
        .getList({
          offset: 0,
          limit: 100,
          date_from: dateFrom,
          date_to: adjustedDateTo,
          supplier_id: optionSenderId,
          currency_id: currencyId,
          warehouse_id: warehouseId
        })
        .then((res: any) => res?.items);

      return res;
    },
    {
      enabled:
        !!dateFrom ||
        !!adjustedDateTo ||
        !!optionSenderId ||
        !!currencyId ||
        !!warehouseId
    }
  );

  const quantityData: SalesData[] = useMemo(() => {
    const resData =
      data?.map((items: any) => {
        return {
          product_name: items?.product?.name,
          barcode: items?.product?.barcode,
          quantity: items?.total_quantity,
          percentage: items?.percentage_of_quantity?.toFixed(2)
        };
      }) ?? [];

    return resData;
  }, [data]);

  const amountData: QuantityData[] = useMemo(() => {
    const resData =
      data?.map((items: any) => {
        return {
          product_name: items?.product?.name,
          barcode: items?.product?.barcode,
          cost: items?.total_amount,
          currency: items?.currency?.name?.[i18n?.language],
          percentage: items?.percentage_of_amount?.toFixed(2)
        };
      }) ?? [];

    return resData;
  }, [data, t, i18n?.language]);

  const salesDataTableColumns: ColumnDef<SalesData>[] = [
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'cost',
      header: t('amounts'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'percentage',
      header: t('in_percentage'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const quantityDataTableColumns: ColumnDef<QuantityData>[] = [
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'quantity',
      header: t('quantity'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'percentage',
      header: t('in_percentage'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: selectedAnalysis === 'quantity_goods' ? quantityData : amountData,
    columns:
      selectedAnalysis === 'quantity_goods'
        ? quantityDataTableColumns
        : salesDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    refetch();
  }, [dateFrom, dateTo, optionSenderId, currencyId, warehouseId]);
  return (
    <AdvanceTableProvider {...table}>
      <div className={styles.tableContainer}>
        <div
          style={{ width: '50%' }}
          className="mt-4 px-4 bg-white border-top border-bottom border-300 "
        >
          <RevenueTargetChart
            style={{ height: '100%', width: '100%' }}
            tableData={table?.getRowModel()?.rows}
          />
        </div>

        <div
          style={{ width: '50%' }}
          className="mt-4 px-4 bg-white border-top border-bottom border-300"
        >
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </div>
    </AdvanceTableProvider>
  );
};

export default ABCAnalysisTable;
