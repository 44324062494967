/* eslint-disable @typescript-eslint/no-explicit-any */
import CountUp from 'react-countup';
import { LegacyRef } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import AnalyticsCallCampaignChart from 'components/charts/e-charts/AnalyticsCallCampaignChart';
import dashboardService from 'service/dashboard';
import styles from './styles.module.scss';

export const branchOption = [
  {
    label: 'Epa',
    value: 'Epa'
  }
];

interface SaleGraphProps {
  selectedCurrencyId?: any;
  dateFrom?: any;
  dateTo?: any;
}

const SaleGraph = ({
  selectedCurrencyId,
  dateFrom,
  dateTo
}: SaleGraphProps) => {
  const { t } = useTranslation();

  //Get Sale
  const { data } = useQuery(
    ['GET_SALE', dateFrom, dateTo, selectedCurrencyId],
    () => {
      if (selectedCurrencyId && dateFrom) {
        const res = dashboardService
          .getSale({
            offset: 0,
            limit: 10,
            date_from: dateFrom,
            date_to: dateTo,
            currency_id: selectedCurrencyId
          })
          .then((res: any) => res);
        return res;
      }
    },
    { enabled: Boolean(dateFrom && selectedCurrencyId) }
  );

  return (
    <div className={styles.graphContainer}>
      <div className={styles.saleInfo}>
        <p className={styles.title}>{t('sales')}</p>
        <CountUp
          end={data?.total_amount ?? 0}
          duration={2.75}
          suffix={
            selectedCurrencyId === 'd69b53bd-da93-4db6-9108-c31210ca5c8f'
              ? ` ${t('sum')}`
              : ' $'
          }
          separator=" "
          delay={0}
        >
          {({ countUpRef }) => (
            <div>
              <span
                className={styles.count}
                ref={countUpRef as LegacyRef<HTMLHeadingElement>}
              ></span>
            </div>
          )}
        </CountUp>

        {/* <Form.Floating style={{ marginTop: '12px', width: '320px' }}>
          <Form.Select>
            <option className="d-none" value=""></option>
            {branchOption?.map((option: any) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>

          <label htmlFor="floatingInputCustom">По дням</label>
        </Form.Floating> */}

        <AnalyticsCallCampaignChart
          data={data?.amounts}
          style={{
            height: '300px',
            width: '100%',
            marginTop: '24px'
          }}
        />

        {/* <div className={styles.graphInfo}>
          <span className={styles.dot}></span>
          <div className={styles.selectedGraphInfo}>
            <p className={styles.sgTitle}>Чорсу</p>
            <CountUp
              end={278980500}
              duration={2.75}
              suffix="сум"
              separator=" "
              delay={0}
            >
              {({ countUpRef }) => (
                <div>
                  <span
                    className={styles.sgCount}
                    ref={countUpRef as LegacyRef<HTMLHeadingElement>}
                  ></span>
                </div>
              )}
            </CountUp>
          </div>
        </div> */}
      </div>

      {/* <div className={styles.fullGraph}>
        <div className={styles.switchToGraph}>
          <p className={styles.title}>Общий график</p>
          <Form.Check type="switch" />
        </div>
      </div> */}
    </div>
  );
};

export default SaleGraph;
