/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoneyBillWave,
  faClockRotateLeft
} from '@fortawesome/free-solid-svg-icons';
import { Modal, Offcanvas } from 'react-bootstrap';
import { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import SettelementHistory from './SettelementHistory';
import AddSettelement from './AddSettelement';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import usePageSearchParams from 'utils/usePageSearchParams';
import providerService from 'service/provider';
import Button from 'components/base/Button';

export type SettelementData = {
  product_provider: string;
  currency: string;
  debt: string;
  credit: string;
  remainder: string;
  status: string;
  bg_color: string;
  text_color: string;
  guid: string;
};

const SettelementResultTable = () => {
  const { t, i18n } = useTranslation();
  const [openSettelement, setOpenSettelement] = useState(false);
  const [openSettelementHistory, setOpenSettelementHistory] = useState(false);
  const [selectedProviderId, setSelectedProviderId] = useState('');
  const [selectedTableData, setSelectedTableData] = useState({});

  const { pathname } = useLocation();
  const [searchParams] = usePageSearchParams();

  const creditor_id = pathname.split('/').pop();
  const currency_id = searchParams.get('currency_id');

  const { data, refetch } = useQuery(
    ['GET_PROVIDER_RESULT'],
    () => {
      if (creditor_id && currency_id) {
        const res = providerService
          .getList({
            offset: 0,
            limit: 100,
            creditor_id: creditor_id,
            currency_id: currency_id
          })
          .then((res: any) => res?.debit_credit_list);
        return res;
      }
    },
    { enabled: !!creditor_id && !!currency_id }
  );

  const resultData: SettelementData[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        const remainder =
          (parseFloat(items?.amount_debit) || 0) -
          (parseFloat(items?.amount_credit) || 0);
        return {
          product_provider: items?.creditor?.name,
          debt: items?.amount_debit
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.amount_debit)
                .replace(/,/g, ' ')
            : 0,
          credit: items?.amount_credit
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.amount_credit)
                .replace(/,/g, ' ')
            : 0,
          remainder: remainder
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(remainder)
                .replace(/,/g, ' ')
            : 0,
          status: items?.status?.name?.[i18n?.language],
          currency: items?.currency?.name?.[i18n?.language],
          creditor_id: items?.creditor_id,
          debitor_id: items?.debitor_id,
          bg_color: items?.status?.background_color,
          text_color: items?.status?.text_color,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const suppliersBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'dt_kt',
      url: '/dtkt/provider'
    },
    {
      label: 'settlement_supplier',
      url: '/dtkt/suppliers'
    },
    {
      label: data && data[0]?.creditor?.name,
      active: true
    }
  ];

  const settelementDataTableColumns: ColumnDef<SettelementData>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex gap-2">
          <Button
            variant="hover"
            className="p-2"
            disabled={
              rowData?.row?.original?.status === 'Завершен' ||
              rowData?.row?.original?.status === 'Bajarildi'
                ? true
                : false
            }
            onClick={() => {
              setOpenSettelement(true);
              setSelectedTableData(rowData?.row?.original);
              setSelectedProviderId(rowData?.row?.original?.guid);
            }}
          >
            <FontAwesomeIcon
              className="cursor-pointer"
              icon={faMoneyBillWave}
              transform={{ size: 20 }}
            />
          </Button>

          <Button
            variant="hover"
            className="p-2"
            onClick={() => {
              setOpenSettelementHistory(true);
              setSelectedProviderId(rowData?.row?.original?.guid);
            }}
          >
            <FontAwesomeIcon
              className="cursor-pointer"
              icon={faClockRotateLeft}
              transform={{ size: 20 }}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'product_provider',
      header: t('supplier_name'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'debt',
      header: t('debit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'credit',
      header: t('credit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'remainder',
      header: t('remainder'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        return (
          /* prettier-ignore */
          <div
            style={{
              background: `${rowData?.row?.original?.bg_color}`,
              padding: '8px 10px',
              textAlign: 'center',
              borderRadius: '8px',
              color: `${rowData?.row?.original?.text_color}`,
              fontWeight: "600"
            }}
          >
            {rowData?.row?.original?.status}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: resultData,
    columns: settelementDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <PageBreadcrumb items={suppliersBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{data && data[0]?.creditor?.name}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>

          <Offcanvas
            show={openSettelement}
            onHide={() => {
              setOpenSettelement(false);
              setSelectedProviderId('');
            }}
            placement="end"
            backdrop="static"
            keyboard={false}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
                {t('mutual_settlement')}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <AddSettelement
                setOpenSettelement={setOpenSettelement}
                selectedProviderId={selectedProviderId}
                selectedTableData={selectedTableData}
                refetch={refetch}
              />
            </Offcanvas.Body>
          </Offcanvas>

          <Modal
            show={openSettelementHistory}
            onHide={() => {
              setOpenSettelementHistory(false);
              setSelectedProviderId('');
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {t('history_mutual_settlements')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SettelementHistory selectedProviderId={selectedProviderId} />
            </Modal.Body>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default SettelementResultTable;
