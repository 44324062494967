/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import walletService from 'service/wallet';
import legalEntityCurrencyService from 'service/legalEntityCurrency';

interface CreateWalletProps {
  setOpenWallet: (show: boolean) => void;
  selectedWalletId?: any;
  refetch?: any;
  setSelectedWalletId?: any;
}

const CreateWallet = ({
  setOpenWallet,
  selectedWalletId,
  refetch,
  setSelectedWalletId
}: CreateWalletProps) => {
  const { t, i18n } = useTranslation();

  const options = [
    {
      value: true,
      label: t('active')
    },
    {
      value: false,
      label: t('blocked')
    }
  ];
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();
  const hasFetchedData = useRef(false);
  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();

  const [loading, setLoading] = useState(false);
  const [currencyOption, setCurrencyOption] = useState([]);

  //GET Country to options
  useQuery(['GET_CURRENCY'], async () => {
    await legalEntityCurrencyService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.legal_entity_currencies?.map((option: any) => ({
          value: option?.currency?.id,
          label: option?.currency?.name?.[i18n?.language]
        }));
        setCurrencyOption(options);
      });
  });

  const fetchData = () => {
    if (selectedWalletId === '') return setLoading(false);

    walletService
      .getWalletById(selectedWalletId)
      .then((res: any) => {
        const computed = {
          name: res?.name,
          currency: res?.currency_id,
          status: res?.status === true ? true : false
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedWalletId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    console.log('data', data);
    const createdData = {
      name: data?.name,
      currency_id: data?.currency,
      status: data?.status === 'true' ? true : false
    };

    walletService
      .createWallet(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: `${t('wallet')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
        }
      })
      .finally(() => {
        refetch();
        setOpenWallet(false);
        setLoading(false);
        setSelectedWalletId('');
      });
  };

  const update = (data: any) => {
    setLoading(true);

    const updateData = {
      name: data?.name,
      currency_id: data?.currency,
      status: data?.status === 'true' || data?.status === true ? true : false,
      id: selectedWalletId
    };
    walletService.updateWallet(selectedWalletId, updateData).finally(() => {
      refetch();
      setLoading(false);
      setOpenWallet(false);
      setSelectedWalletId('');
    });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef.current.select();
      }, 500);
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (selectedWalletId !== '' && !hasFetchedData.current) {
      fetchData();
      hasFetchedData.current = true;
    }
  }, [selectedWalletId]);
  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-3">
          <Col>
            <Controller
              name="name"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...field}
                    ref={inputRef}
                    type="text"
                    placeholder="Name"
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors?.name}
                  />
                  <label htmlFor="floatingInputCustom">{t('main_name')}</label>
                  {errors?.name && (
                    <Form.Control.Feedback type="invalid">
                      {t('required_field')}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <div className="react-select-container">
              <Controller
                name="currency"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors?.currency}>
                      <option className="d-none" value=""></option>
                      {currencyOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('currency')}</label>
                    {errors?.currency && (
                      <Form.Control.Feedback type="invalid">
                        {t('required_field')}
                      </Form.Control.Feedback>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors?.status}>
                      <option className="d-none" value=""></option>
                      {options?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('status')}</label>
                    {errors?.status && (
                      <Form.Control.Feedback type="invalid">
                        {t('required_field')}
                      </Form.Control.Feedback>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
          loadingPosition="start"
        >
          {t('add')}
        </Button>
      </Form>
    </>
  );
};

export default CreateWallet;
