/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import { Form } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useRef } from 'react';
import { UilCalendarAlt } from '@iconscout/react-unicons';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import legalEntityService from 'service/legalEntity';
import legalEntityCurrencyService from 'service/legalEntityCurrency';
import providerService from 'service/provider';
import { showAlert } from 'store/alert/alert.thunk';

interface CreateSuppliersProps {
  setOpenCreate?: any;
}

const CreateSuppliers = ({ setOpenCreate }: CreateSuppliersProps) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm();

  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [legalEntityOption, setLegalEntityOption] = useState<any>([]);
  const [legalEntityCurrencyOption, setLegalEntityCrrencyOption] =
    useState<any>([]);

  const cashboxId = useSelector((state: any) => state?.shiftId?.shiftId);
  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();

  //GET LEgal Entity to options
  useQuery(['GET_LEGAL_ENTITY'], async () => {
    await legalEntityService
      .getLegalEntityGraph({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entities?.map((option: any) => ({
          value: option.id,
          label: option.name,
          creditor_legal_entity_type: option?.legal_entity_type
        }));

        setLegalEntityOption(options);
      });
  });

  //GET LEgal Entity to options
  useQuery(['GET_LEGAL_ENTITY_CURRENCY'], async () => {
    await legalEntityCurrencyService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entity_currencies?.map((option: any) => ({
          value: option?.currency?.id,
          label: option?.currency?.name?.[i18n?.language]
        }));

        setLegalEntityCrrencyOption(options);
      });
  });

  const onSubmit: SubmitHandler<FieldValues> = data => {
    setLoading(true);

    if (!cashboxId) {
      dispatch(showAlert({ title: t('shift_not_open') }));
      return;
    }
    const createdData = {
      cashbox_id: cashboxId,
      creditor_id: data?.legal_entity_id,
      creditor_legal_entity_type: data?.creditor_legal_entity_type,
      currency_id: data?.legal_entity_currency_id,
      amount_debit: +data?.debit ?? 0,
      amount_credit: 0,
      maturity_date: Date.parse(data?.maturity_date)
    };

    providerService?.createProvider(createdData)?.then(res => {
      if (res) {
        dispatch(
          showAlert({
            title: `${t('settlement_supplier')} ${t('successfully_added')}`,
            type: 'success'
          })
        );
        setOpenCreate(false);
      }
    });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Form.Group className="md-4 mb-2">
          <div className="react-select-container">
            <Controller
              name="legal_entity_id"
              control={control}
              defaultValue=""
              rules={{
                required: true
              }}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    ref={inputRef}
                    isInvalid={!!errors.legal_entity_id}
                    onChange={(e: any) => {
                      const selectedOption = legalEntityOption.find(
                        (option: any) => option.value === e.target.value
                      );
                      if (selectedOption) {
                        setValue(
                          'creditor_legal_entity_type',
                          selectedOption.creditor_legal_entity_type
                        );
                      }
                      field.onChange(e);
                    }}
                  >
                    <option className="d-none" value=""></option>
                    {legalEntityOption?.map((option: any) => (
                      <option
                        key={option.value}
                        value={option.value}
                        className="option"
                      >
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">{t('supplier')}</label>
                  {errors?.legal_entity_id && (
                    <span
                      style={{
                        marginTop: '2px',
                        color: 'red',
                        fontSize: '12px'
                      }}
                    >
                      {t('required_field')}
                    </span>
                  )}
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group className="md-4 mb-2">
          <div className="react-select-container">
            <Controller
              name="legal_entity_currency_id"
              control={control}
              defaultValue=""
              rules={{
                required: true
              }}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    isInvalid={!!errors.legal_entity_currency_id}
                  >
                    <option className="d-none" value=""></option>
                    {legalEntityCurrencyOption?.map((option: any) => (
                      <option
                        key={option.value}
                        value={option.value}
                        className="option"
                      >
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                  <label htmlFor="floatingInputCustom">{t('currency')}</label>
                  {errors?.legal_entity_currency_id && (
                    <span
                      style={{
                        marginTop: '2px',
                        color: 'red',
                        fontSize: '12px'
                      }}
                    >
                      {t('required_field')}
                    </span>
                  )}
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-2">
          <Controller
            name={`debit`}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Form.Floating className="md-4">
                <Form.Control
                  {...field}
                  type="number"
                  placeholder="Дебет"
                  onFocus={e => e.target.select()}
                  autoComplete="new-debit"
                  isInvalid={!!errors.debit}
                />
                <label htmlFor="floatingInputCustom">{t('amount_due')}</label>
                {errors?.debit && (
                  <Form.Control.Feedback type="invalid">
                    {t('required_field')}
                  </Form.Control.Feedback>
                )}
              </Form.Floating>
            )}
          />
        </Form.Group>

        <Form.Group className="flatpickr-input-container">
          <Controller
            name="maturity_date"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Form.Floating>
                <Flatpickr
                  className={classNames('form-control', {
                    error: errors.maturity_date?.message === ''
                  })}
                  options={{
                    nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                    prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                    locale: {
                      firstDayOfWeek: 1
                    },
                    monthSelectorType: 'static',
                    onDayCreate: (...[, , , dayElem]) => {
                      if (
                        dayElem.dateObj.getDay() === 5 ||
                        dayElem.dateObj.getDay() === 6
                      ) {
                        dayElem.className += ' weekend-days';
                      }
                    },
                    dateFormat: 'M j, Y',
                    disableMobile: true
                  }}
                  placeholder={t('maturity_date')}
                  {...field}
                />
                <label htmlFor="startDatepicker" className="ps-6">
                  {t('maturity_date')}
                </label>
                <UilCalendarAlt className="flatpickr-icon text-700" size={16} />
              </Form.Floating>
            )}
          />

          {errors?.maturity_date?.message === '' && (
            <span style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}>
              {t('required_field')}
            </span>
          )}
        </Form.Group>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
          loadingPosition="start"
        >
          {t('add')}
        </Button>
      </Form>
    </>
  );
};

export default CreateSuppliers;
